import './index.scss';

import { Button, Form, Input, Spin } from 'antd';
import { useState } from 'react';
import auth0 from 'auth0-js';
import { Navigate } from 'react-router-dom';

const { VITE_AUTH0_DOMAIN, VITE_AUTH0_CLIENT_ID, VITE_AUTH0_CONNECTION } =
  import.meta.env;

const ForgotPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [emailOnRedirect, setEmailOnRedirect] = useState(null);

  const webAuth = new auth0.WebAuth({
    domain: VITE_AUTH0_DOMAIN,
    clientID: VITE_AUTH0_CLIENT_ID,
  });

  const handleSubmit = (values) => {
    const { email } = values;
    if (!email) {
      alert('Please enter your email.');
      return;
    }
    setIsLoading(true);

    webAuth.changePassword(
      {
        connection: VITE_AUTH0_CONNECTION,
        email: email,
      },
      function (err, resp) {
        if (err) {
          console.log(err.message);
        } else {
          console.log(resp);
        }
        setIsLoading(false);
        setEmailOnRedirect(email);
      },
    );
  };

  if (emailOnRedirect) {
    return (
      <Navigate to="/user/forgot-password-sent" state={{ emailOnRedirect }} />
    );
  }

  return (
    <div className="forgot-password-container">
      <div className="title-text">
        Forgot your password? <br /> Enter your email to be sent password reset
        instructions
      </div>
      <Spin size="large" spinning={isLoading}>
        <div>
          <Form
            className="forgot-pasword-form"
            hideRequiredMark
            layout="vertical"
            onFinish={handleSubmit}
            autoComplete="__away"
            name="basic"
          >
            <div id="validation-status" />
            <Form.Item
              name="email"
              rules={[
                { required: true, message: `Please enter your email` },
                {
                  required: false,
                  type: 'email',
                  message: 'Please enter an email in the correct format',
                },
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>
            <Form.Item className="form-item" style={{ paddingTop: '20px' }}>
              <Button htmlType="submit" className="submit-button">
                Send password reset email
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Spin>
    </div>
  );
};

export default ForgotPassword;
