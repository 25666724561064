import '../index.scss';

import {
  LockFilled,
  MinusCircleOutlined,
  PlusCircleOutlined,
  SearchOutlined,
  UnlockFilled,
} from '@ant-design/icons';
import {
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Popconfirm,
  Select,
  Space,
  Spin,
} from 'antd';
import axios from 'axios';
import moment from 'moment';
import { useCallback, useEffect, useRef, useState } from 'react';
import CommonTable from '../../components/CommonTable';
import CompanySuggest from '../../components/CompanySuggest';
import { EditableCell, EditableRow } from '../../components/EditableComponents';
import TopSection from '../../components/TopSection';
import { requestWithAuth } from '../../services/api';
import {
  filterStates,
  genderOptions,
  homeOwnerStatusList,
  InterestsArray,
  maritalStatusOptions,
  presenceOfChildrenOptions,
  SalaryRanges,
  titleRankOptions,
  wealthSegmentOptions,
} from '../../utils/constants';
import { getValue } from '../../utils/general';

interface VaultPeopleRequestParams {
  page_size?: number;
  page?: number;
  q?: string;
  ordering?: string;
  first_name__icontains?: string;
  middle_name__icontains?: string;
  last_name__icontains?: string;
  full_name__icontains?: string;
  org_name__icontains?: string;
  title__icontains?: string;
  location_city__icontains?: string;
  location_state__in?: string;
}

let params: VaultPeopleRequestParams = {};
let source;

const getArray = (field) => {
  if (!field) return [];

  if (field.includes('\n'))
    return field.split('\n').filter((element) => element.length > 0);

  if (field.includes(','))
    return field.split(',').filter((element) => element.length > 0);

  if (Array.isArray(field)) return field;

  return [field];
};

const FormItems = ({
  formVals,
  form,
  isUpdate,
  addRemoveExperience,
  onDateChange,
  onPrimaryChange,
}) => {
  useEffect(() => {
    if (formVals.b2c_id) setIsB2cDisabled(true);
    /** To avoid rendering form with previously clicked row data */
    if (!addRemoveExperience) form.resetFields();
  }, [formVals, addRemoveExperience, form]);

  const [isB2cDisabled, setIsB2cDisabled] = useState(false);

  return (
    <>
      <>
        <div className="title-text">Mapping Section</div>
        <div className="sub-section">
          <Form.Item name="b2b_id" label="b2b_id" className="width-30">
            <Input disabled />
          </Form.Item>
          <Form.Item label="b2c_id" className="width-30">
            <Space align="start" size={'small'}>
              <Form.Item
                name="b2c_id"
                extra={
                  formVals.first_name && (
                    <>
                      If B2C ID is changed, the profile will need 24 hours to
                      update and can not be edited during this period.
                    </>
                  )
                }
              >
                <Input disabled={isB2cDisabled} allowClear={true} />
              </Form.Item>
              {isB2cDisabled ? (
                <LockFilled
                  style={{ fontSize: '30px' }}
                  onClick={() => {
                    setIsB2cDisabled(false);
                  }}
                />
              ) : (
                <UnlockFilled
                  style={{
                    fontSize: '30px',
                    display: !formVals.b2c_id ? 'none' : 'block',
                  }}
                  onClick={() => {
                    setIsB2cDisabled(true);
                  }}
                />
              )}
            </Space>
          </Form.Item>
          <Form.Item
            name="b2c_household_id"
            label="b2c_household_id"
            className="width-30"
          >
            <InputNumber
              style={{ width: '100%' }}
              placeholder="Enter b2c household ID"
            />
          </Form.Item>
          <Form.Item name="cik" label="Owner CIK" className="width-30">
            <Input placeholder="Owner CIK" />
          </Form.Item>
        </div>
      </>
      <>
        <div className="title-text">Household Section</div>
        <div className="sub-section">
          <Form.Item
            name="first_name"
            label="First name"
            className="width-30"
            required
            rules={[
              {
                required: true,
                message: 'Enter first name',
              },
            ]}
          >
            <Input placeholder="First name" autoComplete="_away" />
          </Form.Item>
          <Form.Item
            name="middle_name"
            label="Middle name"
            className="width-30"
          >
            <Input placeholder="Middle name" autoComplete="_away" />
          </Form.Item>
          <Form.Item
            name="last_name"
            label="Last name"
            className="width-30"
            required
            rules={[
              {
                required: true,
                message: 'Enter last name',
              },
            ]}
          >
            <Input placeholder="Last name" autoComplete="_away" />
          </Form.Item>
        </div>
        <div className="sub-section">
          <Form.Item
            name="age"
            label="Age"
            className="width-30"
            required
            rules={[
              {
                required: true,
                message: 'Enter age, or 0 if unknown',
              },
            ]}
          >
            <InputNumber
              style={{ width: '100%' }}
              min={0}
              max={150}
              placeholder="Age"
              autoComplete="_away"
            />
          </Form.Item>
          <Form.Item name="gender" label="Sex" className="width-30">
            <Select style={{ width: '100%' }}>
              {genderOptions.map(({ value, text }) => (
                <Select.Option key={value} value={value}>
                  {text}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="marital_status"
            label="Modeled Marital Status"
            className="width-30"
          >
            <Select style={{ width: '100%' }}>
              {maritalStatusOptions.map(({ value, text }) => (
                <Select.Option key={value} value={value}>
                  {text}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="presence_of_children"
            label="Modeled Presence of Children"
            className="width-30"
          >
            <Select placeholder="Modeled Presence of Children">
              {presenceOfChildrenOptions.map(({ value, text }) => (
                <Select.Option key={String(value)} value={value}>
                  {text}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            className="width-30"
            name="interests"
            label="Modeled Interests"
          >
            <Select
              mode="multiple"
              placeholder="Select interests"
              style={{ width: '100%' }}
            >
              {InterestsArray.map(({ value, text }) => (
                <Select.Option key={value} value={value}>
                  {text}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <div className="sub-section">
          <Form.Item
            className="width-45"
            name="location_city"
            label="Location city"
            required
            rules={[
              {
                required: true,
                message:
                  'Copy address city or enter city from LinkedIn profile',
              },
            ]}
          >
            <Input placeholder="Location city" autoComplete="_away" />
          </Form.Item>
          <Form.Item
            className="width-45"
            name="location_state"
            label="Location state"
            required
            rules={[
              {
                required: true,
                message:
                  'Copy home address state or enter state from LinkedIn profile',
              },
            ]}
          >
            <Select>
              {filterStates.map(({ value, text }) => (
                <Select.Option key={value} value={text}>
                  {text}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <div
          style={{
            marginTop: '30px',
            width: '100%',
            overflow: 'auto',
            paddingBottom: '50px',
          }}
        >
          <div
            className="form-header"
            style={{ gridTemplateColumns: '75px repeat(7, 250px) 75px' }}
          >
            <div>
              Primary
              <br /> Location
            </div>
            <div>Home address line 1</div>
            <div>Home address line 2</div>
            <div>Home City</div>
            <div>Home State</div>
            <div>Home ZIP</div>
            <div>Own/Rent</div>
            <div>Value</div>
            {/* Dummy div */}
            <div></div>
          </div>
          <Form.List name="second_homes">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    className="table-form"
                    style={{
                      gridTemplateColumns: '75px repeat(7, 250px) 75px',
                    }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, 'is_primary']}
                      style={{ textAlign: 'center' }}
                      valuePropName="checked"
                    >
                      <Checkbox
                        onChange={(e) =>
                          onPrimaryChange(
                            'second_homes',
                            name,
                            e.target.checked,
                          )
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'home_address_line1']}
                    >
                      <Input
                        placeholder="Home address line 1"
                        autoComplete="_away"
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'home_address_line2']}
                    >
                      <Input
                        placeholder="Home address line 2"
                        autoComplete="_away"
                      />
                    </Form.Item>

                    <Form.Item {...restField} name={[name, 'city']}>
                      <Input placeholder="Home city" autoComplete="_away" />
                    </Form.Item>

                    <Form.Item {...restField} name={[name, 'state']}>
                      <Select
                        style={{ width: '100%' }}
                        placeholder="Home state"
                      >
                        {filterStates.map(({ value, text }) => (
                          <Select.Option key={value} value={text}>
                            {text}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>

                    <Form.Item {...restField} name={[name, 'postal_code']}>
                      <Input placeholder="Home ZIP" autoComplete="_away" />
                    </Form.Item>

                    <Form.Item {...restField} name={[name, 'norm_home_owner']}>
                      <Select placeholder="Own/Rent" style={{ width: '100%' }}>
                        {homeOwnerStatusList.map(({ value, text }) => (
                          <Select.Option key={value} value={value}>
                            {text}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>

                    <Form.Item {...restField} name={[name, 'value']}>
                      <Input placeholder="Value" autoComplete="_away" />
                    </Form.Item>

                    <MinusCircleOutlined
                      className="remove-icon"
                      onClick={() => remove(name)}
                    />
                  </Space>
                ))}

                <Button
                  className="add-new"
                  onClick={() => {
                    if (add) add();
                  }}
                >
                  <PlusCircleOutlined />
                  Add New Location
                </Button>
              </>
            )}
          </Form.List>
        </div>
      </>
      <>
        <div className="title-text">Contact Info Section</div>
        <div className="sub-section">
          <Form.Item
            name="work_emails"
            label="Work Emails"
            className="width-45"
          >
            <Input.TextArea
              autoSize={{ minRows: 3, maxRows: 8 }}
              placeholder="Work Emails, one per line"
              autoComplete="_away"
            />
          </Form.Item>
          <Form.Item
            name="mobile_phones"
            label="Mobile Phones"
            className="width-45"
          >
            <Input.TextArea
              autoSize={{ minRows: 3, maxRows: 8 }}
              placeholder="Mobile Phones, one per line"
              autoComplete="_away"
            />
          </Form.Item>
          <Form.Item
            name="personal_emails"
            label="Personal Emails"
            className="width-45"
          >
            <Input.TextArea
              autoSize={{ minRows: 3, maxRows: 8 }}
              placeholder="Personal Emails, one per line"
              autoComplete="_away"
            />
          </Form.Item>
          <Form.Item
            name="other_phones"
            label="Other Phones"
            className="width-45"
          >
            <Input.TextArea
              autoSize={{ minRows: 3, maxRows: 8 }}
              placeholder="Other Phones, one per line"
              autoComplete="_away"
            />
          </Form.Item>
        </div>
      </>
      <>
        <div className="title-text">Social Section</div>
        <div className="sub-section">
          <Form.Item
            name="norm_linkedin_urls"
            label="LinkedIn"
            className="width-30"
          >
            <Input.TextArea
              autoSize={{ minRows: 3, maxRows: 8 }}
              placeholder="LinkedIn urls, one per line"
              autoComplete="_away"
            />
          </Form.Item>
          <Form.Item name="facebook" label="Facebook" className="width-30">
            <Input.TextArea
              autoSize={{ minRows: 3, maxRows: 8 }}
              placeholder="Facebook urls, one per line"
              autoComplete="_away"
            />
          </Form.Item>
          <Form.Item name="twitter" label="Twitter" className="width-30">
            <Input.TextArea
              autoSize={{ minRows: 3, maxRows: 8 }}
              placeholder="Twitter urls, one per line"
              autoComplete="_away"
            />
          </Form.Item>
        </div>
      </>
      <>
        <div className="title-text">Education Section</div>
        <div className="sub-section">
          <Form.Item name="schools" label="Education" className="width-30">
            <Input.TextArea
              autoSize={{ minRows: 3, maxRows: 8 }}
              placeholder="Education, one per line"
              autoComplete="_away"
            />
          </Form.Item>
        </div>
      </>
      <>
        <div className="title-text">Professional Section</div>
        <div
          className="sub-section"
          style={{ gridTemplateColumns: 'auto auto' }}
        >
          <Form.Item
            name="inferred_salary_range"
            label="Inferred Income Range"
            className="width-30"
            initialValue={formVals.inferred_salary_range}
            required
            rules={[
              {
                required: true,
                message: 'Enter Inferred Income Range',
              },
            ]}
          >
            <Select style={{ width: '100%' }}>
              {SalaryRanges.map(({ value, text }) => (
                <Select.Option key={value} value={value}>
                  {text}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="wealth_estimate"
            label="Wealth Segment"
            className="width-30"
            required
            rules={[
              {
                required: true,
                message: 'Enter wealth segment',
              },
            ]}
          >
            <Select style={{ width: '100%' }}>
              {wealthSegmentOptions.map(({ value, text, min, max }) => (
                <Select.Option
                  key={value}
                  value={
                    value === 'Billionaire Wealth Market'
                      ? min + 1
                      : (min + max) / 2
                  }
                >
                  {text}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <div
          style={{
            marginTop: '30px',
            width: '100%',
            overflow: 'auto',
            paddingBottom: '50px',
          }}
        >
          <div
            className="form-header"
            style={{ gridTemplateColumns: '75px repeat(8, 250px) 75px' }}
          >
            <div>Is Primary</div>
            <div>Company</div>
            <div>Title</div>
            <div>Title Rank</div>
            <div>Company URL</div>
            <div>Work City</div>
            <div>Work State</div>
            <div>Start Date</div>
            <div>End Date</div>
            <div>{/* Dummy Remove header */}</div>
          </div>
          <Form.List name="norm_experience">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    className="table-form"
                    style={{
                      gridTemplateColumns: '75px repeat(8, 250px) 75px',
                    }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, 'is_primary']}
                      style={{ paddingLeft: '25px' }}
                      valuePropName="checked"
                    >
                      <Checkbox
                        onChange={(e) =>
                          onPrimaryChange(
                            'norm_experience',
                            name,
                            e.target.checked,
                          )
                        }
                      />
                    </Form.Item>
                    <Form.Item {...restField} name={[name, 'org_name_obj']}>
                      <CompanySuggest
                        value={{
                          label: formVals.org_name,
                          value: formVals.ai_org_id,
                        }}
                      />
                    </Form.Item>
                    <Form.Item {...restField} name={[name, 'title']}>
                      <Input placeholder="Title" />
                    </Form.Item>
                    <Form.Item {...restField} name={[name, 'title_rank']}>
                      <Select style={{ width: '100%' }}>
                        {titleRankOptions.map(({ value, label }) => (
                          <Select.Option key={value} value={value}>
                            {label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item {...restField} name={[name, 'org_url']}>
                      <Input placeholder="Company URL" autoComplete="_away" />
                    </Form.Item>
                    <Form.Item {...restField} name={[name, 'work_city']}>
                      <Input placeholder="Work city" autoComplete="_away" />
                    </Form.Item>
                    <Form.Item {...restField} name={[name, 'work_state']}>
                      <Select style={{ width: '100%' }}>
                        {filterStates.map(({ value, text }) => (
                          <Select.Option key={value} value={text}>
                            {text}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'start_date']}
                      valuePropName={'date'}
                      // initialValue={field.start_date ? moment(field.start_date) : undefined}
                      // {...formLayout}
                    >
                      <DatePicker
                        value={
                          formVals.norm_experience[key]?.start_date
                            ? moment(
                                formVals.norm_experience[key].start_date,
                                'YYYY-MM-DD',
                              )
                            : undefined
                        }
                        onChange={(date, dateString) => {
                          onDateChange(dateString, 'start_date', key);
                        }}
                        placeholder="Start date"
                        style={{ width: '100%' }}
                        disabledDate={(current) => {
                          return current && current.valueOf() > Date.now();
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'end_date']}
                      valuePropName={'date'}
                      // initialValue={field.start_date ? moment(field.start_date) : undefined}
                      // {...formLayout}
                    >
                      <DatePicker
                        value={
                          formVals.norm_experience[key]?.end_date
                            ? moment(
                                formVals.norm_experience[key].end_date,
                                'YYYY-MM-DD',
                              )
                            : undefined
                        }
                        onChange={(date, dateString) => {
                          onDateChange(dateString, 'end_date', key);
                        }}
                        placeholder="End date"
                        style={{ width: '100%' }}
                        disabledDate={(current) => {
                          return current && current.valueOf() > Date.now();
                        }}
                      />
                    </Form.Item>
                    <MinusCircleOutlined
                      className="remove-icon"
                      onClick={() => remove(name)}
                    />
                  </Space>
                ))}
                <Button
                  className="add-new"
                  onClick={() => {
                    if (add) add();
                  }}
                >
                  <PlusCircleOutlined />
                  Add New Experience
                </Button>
              </>
            )}
          </Form.List>
        </div>
      </>
    </>
  );
};

interface NormExperience {
  title: string;
  ai_org_id?: string;
  org_name?: string;
  is_primary?: boolean;
  org_name_obj?: {
    value: string;
    label: string;
  };
  start_date?: string;
  end_date?: string;
}

interface FormVals {
  id?: number;
  b2b_id?: string;
  b2c_id?: string;
  org_name?: string;
  ai_org_id?: string | number;
  title?: string;
  norm_experience: NormExperience[];
  second_homes: SecondHome[];
  socialHandles?: SocialHandle[];
}

interface SecondHome {
  home_address_line1?: string;
  home_address_line2?: string;
  city?: string;
  state?: string;
  postal_code?: string;
  value?: number;
  norm_home_owner?: boolean;
  is_primary?: boolean;
}

interface SocialHandle {
  network: string;
  url: string;
}

const FormModal = ({
  form,
  isUpdate,
  handleUpdate,
  handleCreate,
  isModalLoading,
  onClose,
  recordFormVals,
  error,
}) => {
  const [formVals, setFormVals] = useState<FormVals>({
    norm_experience: [{ title: '', is_primary: false }],
    second_homes: [{ home_address_line1: '', is_primary: false }],
  });

  /** key to understand formaval update fron add/remove experience, so no need to reset fields within FormItems */
  // const addRemoveExperience = false;

  useEffect(() => {
    if (isUpdate) {
      // const interests = [];
      // InterestsArray.map((interest) => {
      //   if (
      //     interest &&
      //     interest.value &&
      //     recordFormVals[interest.value] === true
      //   )
      //     interests.push(interest.value);
      //   return "";
      // });

      const facebook = [];
      const twitter = [];
      let socialHandles = [];
      if (
        recordFormVals.social_handles &&
        recordFormVals.social_handles.length
      ) {
        recordFormVals.social_handles.map((socialHandle) => {
          if (socialHandle && socialHandle.network && socialHandle.url) {
            if (socialHandle.network === 'facebook') {
              facebook.push(socialHandle.url);
            } else if (socialHandle.network === 'twitter') {
              twitter.push(socialHandle.url);
            }
          }
          return '';
        });
        socialHandles = recordFormVals.social_handles.filter(
          (item) => item.network !== 'facebook' && item.network !== 'twitter',
        );
      }
      let norm_experience: NormExperience[] = [{ title: '' }];

      if (
        recordFormVals.norm_experience &&
        Array.isArray(recordFormVals.norm_experience) &&
        recordFormVals.norm_experience.length > 0
      ) {
        // norm_experience = [...recordFormVals.norm_experience];
        norm_experience = recordFormVals.norm_experience.map((exp) => {
          return {
            ...exp,
            org_name_obj: { value: exp.ai_org_id, label: exp.org_name },
          };
        });
        const index = norm_experience.findIndex(
          (item: NormExperience) =>
            (item.ai_org_id === recordFormVals.ai_org_id ||
              item.org_name?.toLowerCase() ===
                recordFormVals.org_name?.toLowerCase()) &&
            item.title?.toLowerCase() === recordFormVals.title?.toLowerCase(),
        );
        if (index > -1) {
          norm_experience[index].is_primary = true;
        }
      }

      const primaryAddress = {
        is_primary: !!(
          recordFormVals.home_address_line1 ||
          recordFormVals.home_address_line2 ||
          recordFormVals.home_city ||
          recordFormVals.home_state ||
          recordFormVals.home_postal_code ||
          recordFormVals.norm_home_owner ||
          recordFormVals.home_value_estimate
        ),
        home_address_line1: recordFormVals.home_address_line1,
        home_address_line2: recordFormVals.home_address_line2,
        city: recordFormVals.home_city,
        state: recordFormVals.home_state,
        postal_code: recordFormVals.home_postal_code,
        value: recordFormVals.home_value_estimate,
        norm_home_owner: recordFormVals.norm_home_owner,
        number_of_owners: undefined,
        property_id: undefined,
      };

      let second_homes = [{ ...primaryAddress }];

      if (
        recordFormVals.second_homes &&
        Array.isArray(recordFormVals.second_homes) &&
        recordFormVals.second_homes.length > 0
      ) {
        second_homes = [...recordFormVals.second_homes, ...second_homes];
      }

      let wealthEstimate;
      if (recordFormVals.wealth_estimate) {
        if (recordFormVals.wealth_estimate >= 1000000001)
          wealthEstimate = 1000000002;
        else
          wealthSegmentOptions.map(({ min, max }) => {
            if (
              recordFormVals.wealth_estimate >= min &&
              recordFormVals.wealth_estimate <= max
            )
              wealthEstimate = (min + max) / 2;
            return wealthEstimate;
          });
      }

      setFormVals({
        ...recordFormVals,
        // interests,
        facebook,
        twitter,
        socialHandles,
        norm_experience,
        second_homes,
      });
    }
  }, [recordFormVals, isUpdate]);

  const onDateChange = (date, key, position) => {
    const fieldValues = form.getFieldsValue();
    const { norm_experience } = fieldValues;
    norm_experience[position][key] = date || null;
    if (isUpdate) {
      setFormVals({ ...recordFormVals, ...fieldValues, norm_experience });
    } else {
      setFormVals({ ...fieldValues, norm_experience });
    }
  };

  const onPrimaryChange = (key, position, isChecked) => {
    const fieldValues = form.getFieldsValue();
    const { norm_experience, second_homes } = fieldValues;
    if (key === 'norm_experience') {
      for (const exp of norm_experience) {
        if (exp) delete exp.is_primary;
      }
      if (isChecked) norm_experience[position].is_primary = true;
    } else if (key === 'second_homes') {
      for (const home of second_homes) {
        if (home) delete home.is_primary;
      }
      if (isChecked) second_homes[position].is_primary = true;
    }

    if (isUpdate) {
      setFormVals({
        ...recordFormVals,
        ...fieldValues,
        norm_experience,
        second_homes,
      });
    } else {
      setFormVals({ ...fieldValues, norm_experience, second_homes });
    }
  };

  async function mergeUnmergeApi(operation, b2c) {
    return requestWithAuth('vault-b2b-b2c-merge', 'POST', null, {
      b2b_id: formVals.b2b_id,
      b2c_id: b2c,
      op: operation,
    });
  }
  const handleSubmit = () => {
    form
      .validateFields()
      .then((currentValues) => {
        const formValues = { ...currentValues };
        // InterestsArray.map((val) => {
        //   if (
        //     val &&
        //     formValues.interests &&
        //     Array.isArray(formValues.interests) &&
        //     formValues.interests.includes(val.value)
        //   )
        //     formValues[val.value] = true;
        //   else formValues[val.value] = null;
        //   return "";
        // });
        // delete formValues.interests;
        [
          'schools',
          'work_emails',
          'personal_emails',
          'mobile_phones',
          'other_phones',
          // 'phones',
          'norm_linkedin_urls',
        ].forEach((field) => {
          formValues[field] = getArray(formValues[field]);
        });

        const { socialHandles = [] } = formVals;
        ['facebook', 'twitter'].forEach((field) => {
          if (formValues[field] && formValues[field] !== '') {
            let socialArray = formValues[field];
            socialArray = getArray(formValues[field]);

            if (socialArray && socialArray.length > 0) {
              socialArray.map((url) => {
                socialHandles.push({
                  network: field,
                  url,
                });
                return '';
              });
            }
          }
        });
        formValues.social_handles = socialHandles;
        delete formValues.facebook;
        delete formValues.twitter;

        /** If the norm_experience has the default empty object, just remove the key itself
         * Otherwise get primary experience if any and update [title,ai_org_id,ai_org_id] directly within formValues
         * Also update @norm_experience.org_name with string instead of object
         */

        if (
          formValues.norm_experience &&
          formValues.norm_experience.length === 1 &&
          !formValues.norm_experience[0].org_name_obj
        ) {
          delete formValues.norm_experience;
        } else if (
          formValues.norm_experience &&
          formValues.norm_experience.length > 0
        ) {
          const primaryExperience = formValues.norm_experience.find(
            (obj) => obj.is_primary === true,
          );
          if (primaryExperience) {
            formValues.title = primaryExperience.title;
            formValues.ai_org_id = primaryExperience.org_name_obj.value;
            formValues.org_name = primaryExperience.org_name_obj.label;
          } else {
            delete formValues.title;
            delete formValues.ai_org_id;
            delete formValues.org_name;
          }

          formValues.norm_experience = formValues.norm_experience
            .map((exp) => {
              if (exp.org_name_obj) {
                const newExperience = {
                  ...exp,
                  ai_org_id: exp.org_name_obj.value,
                  org_name: exp.org_name_obj.label,
                };
                delete newExperience.org_name_obj;
                return newExperience;
              } else return null;
            })
            .filter((experience) => experience !== null);
        }

        if (formValues.second_homes && formValues.second_homes.length > 0) {
          /** we need to delete primary address from secondary */
          const primaryIndex = formValues.second_homes.findIndex(
            (obj) => obj.is_primary === true,
          );
          if (primaryIndex >= 0) {
            const primaryAddress = formValues.second_homes.splice(
              formValues.second_homes.findIndex(
                (obj) => obj.is_primary === true,
              ),
              1,
            );

            const primaryAddressItems: SecondHome = primaryAddress[0] || {};

            if (
              primaryAddressItems &&
              Object.keys(primaryAddressItems).length > 0
            ) {
              formValues.home_address_line1 =
                primaryAddressItems.home_address_line1 || null;
              formValues.home_address_line2 =
                primaryAddressItems.home_address_line2 || null;
              formValues.home_city = primaryAddressItems.city || null;
              formValues.home_state = primaryAddressItems.state || null;
              formValues.home_postal_code =
                primaryAddressItems.postal_code || null;
              formValues.home_value_estimate =
                primaryAddressItems.value || null;
              formValues.norm_home_owner =
                primaryAddressItems.norm_home_owner || null;
            }
          } else {
            /** Incase we only removed primary, clear the existing data */
            formValues.home_address_line1 = null;
            formValues.home_address_line2 = null;
            formValues.home_city = null;
            formValues.home_state = null;
            formValues.home_postal_code = null;
            formValues.home_value_estimate = null;
            formValues.norm_home_owner = null;
          }

          // delete formValues.second_homes
        }

        /** formVals represents old values , formValues reps current changed data*/
        if (isUpdate && formVals.b2c_id !== formValues.b2c_id) {
          if (!formValues.b2c_id) {
            /** deleted old b2c -> unmerge old values */
            mergeUnmergeApi('unmerge', formVals.b2c_id).then((res) => {
              if (res && res.op === 'unmerge') {
                handleUpdate(formVals.id, formValues);
                message.success(
                  'This profile is being updated - please revisit in 24 hours',
                  10,
                );
              }
            });
          } else if (!formVals.b2c_id) {
            /** added new b2c, old b2c is empty -> merge new values */
            mergeUnmergeApi('merge', formValues.b2c_id).then((res) => {
              if (res && res.op === 'merge') {
                handleUpdate(formVals.id, formValues);
                message.success(
                  'This profile is being updated - please revisit in 24 hours',
                  10,
                );
              }
            });
          } else {
            /** deleted old b2c and added new -> unmerge old values ,merge new values */
            mergeUnmergeApi('unmerge', formVals.b2c_id).then((res) => {
              if (res.op === 'unmerge')
                mergeUnmergeApi('merge', formValues.b2c_id)
                  .then((res) => {
                    if (res && res.op === 'merge') {
                      handleUpdate(formVals.id, formValues);
                      message.success(
                        'This profile is being updated - please revisit in 24 hours',
                        10,
                      );
                    }
                  })
                  .catch(function (error) {
                    console.log(error);
                  });
            });
          }
        } else if (isUpdate) {
          handleUpdate(formVals.id, formValues);
          // this.setState(
          //     {
          //       formVals: formValues,
          //     },
          //     () => {
          //       delete formValues.normExperiences;
          //       handleUpdate(formValues);
          //     }
          //   );
        } else {
          handleCreate(formValues);
        }
      })
      .catch((errorInfo) => {
        if (errorInfo) {
          console.log(errorInfo);
          message.error('Please fill the mandatory fileds');
          return;
        }
      });
  };

  return (
    <Modal
      title={isUpdate ? 'Update Person' : 'New Person'}
      open
      width={1100}
      okText={isUpdate ? 'Update' : 'Create'}
      onOk={handleSubmit}
      confirmLoading={isModalLoading}
      onCancel={onClose}
    >
      <Spin size="default" spinning={isModalLoading}>
        <Form
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 13 }}
          form={form}
          initialValues={formVals || {}}
          className="profile-editor-form"
        >
          {/* {formItems(formVals)} */}
          <FormItems
            formVals={formVals}
            form={form}
            isUpdate={isUpdate}
            addRemoveExperience={false}
            onDateChange={onDateChange}
            onPrimaryChange={onPrimaryChange}
          />
          {/* {experienceItems(formVals.norm_experience, onDateChange)} */}
          {error && <div className="error-text">{error}</div>}
        </Form>
      </Spin>
    </Modal>
  );
};

interface SaveFields {
  org_name?: string;
  first_name?: string;
  middle_name?: string;
  last_name?: string;
  title?: string;
  email?: string;
  phone?: string;
  state?: string;
  location_city?: string;
  location_state?: string;
  age?: number;
}

const PeopleEditor = (props) => {
  const [listPagination, setListPagination] = useState({
    total: 0,
    pageSize: 50,
    current: 1,
  });
  const { pageSize, current } = listPagination;
  const [peopleEditorData, setPeopleEditorData] = useState([]);
  const [filteredInfoState, setFilteredInfoState] = useState([]);
  const [peopleDataCount, setPeopleDataCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [sortInfo, setSortInfo] = useState({
    column: '',
    order: '',
  });
  const [isUpdate, setIsUpdate] = useState(false);
  const [showFormModal, setShowFormModal] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [recordFormVals, setRecordFormVals] = useState(null);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  const searchInputRef = useRef(null);
  const [form] = Form.useForm();
  const { column, order } = sortInfo;

  const fetchPeopleData = useCallback(() => {
    setIsLoading(true);
    const newParams = {
      ...params,
      page_size: pageSize,
      from: (current - 1) * pageSize,
    };
    if (searchQuery) newParams.q = searchQuery;

    if (source) source.cancel('Operation canceled by the user.');
    const { CancelToken } = axios;
    source = CancelToken.source();

    requestWithAuth('vault-people', 'GET', newParams, null, source.token).then(
      (response) => {
        if (response && response.results) {
          setPeopleEditorData(response.results);
          setPeopleDataCount(response.count);
          setListPagination((prevState) => ({
            ...prevState,
            total: response.count,
          }));
          setIsLoading(false);
        }
      },
    );
  }, [pageSize, current, searchQuery]);

  useEffect(() => {
    return () => {
      params = {};
      setSortInfo({
        column: '',
        order: '',
      });
      setFilteredInfoState([]);
      // searchQuery = "";
      setSearchQuery('');
    };
  }, []);
  useEffect(() => {
    fetchPeopleData();
  }, [fetchPeopleData, filteredInfoState, column, order]);
  //   return () => {
  //     params = {};
  //     setSortInfo({
  //       column: "",
  //       order: "",
  //     });
  //     setFilteredInfoState([]);
  //     searchQuery = "";
  //   };
  // }, [fetchPeopleData]);

  const handleSearch = (selectedKeys, confirm, dataIndex, clearFilters) => {
    const searchData = selectedKeys[0];
    if (searchData !== undefined)
      switch (dataIndex) {
        case 'FirstName':
          params.first_name__icontains = searchData;
          break;
        case 'MiddleName':
          params.middle_name__icontains = searchData;
          break;
        case 'LastName':
          params.last_name__icontains = searchData;
          break;
        case 'FullName':
          params.full_name__icontains = searchData;
          break;
        case 'Company':
          params.org_name__icontains = searchData;
          break;
        case 'Title':
          params.title__icontains = searchData;
          break;
        case 'City':
          params.location_city__icontains = searchData;
          break;
        default:
          break;
      }
    confirm();
  };

  const handleReset = (clearFilters, confirm, dataIndex) => {
    clearFilters();
    switch (dataIndex) {
      case 'FirstName':
        delete params.first_name__icontains;
        break;
      case 'MiddleName':
        delete params.middle_name__icontains;
        break;
      case 'LastName':
        delete params.last_name__icontains;
        break;
      case 'FullName':
        delete params.full_name__icontains;
        break;
      case 'Company':
        delete params.org_name__icontains;
        break;
      case 'Title':
        delete params.title__icontains;
        break;
      case 'City':
        delete params.location_city__icontains;
        break;
      default:
        break;
    }
    fetchPeopleData();
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInputRef}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0] ? selectedKeys[0] : ''}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onPressEnter={() =>
            handleSearch(selectedKeys, confirm, dataIndex, clearFilters)
          }
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() =>
            handleSearch(selectedKeys, confirm, dataIndex, clearFilters)
          }
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters, confirm, dataIndex)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
      // style={{
      //     color: filtered ? 'var(--color-primary)' : undefined,
      // }}
      />
    ),

    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInputRef.current.select());
      }
    },
  });

  const handleTableChange = (pagination, filtersArg, sorter) => {
    Object.keys(filtersArg).reduce((obj, key) => {
      // const filt = { ...obj };
      let value = '';
      if (filtersArg[key] !== null) value = getValue(filtersArg[key]);
      if (key === 'location_state') {
        if (!value) {
          setFilteredInfoState([]);
          delete params.location_state__in;
        } else {
          // filt.location_state__in = value;
          setFilteredInfoState(value.split(','));
          params.location_state__in = value;
        }
      }
      return null;
    }, {});
    if (sorter && sorter.order && sorter.columnKey) {
      params.ordering =
        sorter.order === 'descend' ? '-' + sorter.columnKey : sorter.columnKey;
      setSortInfo({
        column: sorter.columnKey,
        order: sorter.order,
      });
    } else {
      setSortInfo({
        column: '',
        order: '',
      });
      delete params.ordering;
    }
    // setParams(params);
    // if (
    //   pagination.pageSize === listPagination.pageSize &&
    //   pagination.current === listPagination.current
    // ) {
    //   fetchPeopleData();
    // }
    setListPagination({
      ...pagination,
      pageSize: pagination.pageSize,
      current: pagination.current,
    });
  };

  const handleSave = (row) => {
    setIsLoading(true);
    const fields: SaveFields = {};
    fields.org_name = row.org_name;
    fields.first_name = row.first_name;
    fields.middle_name = row.middle_name;
    fields.last_name = row.last_name;
    fields.title = row.title;
    fields.location_city = row.location_city;
    fields.location_state = row.location_state;
    fields.age = row.age;
    // const params={field.id}

    requestWithAuth('vault-people/' + row.id, 'PATCH', null, fields).then(
      (response) => {
        if (response) {
          fetchPeopleData();
        }
        setIsLoading(false);
      },
    );
  };

  const handleDelete = (recordId, eventId) => {
    setIsLoading(true);
    requestWithAuth('vault-people/' + recordId, 'DELETE', null, null).then(
      () => {
        message.success('Person deleted');
        setIsLoading(false);
        fetchPeopleData();
      },
    );
  };

  const toggleFormModal = (flag) => {
    setShowFormModal(flag);
  };

  const handleSearchQuery = (searchValue) => {
    setSearchQuery(searchValue || '');
    setListPagination((prevState) => ({
      ...prevState,
      current: 1,
    }));
  };

  const handleFormReset = () => {
    params = {};
    setSortInfo({
      column: '',
      order: '',
    });
    setListPagination({
      current: 1,
      pageSize: 50,
      total: 0,
    });
    setFilteredInfoState([]);
    setSearchQuery('');
    //fetchPeopleData();
  };

  const showUpdateForm = (record) => {
    setIsUpdate(true);
    setShowFormModal(true);
    setRecordFormVals({ ...record });
  };

  const handleCreate = (fields) => {
    setIsModalLoading(true);
    setError(null);
    requestWithAuth('vault-people', 'POST', null, fields)
      .then((response) => {
        if (response && response.error) {
          setError(Object.values(response.error).join(', '));
          setIsModalLoading(false);
          return;
        }
        if (response) {
          fetchPeopleData();
          setShowFormModal(false);
        }

        setIsModalLoading(false);
      })
      .catch((e) => {
        setIsModalLoading(false);
        setShowFormModal(false);
      });
  };

  const handleUpdate = (recordId, fields) => {
    setIsModalLoading(true);
    setError(null);
    requestWithAuth(`vault-people/${recordId}`, 'PATCH', null, fields)
      .then((response) => {
        if (response && response.error) {
          setError(Object.values(response.error).join(', '));
          setIsModalLoading(false);
          return;
        } else if (response) {
          fetchPeopleData();
          setShowFormModal(false);
          setIsUpdate(false);
        }
        setIsModalLoading(false);
      })
      .catch((e) => {
        setIsModalLoading(false);
        setShowFormModal(false);
        setIsUpdate(false);
      });
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = [
    {
      title: 'b2b_id',
      width: 250,
      dataIndex: 'b2b_id',
      key: 'b2b_id',
      sorter: false,
      editable: false,
      render: (_val, rec) => {
        return (
          <div style={{ width: '100%', hyphens: 'none' }}>{rec.b2b_id}</div>
        );
      },
    },
    {
      title: 'b2c_id',
      width: 150,
      dataIndex: 'b2c_id',
      key: 'b2c_id',
      sorter: false,
      editable: false,
      render: (_val, rec) => {
        return (
          <div style={{ width: '100%', hyphens: 'none' }}>{rec.b2c_id}</div>
        );
      },
    },
    {
      title: 'First Name',
      width: 150,
      dataIndex: 'first_name',
      key: 'first_name',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortInfo.column === 'first_name' && sortInfo.order,
      ...getColumnSearchProps('FirstName'),
      editable: true,
      required: true,
      render: (_val, rec) => {
        return (
          <div style={{ width: '100%', wordBreak: 'break-word' }}>
            {rec.first_name}
          </div>
        );
      },
    },
    // {
    //     title: 'Middle Name',
    //     width: 150,
    //     dataIndex: 'middle_name',
    //     key: 'middle_name',
    //     sorter: true,
    //     sortDirections: ['ascend', 'descend'],
    //     sortOrder: sortInfo.column === 'middle_name' && sortInfo.order,
    //     ...getColumnSearchProps('MiddleName'),
    //     editable: true,
    //     render: (_val, rec) => {
    //         return <div style={{ width: '100%', wordBreak: 'break-word' }}>{rec.middle_name}</div>;
    //     },
    // },
    {
      title: 'Last Name',
      width: 150,
      dataIndex: 'last_name',
      key: 'last_name',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortInfo.column === 'last_name' && sortInfo.order,
      ...getColumnSearchProps('LastName'),
      editable: true,
      required: true,
      render: (_val, rec) => {
        return (
          <div style={{ width: '100%', wordBreak: 'break-word' }}>
            {rec.last_name}
          </div>
        );
      },
    },
    {
      title: 'Full Name',
      width: 150,
      dataIndex: 'full_name',
      key: 'full_name',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortInfo.column === 'full_name' && sortInfo.order,
      ...getColumnSearchProps('FullName'),
      render: (_val, rec) => {
        return (
          <div style={{ width: '100%', wordBreak: 'break-word' }}>
            {rec.full_name}
          </div>
        );
      },
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: 250,
      ...getColumnSearchProps('Title'),
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortInfo.column === 'title' && sortInfo.order,
      editable: true,
      render: (val) => {
        return (
          <div
            style={{ width: '100%', wordBreak: 'break-word', display: 'block' }}
          >
            {val}
          </div>
        );
      },
    },
    {
      title: 'Company',
      dataIndex: 'org_name',
      key: 'org_name',
      width: 250,
      ...getColumnSearchProps('Company'),
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortInfo.column === 'org_name' && sortInfo.order,
      editable: true,
      render: (val) => {
        return (
          <div
            style={{ width: '100%', wordBreak: 'break-word', display: 'block' }}
          >
            {val}
          </div>
        );
      },
    },
    {
      title: 'City',
      width: 150,
      dataIndex: 'location_city',
      key: 'location_city',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortInfo.column === 'location_city' && sortInfo.order,
      ...getColumnSearchProps('City'),
      editable: true,
      required: true,
      render: (val) => {
        return (
          <div
            style={{ width: '100%', wordBreak: 'break-word', display: 'block' }}
          >
            {val}
          </div>
        );
      },
    },
    {
      title: 'State',
      width: 100,
      dataIndex: 'location_state',
      key: 'location_state',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortInfo.column === 'location_state' && sortInfo.order,
      filters: filterStates,
      filteredValue: filteredInfoState || [],
      editable: true,
      required: true,
      render: (val) => {
        return (
          <div
            style={{ width: '100%', wordBreak: 'break-word', display: 'block' }}
          >
            {val}
          </div>
        );
      },
    },
    {
      title: 'Age',
      width: 70,
      dataIndex: 'age',
      key: 'age',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortInfo.column === 'age' && sortInfo.order,
      editable: true,
      required: true,
      render: (val) => {
        return (
          <div
            style={{ width: '100%', wordBreak: 'break-word', display: 'block' }}
          >
            {val}
          </div>
        );
      },
    },
    {
      title: 'LinkedIn',
      width: 200,
      dataIndex: 'norm_linkedin_urls',
      key: 'norm_linkedin_urls',
      // editable: true,
      render: (linkedIns) => (
        <div style={{ width: '100%' }}>
          {Array.isArray(linkedIns) &&
            linkedIns.map((linkedIn) => {
              const link = `//${linkedIn}`;
              return (
                <span key={linkedIn}>
                  <a
                    style={{ wordBreak: 'break-word', display: 'block' }}
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {linkedIn}
                  </a>{' '}
                </span>
              );
            })}
        </div>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 120,
      color: 'var(--color-primary)',
      onCell: () => ({
        onClick: (e) => {
          e.stopPropagation();
        },
      }),
      render: (text, record) => (
        <>
          <Button
            className="row-actions"
            onClick={() => showUpdateForm(record)}
          >
            Edit
          </Button>
          <Divider type="vertical" />
          <Popconfirm
            title="Delete?"
            onConfirm={() => handleDelete(record.id, record.events_id)}
          >
            <Button className="row-actions" onClick={(e) => e.preventDefault()}>
              Delete
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  const editableColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        required: col.required || false,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: handleSave,
        // onClick: (e) => e.stopPropagation(),
      }),
    };
  });

  return (
    <>
      <div className="people-editor">
        <TopSection
          count={peopleDataCount}
          placeholder="Search names, companies, LinkedIn, IDs, phones, or emails"
          handleSearchQuery={handleSearchQuery}
          handleFormReset={handleFormReset}
          toggleCreateForm={toggleFormModal}
          showSearch
          searchValue={searchQuery}
        />
        <Spin size="default" spinning={isLoading}>
          <CommonTable
            columns={editableColumns}
            data={peopleEditorData}
            components={components}
            rowClassName={() => 'editable-row'}
            onTableChange={handleTableChange}
            pagination={{ ...listPagination }}
            pageSize={listPagination.pageSize}
            loading={isLoading}
            rowEventHandlers={showUpdateForm}
            size="small"
            rowKey={(rec) => rec.id}
          />
        </Spin>
      </div>
      {showFormModal && (
        <FormModal
          form={form}
          isUpdate={isUpdate}
          handleUpdate={handleUpdate}
          handleCreate={handleCreate}
          isModalLoading={isModalLoading}
          recordFormVals={recordFormVals}
          error={error}
          onClose={() => {
            form.resetFields();
            setError(null);
            setIsUpdate(false);
            setShowFormModal(false);
          }}
        />
      )}
    </>
  );
};

export default PeopleEditor;
