import '../index.scss';

import { useCallback, useEffect, useState } from 'react';
import { Form, Input, message, Spin, Modal, InputNumber, Button } from 'antd';
import moment from 'moment';

import { requestWithAuth } from '../../services/api';
import CommonTable from '../../components/CommonTable';
import TopSection from '../../components/TopSection';
import CompanySuggest from '../../components/CompanySuggest';

interface ParamsType {
  is_user_map: number;
  ordering?: string;
  q?: string;
}

let params: ParamsType = { is_user_map: 1 };
//let searchQuery = '';

interface FormVals {
  id?: number;
}

const FormItems = ({ formVals, form, isUpdate }) => {
  useEffect(() => {
    form.resetFields();
  }, [formVals, form]);

  return [
    <Form.Item
      key="first_name"
      name="first_name"
      label="First name"
      initialValue={formVals.first_name}
    >
      <Input
        disabled={isUpdate}
        placeholder="First name"
        autoComplete="_away"
      />
    </Form.Item>,
    <Form.Item
      key="last_name"
      name="last_name"
      label="Last name"
      initialValue={formVals.last_name}
    >
      <Input disabled={isUpdate} placeholder="Last name" autoComplete="_away" />
    </Form.Item>,

    <Form.Item
      key="title"
      name="title"
      label="Title"
      initialValue={formVals.title}
    >
      <Input disabled={isUpdate} placeholder="Title" />
    </Form.Item>,

    <Form.Item
      key="org_name"
      name="org_name"
      label="Org Name"
      initialValue={{ label: formVals.org_name, value: formVals.ai_org_id }}
    >
      <CompanySuggest
        disabled={true}
        value={{ label: formVals.org_name, value: formVals.ai_org_id }}
      />
    </Form.Item>,

    <Form.Item
      key="email"
      name="email"
      label="Email"
      initialValue={formVals.email}
    >
      <Input disabled={isUpdate} placeholder="Email" />
    </Form.Item>,

    <Form.Item
      key="b2b_id"
      name="b2b_id"
      label="b2b_id"
      initialValue={formVals.b2b_id}
    >
      <Input />
    </Form.Item>,

    <Form.Item
      key="b2c_id"
      name="b2c_id"
      label="b2c_id"
      initialValue={formVals.b2c_id}
    >
      <InputNumber />
    </Form.Item>,
  ];
};

const FormModal = ({
  form,
  isUpdate,
  handleUpdate,
  isModalLoading,
  onClose,
  recordFormVals,
}) => {
  const [formVals, setFormVals] = useState<FormVals>({});

  useEffect(() => {
    if (isUpdate) {
      setFormVals({ ...recordFormVals });
    }
  }, [recordFormVals, isUpdate]);

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        const formValues = { ...values };
        if (formValues.org_name && formValues.org_name.label) {
          formValues.ai_org_id = formValues.org_name.key;
          formValues.org_name = formValues.org_name.label;
        } else {
          delete formValues.org_name;
        }

        if (isUpdate) {
          handleUpdate(formVals.id, formValues);
        }
      })
      .catch((errorInfo) => {
        if (errorInfo) {
          console.log(errorInfo);
          message.error('Please fill the mandatory fileds');
          return;
        }
      });
  };

  return (
    <Modal
      title={isUpdate ? 'Edit User' : 'New User'}
      visible
      width={800}
      okText={isUpdate ? 'Update' : 'Create'}
      onOk={handleSubmit}
      confirmLoading={isModalLoading}
      onCancel={onClose}
    >
      <Spin size="default" spinning={isModalLoading}>
        <Form labelCol={{ span: 7 }} wrapperCol={{ span: 13 }} form={form}>
          <FormItems formVals={formVals} form={form} isUpdate={isUpdate} />
        </Form>
      </Spin>
    </Modal>
  );
};

const UserMappingEditor = () => {
  const [listPagination, setListPagination] = useState({
    total: 0,
    pageSize: 50,
    current: 1,
  });
  const { pageSize, current } = listPagination;
  const [isLoading, setIsLoading] = useState(false);
  const [usersMappingData, setUsersMappingData] = useState([]);
  const [usersMappingCount, setUsersMappingCount] = useState(0);
  const [sortedInfo, setSortedInfo] = useState({
    column: '',
    order: '',
  });
  const { column, order } = sortedInfo;
  const [searchQuery, setSearchQuery] = useState('');
  const [isUpdate, setIsUpdate] = useState(false);
  const [showFormModal, setShowFormModal] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [recordFormVals, setRecordFormVals] = useState(null);

  const [form] = Form.useForm();

  const fetchUsersMappingData = useCallback(() => {
    setIsLoading(true);
    const newParams = {
      ...params,
      page_size: pageSize,
      page: current,
    };
    if (searchQuery) newParams.q = searchQuery;
    requestWithAuth('vault-user', 'GET', newParams, null).then((response) => {
      if (response && response.results) {
        setUsersMappingData(response.results);
        setUsersMappingCount(response.count);
        setListPagination((prevState) => ({
          ...prevState,
          total: response.count,
        }));
        setIsLoading(false);
      }
    });
  }, [pageSize, current, searchQuery]);

  useEffect(() => {
    fetchUsersMappingData();
  }, [fetchUsersMappingData, column, order]);

  useEffect(() => {
    return () => {
      params = { is_user_map: 1 };
    };
  }, []);

  const showUpdateForm = (record) => {
    setIsUpdate(true);
    setShowFormModal(true);
    setRecordFormVals({ ...record });
  };

  const handleSearchQuery = (searchValue) => {
    setSearchQuery(searchValue || '');
    setListPagination((prevState) => ({
      ...prevState,
      current: 1,
    }));
  };

  const handleFormReset = () => {
    params = { is_user_map: 1 };
    setSortedInfo({
      column: '',
      order: '',
    });
    setListPagination({
      current: 1,
      pageSize: 50,
      total: 0,
    });
    setSearchQuery('');
  };

  const handleUpdate = (recordId, fields) => {
    setIsModalLoading(true);
    requestWithAuth(`vault-user/${recordId}`, 'PUT', params, fields)
      .then((response) => {
        if (response) {
          fetchUsersMappingData();
          setShowFormModal(false);
          setIsUpdate(false);
        }
        setIsModalLoading(false);
      })
      .catch(() => {
        setIsModalLoading(false);
        setShowFormModal(false);
        setIsUpdate(false);
      });
  };

  const handleTableChange = (pagination, _, sorter) => {
    if (sorter && sorter.order && sorter.columnKey) {
      params.ordering =
        sorter.order === 'descend' ? '-' + sorter.columnKey : sorter.columnKey;
      setSortedInfo({
        column: sorter.columnKey,
        order: sorter.order,
      });
    } else {
      setSortedInfo({
        column: '',
        order: '',
      });
      delete params.ordering;
    }

    // if (
    //     pagination.pageSize === listPagination.pageSize &&
    //     pagination.current === listPagination.current
    // ) {
    //     fetchUsersMappingData();
    // }
    setListPagination({
      ...pagination,
      pageSize: pagination.pageSize,
      current: pagination.current,
    });
  };

  const columns = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 160,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortedInfo.column === 'email' && sortedInfo.order,
      render: (val) => {
        return <div className="column-style">{val}</div>;
      },
    },
    {
      title: 'First Name',
      dataIndex: 'first_name',
      key: 'first_name',
      width: 160,
      render: (val) => {
        return <div className="column-style">{val}</div>;
      },
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      key: 'last_name',
      width: 160,
      render: (val) => {
        return <div className="column-style">{val}</div>;
      },
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: 160,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortedInfo.column === 'title' && sortedInfo.order,
      render: (val) => {
        return <div className="column-style">{val}</div>;
      },
    },
    {
      title: 'Company',
      dataIndex: 'org_name',
      key: 'org_name',
      width: 160,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortedInfo.column === 'org_name' && sortedInfo.order,
      render: (val) => {
        return <div className="column-style">{val}</div>;
      },
    },
    {
      title: 'b2b_id',
      width: 250,
      dataIndex: 'b2b_id',
      key: 'b2b_id',
      sorter: false,
      editable: false,
      render: (_val, rec) => {
        return (
          <div style={{ width: '100%', hyphens: 'none' }}>{rec.b2b_id}</div>
        );
      },
    },
    {
      title: 'b2c_id',
      width: 150,
      dataIndex: 'b2c_id',
      key: 'b2c_id',
      sorter: false,
      editable: false,
      render: (_val, rec) => {
        return (
          <div style={{ width: '100%', hyphens: 'none' }}>{rec.b2c_id}</div>
        );
      },
    },
    {
      title: 'Date',
      dataIndex: 'create_time',
      key: 'create_time',
      width: 160,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortedInfo.column === 'create_time' && sortedInfo.order,
      render: (val) => {
        return (
          <div className="column-style">
            {val ? moment(val).format('MM/DD/YY') : ''}
          </div>
        );
      },
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 120,
      onCell: () => ({
        onClick: (e) => {
          e.stopPropagation();
        },
      }),
      render: (text, record) => (
        <>
          <Button
            className="row-actions"
            onClick={() => showUpdateForm(record)}
          >
            Edit
          </Button>
        </>
      ),
    },
  ];
  return (
    <>
      <div>
        <TopSection
          placeholder="Search name, email"
          handleSearchQuery={handleSearchQuery}
          handleFormReset={handleFormReset}
          showNewButton={false}
          showSearch
          count={usersMappingCount}
          searchValue={searchQuery}
        />
        <Spin size="default" spinning={isLoading}>
          <CommonTable
            columns={columns}
            data={usersMappingData}
            onTableChange={handleTableChange}
            pagination={{ ...listPagination }}
            pageSize={listPagination.pageSize}
            loading={isLoading}
            rowEventHandlers={showUpdateForm}
          />
        </Spin>
      </div>
      {showFormModal && (
        <FormModal
          form={form}
          isUpdate={isUpdate}
          handleUpdate={handleUpdate}
          isModalLoading={isModalLoading}
          recordFormVals={recordFormVals}
          onClose={() => {
            form.resetFields();
            setIsUpdate(false);
            setShowFormModal(false);
          }}
        />
      )}
    </>
  );
};

export default UserMappingEditor;
