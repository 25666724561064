import '../index.scss';

import { SearchOutlined } from '@ant-design/icons';
import {
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Select,
  Spin,
} from 'antd';
import moment from 'moment';
import { useCallback, useEffect, useRef, useState } from 'react';
import CommonTable from '../../components/CommonTable';
import { EditableCell, EditableRow } from '../../components/EditableComponents';
import TopSection from '../../components/TopSection';
import { requestWithAuth } from '../../services/api';
import { filterStates } from '../../utils/constants';
import { getValue, splitNumberWithCommas } from '../../utils/general';

interface CompanyParams {
  ordering: string;
  state__in?: string;
  name__icontains?: string;
  city__icontains?: string;
  page_size?: number;
  from?: number;
  q?: string;
}

let params: CompanyParams = {
  ordering: '-employee_headcount',
};

moment.fn.toJSON = function () {
  return this.format('YYYY-MM-DD');
};

const FormItems = ({ formVals, form }) => {
  const flattenArray = (list) => (Array.isArray(list) ? list.join('\n') : '');

  useEffect(() => {
    form.resetFields();
  }, [formVals, form]);

  return [
    <Form.Item key="id" name="id" label="id" initialValue={formVals.id}>
      <Input placeholder="id" disabled />
    </Form.Item>,

    <Form.Item
      key="ai_org_id"
      name="ai_org_id"
      label="ai org id"
      initialValue={formVals.ai_org_id}
    >
      <Input placeholder="ai org id" disabled />
    </Form.Item>,

    <Form.Item
      key="b2b_org_id"
      name="b2b_org_id"
      label="b2b org id"
      initialValue={formVals.b2b_org_id}
    >
      <Input placeholder="b2b org id" disabled />
    </Form.Item>,

    <Form.Item
      key="owler_id"
      name="owler_id"
      label="Owler id"
      initialValue={formVals.owler_id}
    >
      <Input placeholder="Owler id" disabled />
    </Form.Item>,

    <Form.Item
      key="crunchbase_id"
      name="crunchbase_id"
      label="Crunchbase id"
      initialValue={formVals.crunchbase_id}
    >
      <Input placeholder="Crunchbase id" disabled />
    </Form.Item>,

    <Form.Item key="name" name="name" label="Name" initialValue={formVals.name}>
      {/* <CompanySuggest
                value={{ label: formVals.name, value: formVals.ai_org_id }}
            /> */}
      <Input placeholder="Name" />
    </Form.Item>,

    <Form.Item
      key="short_name"
      name="short_name"
      label="Short name"
      initialValue={formVals.short_name}
    >
      <Input placeholder="Short name" />
    </Form.Item>,

    <Form.Item
      key="alt_names"
      name="alt_names"
      label="Alt names"
      initialValue={flattenArray(formVals.alt_names)}
    >
      <Input.TextArea
        autoSize={{ minRows: 3, maxRows: 8 }}
        placeholder="Alt names, one per line"
        autoComplete="_away"
      />
    </Form.Item>,

    <Form.Item
      key="short_description"
      name="short_description"
      label="Short description"
      initialValue={formVals.short_description}
    >
      <Input placeholder="Short description" />
    </Form.Item>,

    <Form.Item
      key="founded"
      name="founded"
      label="Founded date"
      initialValue={formVals.founded ? moment(formVals.founded) : undefined}
    >
      <DatePicker placeholder="Date" style={{ width: '100%' }} />
    </Form.Item>,

    <Form.Item
      key="address_line1"
      name="address_line1"
      label="Address line 1"
      initialValue={formVals.address_line1}
    >
      <Input placeholder="Address line 1" autoComplete="_away" />
    </Form.Item>,

    <Form.Item
      key="address_line2"
      name="address_line2"
      label="Address line 2"
      initialValue={formVals.address_line2}
    >
      <Input placeholder="Address line 2" autoComplete="_away" />
    </Form.Item>,

    <Form.Item key="city" name="city" label="City" initialValue={formVals.city}>
      <Input placeholder="City" />
    </Form.Item>,

    <Form.Item
      key="state"
      name="state"
      label="State"
      initialValue={formVals.state}
    >
      <Select style={{ width: '100%' }}>
        {filterStates.map(({ value, text }) => (
          <Select.Option key={value} value={text}>
            {text}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>,

    <Form.Item
      key="region"
      name="region"
      label="Region"
      initialValue={formVals.region}
    >
      <Input placeholder="Region" />
    </Form.Item>,

    <Form.Item
      key="postal_code"
      name="postal_code"
      label="Postal code"
      initialValue={formVals.postal_code}
    >
      <Input placeholder="Postal code" />
    </Form.Item>,

    <Form.Item
      key="industry"
      name="industry"
      label="Industry"
      initialValue={formVals.industry}
    >
      <Input placeholder="Industry" />
    </Form.Item>,

    <Form.Item key="type" name="type" label="Type" initialValue={formVals.type}>
      <Input placeholder="Type" />
    </Form.Item>,

    <Form.Item
      key="status"
      name="status"
      label="Status"
      initialValue={formVals.status}
    >
      <Input placeholder="Status" />
    </Form.Item>,

    <Form.Item
      key="crunchbase_status"
      name="crunchbase_status"
      label="Crunchbase status"
      initialValue={formVals.crunchbase_status}
    >
      <Input placeholder="Crunchbase status" />
    </Form.Item>,

    <Form.Item
      key="naics_codes"
      name="naics_codes"
      label="NAICS codes"
      initialValue={flattenArray(formVals.naics_codes)}
    >
      <Input.TextArea
        autoSize={{ minRows: 3, maxRows: 8 }}
        placeholder="Enter NAICS codes, one per line"
        autoComplete="_away"
      />
    </Form.Item>,

    <Form.Item
      key="naics_descriptions"
      name="naics_descriptions"
      label="NAICS descriptions"
      initialValue={flattenArray(formVals.naics_descriptions)}
    >
      <Input.TextArea
        autoSize={{ minRows: 3, maxRows: 8 }}
        placeholder="Enter NAICS descriptions, one per line"
        autoComplete="_away"
      />
    </Form.Item>,

    <Form.Item
      key="phone"
      name="phone"
      label="Phone"
      initialValue={formVals.phone}
    >
      <Input placeholder="Phone" />
    </Form.Item>,

    <Form.Item
      key="email"
      name="email"
      label="Emails"
      initialValue={flattenArray(formVals.email)}
    >
      <Input.TextArea
        autoSize={{ minRows: 3, maxRows: 8 }}
        placeholder="Emails, one per line"
        autoComplete="_away"
      />
    </Form.Item>,

    <Form.Item
      key="email_domains"
      name="email_domains"
      label="Email domains"
      initialValue={flattenArray(formVals.email_domains)}
    >
      <Input.TextArea
        autoSize={{ minRows: 3, maxRows: 8 }}
        placeholder="Enter email domains, one per line"
        autoComplete="_away"
      />
    </Form.Item>,

    <Form.Item
      key="domain"
      name="domain"
      label="Domain"
      initialValue={formVals.domain}
    >
      <Input placeholder="Domain" />
    </Form.Item>,

    <Form.Item
      key="url"
      name="url"
      label="url"
      extra="Note: used for Chrome extension lookup"
      initialValue={formVals.url}
    >
      <Input placeholder="url" />
    </Form.Item>,

    <Form.Item
      key="crunchbase_url"
      name="crunchbase_url"
      label="Crunchbase url"
      initialValue={formVals.crunchbase_url}
    >
      <Input placeholder="Crunchbase url" />
    </Form.Item>,

    <Form.Item
      key="facebook_url"
      name="facebook_url"
      label="Facebook url"
      initialValue={formVals.facebook_url}
    >
      <Input placeholder="Facebook url" />
    </Form.Item>,

    <Form.Item
      key="linkedin_id"
      name="linkedin_id"
      label="Linkedin id"
      initialValue={formVals.linkedin_id}
    >
      <Input placeholder="Linkedin id" />
    </Form.Item>,

    <Form.Item
      key="linkedin_url"
      name="linkedin_url"
      label="Linkedin url"
      initialValue={formVals.linkedin_url}
    >
      <Input placeholder="Linkedin url" />
    </Form.Item>,

    <Form.Item
      key="twitter_url"
      name="twitter_url"
      label="Twitter url"
      initialValue={formVals.twitter_url}
    >
      <Input placeholder="Twitter url" />
    </Form.Item>,

    <Form.Item
      key="logo_url"
      name="logo_url"
      label="Logo url"
      initialValue={formVals.logo_url}
    >
      <Input placeholder="Logo url" />
    </Form.Item>,

    <Form.Item
      key="num_funding_rounds"
      name="num_funding_rounds"
      label="Num funding rounds"
      initialValue={formVals.num_funding_rounds}
    >
      <Input placeholder="Num funding rounds" />
    </Form.Item>,

    <Form.Item
      key="total_funding_usd"
      name="total_funding_usd"
      label="Total funding"
      initialValue={formVals.total_funding_usd}
    >
      <Input placeholder="Total funding" />
    </Form.Item>,

    <Form.Item
      key="last_funding_on"
      name="last_funding_on"
      label="Last funding on"
      initialValue={
        formVals.last_funding_on ? moment(formVals.last_funding_on) : undefined
      }
    >
      <DatePicker placeholder="Last funding on" style={{ width: '100%' }} />
    </Form.Item>,

    <Form.Item key="geo" name="geo" label="Geo" initialValue={formVals.geo}>
      <Input placeholder="Geo" />
    </Form.Item>,

    <Form.Item
      key="closed_on"
      name="closed_on"
      label="Closed on"
      initialValue={formVals.closed_on ? moment(formVals.closed_on) : undefined}
    >
      <DatePicker placeholder="Closed on" style={{ width: '100%' }} />
    </Form.Item>,

    <Form.Item
      key="stock_exchange"
      name="stock_exchange"
      label="Stock exchange"
      initialValue={formVals.stock_exchange}
    >
      <Input placeholder="Stock exchange" />
    </Form.Item>,

    <Form.Item
      key="stock_ticker"
      name="stock_ticker"
      label="Stock Ticker"
      initialValue={formVals.stock_ticker}
    >
      <Input placeholder="Stock Ticker" />
    </Form.Item>,

    <Form.Item
      key="num_exits"
      name="num_exits"
      label="Num exits"
      initialValue={formVals.num_exits}
    >
      <Input placeholder="Num exits" />
    </Form.Item>,
  ];
};

interface FormValsType {
  id?: number;
}

const FormModal = ({
  form,
  isUpdate,
  handleUpdate,
  handleCreate,
  isModalLoading,
  onClose,
  recordFormVals,
}) => {
  const [formVals, setFormVals] = useState<FormValsType>({});

  useEffect(() => {
    if (isUpdate) {
      setFormVals({ ...recordFormVals });
    }
  }, [recordFormVals, isUpdate]);

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        const formValues = { ...values };

        // if (formValues.name && formValues.name.label) {
        //     formValues.ai_org_id = formValues.name.key;
        //     formValues.name = formValues.name.label;
        // } else {
        //     delete formValues.org_name;
        // }
        // if (formValues.date)
        //     formValues.date = moment(formValues.date);
        // else
        //     delete formValues.date;
        if (formValues.total_funding_usd)
          formValues.total_funding_usd = formValues.total_funding_usd
            .toString()
            .replaceAll(',', '');
        [
          'alt_names',
          'naics_codes',
          'naics_descriptions',
          'email',
          'email_domains',
        ].forEach((field) => {
          if (formValues[field] && formValues[field] !== '') {
            const arrayValues =
              formValues[field].length > 0
                ? formValues[field]
                    .split('\n')
                    .filter((element) => element.length > 0)
                : formValues[field];
            if (arrayValues && arrayValues.length > 0) {
              formValues[field] = arrayValues;
            }
          } else formValues[field] = [];
        });
        if (isUpdate) {
          handleUpdate(formVals.id, formValues);
        } else {
          handleCreate(formValues);
        }
      })
      .catch((errorInfo) => {
        if (errorInfo) {
          console.log(errorInfo);
          message.error('Please fill the mandatory fileds');
          return;
        }
      });
  };

  return (
    <Modal
      title={isUpdate ? 'Update Company' : 'New Company'}
      visible
      width={800}
      okText={isUpdate ? 'Update' : 'Create'}
      onOk={handleSubmit}
      confirmLoading={isModalLoading}
      onCancel={onClose}
    >
      <Spin size="default" spinning={isModalLoading}>
        <Form labelCol={{ span: 7 }} wrapperCol={{ span: 13 }} form={form}>
          <FormItems formVals={formVals} form={form} />
        </Form>
      </Spin>
    </Modal>
  );
};

const CompanyEditor = () => {
  const [listPagination, setListPagination] = useState({
    total: 0,
    pageSize: 50,
    current: 1,
  });
  const { pageSize, current } = listPagination;
  const [isLoading, setIsLoading] = useState(false);
  const [sortInfo, setSortInfo] = useState({
    column: 'employee_headcount',
    order: 'descend',
  });
  const [allClearFilters, setAllClearFilters] = useState({});
  const [companyData, setCompanyData] = useState([]);
  const [companyCount, setCompanyCount] = useState(0);
  const [filteredInfoState, setFilteredInfoState] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [showFormModal, setShowFormModal] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [recordFormVals, setRecordFormVals] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  const searchInputRef = useRef(null);
  const [form] = Form.useForm();
  const { column, order } = sortInfo;

  const fetchCompanyData = useCallback(() => {
    setIsLoading(true);
    const newParams = {
      ...params,
      page_size: pageSize,
      from: (current - 1) * pageSize,
      // page: listPagination.current,
    };
    if (searchQuery) newParams.q = searchQuery;
    requestWithAuth('vault-company', 'GET', newParams, null).then(
      (response) => {
        if (response && response.results) {
          setCompanyData(response.results);
          setCompanyCount(response.count);
          setListPagination((prevState) => ({
            ...prevState,
            total: response.count,
          }));
        }
        setIsLoading(false);
      },
    );
  }, [pageSize, current, searchQuery]);

  const handleFormReset = () => {
    if (allClearFilters && Object.keys(allClearFilters).length)
      Object.keys(allClearFilters).map((key) => allClearFilters[key]());
    params = { ordering: '-employee_headcount' };
    setSortInfo({
      column: '',
      order: '',
    });
    setListPagination({
      current: 1,
      pageSize: 50,
      total: 0,
    });
    setFilteredInfoState([]);
    setAllClearFilters({});
    setSearchQuery('');
    //fetchCompanyData();
  };

  useEffect(() => {
    return () => {
      //handleFormReset();
      // if (allClearFilters && Object.keys(allClearFilters).length)
      //   Object.keys(allClearFilters).map((key) => allClearFilters[key]());
      params = { ordering: '-employee_headcount' };
      setSortInfo({
        column: '',
        order: '',
      });
      setListPagination({
        current: 1,
        pageSize: 50,
        total: 0,
      });
      setFilteredInfoState([]);
      setAllClearFilters({});
      setSearchQuery('');
    };
  }, []);

  useEffect(() => {
    fetchCompanyData();

    // return () => {
    //   //handleFormReset();
    //   if (allClearFilters && Object.keys(allClearFilters).length)
    //     Object.keys(allClearFilters).map((key) => allClearFilters[key]());
    //   params = {};
    //   setSortInfo({
    //     column: "",
    //     order: "",
    //   });
    //   setListPagination({
    //     current: 1,
    //     pageSize: 50,
    //   });
    //   setFilteredInfoState([]);
    //   setAllClearFilters({});
    //   searchQuery = "";
    // };
  }, [fetchCompanyData, filteredInfoState, column, order]);

  //   useEffect(() => {
  //     if (total === 0) return;
  //     fetchCompanyData();
  //   }, [fetchCompanyData, total]);

  const toggleFormModal = (flag) => {
    setShowFormModal(flag);
  };

  const showUpdateForm = (record) => {
    setIsUpdate(true);
    setShowFormModal(true);
    setRecordFormVals({ ...record });
  };

  const handleSave = (row) => {
    setIsLoading(true);
    const fields = {
      name: row.name,
      address_line1: row.address_line1,
      city: row.city,
      state: row.state,
      address_line2: row.address_line2,
    };

    requestWithAuth('vault-company/' + row.id, 'PUT', null, fields).then(
      (response) => {
        if (response) {
          fetchCompanyData();
          setIsLoading(false);
        }
      },
    );
  };

  const handleDelete = (recordId) => {
    setIsLoading(true);
    requestWithAuth('vault-company/' + recordId, 'DELETE', null, null).then(
      () => {
        message.success('Company deleted');
        setIsLoading(false);
        fetchCompanyData();
      },
    );
  };

  const handleCreate = (fields) => {
    setIsModalLoading(true);
    requestWithAuth('vault-company', 'POST', null, fields)
      .then((response) => {
        if (response) {
          fetchCompanyData();
          setShowFormModal(false);
        }

        setIsModalLoading(false);
      })
      .catch(() => {
        setIsModalLoading(false);
        setShowFormModal(false);
      });
  };

  const handleUpdate = (recordId, fields) => {
    setIsModalLoading(true);
    requestWithAuth(`vault-company/${recordId}`, 'PUT', null, fields)
      .then((response) => {
        if (response) {
          fetchCompanyData();
          setShowFormModal(false);
          setIsUpdate(false);
        }

        setIsModalLoading(false);
      })
      .catch(() => {
        setIsModalLoading(false);
        setShowFormModal(false);
        setIsUpdate(false);
      });
  };

  const handleSearchQuery = (searchValue) => {
    setSearchQuery(searchValue || '');
    setListPagination((prevState) => ({
      ...prevState,
      current: 1,
    }));
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInputRef}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0] ? selectedKeys[0] : ''}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            setAllClearFilters({
              ...allClearFilters,
              [dataIndex]: clearFilters,
            });
          }}
          onPressEnter={() =>
            handleSearch(selectedKeys, confirm, dataIndex, clearFilters)
          }
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() =>
            handleSearch(selectedKeys, confirm, dataIndex, clearFilters)
          }
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters, confirm, dataIndex)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? 'var(--color-primary)' : undefined,
        }}
      />
    ),

    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInputRef.current.select());
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex, clearFilters) => {
    const searchData = selectedKeys[0];
    if (searchData !== undefined)
      switch (dataIndex) {
        case 'Name':
          params.name__icontains = searchData;
          break;
        case 'City':
          params.city__icontains = searchData;
          break;
        default:
          break;
      }
    setAllClearFilters({
      ...allClearFilters,
      [dataIndex]: clearFilters,
    });
    confirm();
  };

  const handleReset = (clearFilters, _confirm, dataIndex) => {
    clearFilters();
    switch (dataIndex) {
      case 'Name':
        delete params.name__icontains;
        break;
      case 'City':
        delete params.city__icontains;
        break;
      default:
        break;
    }
    fetchCompanyData();
  };

  const handleTableChange = (pagination, filtersArg, sorter) => {
    Object.keys(filtersArg).reduce((_, key) => {
      let value = '';
      if (filtersArg[key] !== null) value = getValue(filtersArg[key]);
      if (key === 'state') {
        if (!value) {
          setFilteredInfoState([]);
          delete params.state__in;
        } else {
          setFilteredInfoState(value.split(','));
          params.state__in = value;
        }
      }
      return '';
    }, {});
    if (sorter && sorter.order && sorter.columnKey) {
      params.ordering =
        sorter.order === 'descend' ? '-' + sorter.columnKey : sorter.columnKey;
      setSortInfo({
        column: sorter.columnKey,
        order: sorter.order,
      });
    } else {
      setSortInfo({
        column: '',
        order: '',
      });
      delete params.ordering;
    }
    // setParams(params);
    // if (pagination.pageSize === pageSize && pagination.current === current) {
    //   fetchCompanyData();
    // }
    setListPagination({
      ...pagination,
      pageSize: pagination.pageSize,
      current: pagination.current,
    });
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 180,
      editable: true,
      ...getColumnSearchProps('Name'),
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortInfo.column === 'name' && sortInfo.order,
      render: (val) => {
        return (
          <div
            style={{ width: '100%', wordBreak: 'break-word', display: 'block' }}
          >
            {val}
          </div>
        );
      },
    },
    {
      title: 'Employee Head Count',
      dataIndex: 'employee_headcount',
      key: 'employee_headcount',
      width: 180,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortInfo.column === 'employee_headcount' && sortInfo.order,
      render: (val) => {
        return (
          <div
            style={{ width: '100%', wordBreak: 'break-word', display: 'block' }}
          >
            {splitNumberWithCommas(val)}
          </div>
        );
      },
    },
    {
      title: 'url',
      dataIndex: 'url',
      key: 'url',
      width: 160,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortInfo.column === 'url' && sortInfo.order,
      render: (val) => {
        return (
          <div
            style={{ width: '100%', wordBreak: 'break-word', display: 'block' }}
          >
            {val}
          </div>
        );
      },
    },
    {
      title: 'Address Line1',
      dataIndex: 'address_line1',
      key: 'address_line1',
      width: 180,
      editable: true,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortInfo.column === 'address_line1' && sortInfo.order,
      render: (val) => {
        return (
          <div
            style={{ width: '100%', wordBreak: 'break-word', display: 'block' }}
          >
            {val ? val.toTitleCase() : ''}
          </div>
        );
      },
    },
    {
      title: 'Address Line2',
      dataIndex: 'address_line2',
      key: 'address_line2',
      width: 180,
      editable: true,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortInfo.column === 'address_line2' && sortInfo.order,
      render: (val) => {
        return (
          <div
            style={{ width: '100%', wordBreak: 'break-word', display: 'block' }}
          >
            {val ? val.toTitleCase() : ''}
          </div>
        );
      },
    },
    {
      title: 'City',
      width: 150,
      dataIndex: 'city',
      key: 'city',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortInfo.column === 'city' && sortInfo.order,
      ...getColumnSearchProps('City'),
      editable: true,
      render: (val) => {
        return (
          <div
            style={{ width: '100%', wordBreak: 'break-word', display: 'block' }}
          >
            {val ? val.toTitleCase() : ''}
          </div>
        );
      },
    },
    {
      title: 'State',
      width: 100,
      dataIndex: 'state',
      key: 'state',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortInfo.column === 'state' && sortInfo.order,
      filters: filterStates,
      filteredValue: filteredInfoState || [],
      editable: true,
      render: (val) => {
        return (
          <div
            style={{ width: '100%', wordBreak: 'break-word', display: 'block' }}
          >
            {val}
          </div>
        );
      },
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 120,
      onCell: () => ({
        onClick: (e) => {
          e.stopPropagation();
        },
      }),
      render: (_text, record) => (
        <>
          <span
            style={{ color: 'var(--color-primary)' }}
            onClick={() => showUpdateForm(record)}
          >
            Edit
          </span>
          <Divider type="vertical" />
          <Popconfirm title="Delete?" onConfirm={() => handleDelete(record.id)}>
            <span
              style={{ color: 'var(--color-primary)' }}
              onClick={(e) => e.preventDefault()}
            >
              Delete
            </span>
          </Popconfirm>
        </>
      ),
    },
  ];

  const editableColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: handleSave,
        // onClick: (e)=>e.stopPropagation()
      }),
    };
  });

  return (
    <>
      <div className="people-editor">
        <TopSection
          count={companyCount}
          placeholder="Search company names, short names, alt names, url, naics codes, stock ticker or linkedin "
          handleSearchQuery={handleSearchQuery}
          handleFormReset={handleFormReset}
          toggleCreateForm={toggleFormModal}
          showSearch
          searchValue={searchQuery}
        />
        <Spin size="default" spinning={isLoading}>
          <CommonTable
            columns={editableColumns}
            data={companyData}
            components={components}
            rowClassName={() => 'editable-row'}
            onTableChange={handleTableChange}
            pagination={{ ...listPagination }}
            pageSize={listPagination.pageSize}
            loading={isLoading}
            rowEventHandlers={showUpdateForm}
          />
        </Spin>
      </div>
      {showFormModal && (
        <FormModal
          form={form}
          isUpdate={isUpdate}
          handleUpdate={handleUpdate}
          handleCreate={handleCreate}
          isModalLoading={isModalLoading}
          recordFormVals={recordFormVals}
          onClose={() => {
            form.resetFields();
            setIsUpdate(false);
            setShowFormModal(false);
          }}
        />
      )}
    </>
  );
};

export default CompanyEditor;
