import { Button, Form, Input, Spin, Tabs, message } from 'antd';
import { useEffect, useState } from 'react';
import profilePlaceholder from '../../assets/images/profile-placeholder.png';
import { requestWithAuth } from '../../services/api';
import './index.scss';

const Account = (props) => {
  const defaultName = props.name;

  const { TabPane } = Tabs;
  const FormItem = Form.Item;
  const [name, setName] = useState(defaultName || '');
  const [email, setEmail] = useState('');
  const [ownerId, setOwnerId] = useState('');
  const [avatarSrc, setAvatarSrc] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    document.getElementById('cover-spin').style.display = 'block';
    requestWithAuth('user-type', 'GET', null, null).then((response) => {
      document.getElementById('cover-spin').style.display = 'none';
      if (response && response.results && response.results[0]) {
        setName(response.results[0].full_name);
        setEmail(response.results[0].email);
        setOwnerId(response.results[0].id);
        if (response.results[0].avatar) {
          setAvatarSrc(response.results[0].avatar);
        }
      }
    });
  }, []);

  const handleSubmit = (values) => {
    const name = values.fullName.split(' ');
    const firstName = name && name.length > 0 && name[0] ? name[0] : '';
    name.shift();
    const lastName = name && name.length > 0 ? name.join(' ') : '';
    const body = {
      first_name: firstName,
      last_name: lastName,
    };

    setIsLoading(true);
    requestWithAuth(`user-type/${ownerId}`, 'PATCH', null, body, null).then(
      (res) => {
        setIsLoading(false);
        if (res.code && res.code === 200) {
          message.success('Profile Updated');
        }
      },
    );
  };

  return (
    <div className="account-settings">
      <div id="cover-spin" style={{ display: 'none' }}>
        <Spin className="spinUser" size="default" />
      </div>
      <Tabs defaultActiveKey="settings" tabPosition="left">
        <TabPane tab="Basic Settings" key="settings">
          <div className="tab-pane-content">
            <div className="title">Basic Settings</div>
            <div className="content">
              <div className="form-area">
                <Form
                  layout="vertical"
                  onFinish={handleSubmit}
                  hideRequiredMark
                  initialValues={{ fullName: defaultName }}
                >
                  <FormItem
                    label="Name"
                    name="fullName"
                    rules={[
                      { required: true, message: 'Name cannot be empty' },
                    ]}
                  >
                    <Input
                      className="formItem"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </FormItem>

                  <Form.Item label="Email">
                    <Input className="formItem" value={email} disabled />
                  </Form.Item>

                  <FormItem>
                    <Button
                      loading={isLoading}
                      htmlType="submit"
                      type="primary"
                    >
                      Update Information
                    </Button>
                  </FormItem>
                </Form>
              </div>
              <div className="avatar-area">
                <div className="avatar">
                  <img
                    src={avatarSrc ? avatarSrc : profilePlaceholder}
                    alt="avatar"
                  />
                </div>
              </div>
            </div>
          </div>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Account;
