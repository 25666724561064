import '../index.scss';

import { ReactNode, useCallback, useEffect, useState } from 'react';
import { Spin, Table, Tooltip, Tag, Input, Button } from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';

import { requestWithAuth } from '../../services/api';
import TopSection from '../../components/TopSection';
import { splitNumberWithCommas, getValue } from '../../utils/general';
import ExportCsv from '../../components/ExportCsv';

interface RequestParams {
  page_size?: number;
  page?: number;
  q?: string;
  ordering?: string;
}

let params: RequestParams = {};

interface ParamsType {
  ordering?: string;
  is_staff?: number;
  user_type?: string;
  user_ref_code_expiry_date__isnull?: string;
  total_matched_record_count__isnull?: string;
  is_corporate?: string;
  b2b_id__isblank?: string;
}

let paramsSecondary: ParamsType = {};
interface FiltersStateSecondary {
  total_matched_record_count?: string[];
  b2b_id?: string[];
  user_type?: string[];
  is_corporate?: string[];
  user_ref_code_expiry_date?: string[];
  is_staff?: string[];
}

// interface column {
//   title: string;
//   key: string;
//   dataIndex?: string;
//   width?: number | string;
//   sorter?: boolean | object;
//   sortDirections?: ('ascend' | 'descend')[];
//   sortOrder?: 'ascend' | 'descend' | null;
//   minWidth?: number;
//   filters?: object[];
//   filterMultiple?: boolean;
//   filteredValue?: string[];
//   render?: (value, record, index) => ReactNode;
// }

const Reports = (props: object) => {
  const [isLoading, setIsLoading] = useState(false);
  const [orgData, setOrgData] = useState([]);
  const [orgUserData, setOrgUserData] = useState([]);
  const [userReportsData, setUserReportsData] = useState([]);
  const [userTypesData, setUserTypesData] = useState([]);
  const [reportsOrgCount, setReportsOrgCount] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [primaryTableExpandedRowKey, setPrimaryTableExpandedRowKey] =
    useState(null);
  const [secondaryTableExpandedRowKey, setSecondaryTableExpandedRowKey] =
    useState(null);
  const [filtersStateSecondary, setFiltersStateSecondary] =
    useState<FiltersStateSecondary>({});
  const [sorterStatePrimary, setSorterStatePrimary] = useState([]);
  const [sorterStateSecondary, setSorterStateSecondary] = useState([]);
  const [listPagination, setListPagination] = useState({
    total: 0,
    pageSize: 50,
    current: 1,
  });
  const [secondListPagination, setSecondListPagination] = useState({
    total: 0,
    pageSize: 50,
    current: 1,
  });
  const [thirdListPagination, setThirdListPagination] = useState({
    total: 0,
    pageSize: 50,
    current: 1,
  });
  // const [sortedInfo, setSortedInfo] = useState({
  //   column: '',
  //   order: '',
  // });
  // const { column, order } = sortedInfo;
  const { pageSize, current } = listPagination;

  // let searchQuery = '';

  const paginationProps = {
    showSizeChanger: true,
    showQuickJumper: true,
    // changed page size options to 50,100 and 200
    pageSizeOptions: ['20', '50', '100', '200'],
    /** by default defaultPageSize is 10,
     * to change that to current size or 200
     */
    defaultPageSize: pageSize,
    // set selected page size of user even after signout
    ...listPagination,
  };

  const secondPaginationProps = {
    showSizeChanger: true,
    showQuickJumper: true,
    pageSizeOptions: ['20', '50', '100', '200'],
    defaultPageSize: secondListPagination.pageSize,
    ...secondListPagination,
  };

  const ThirdPaginationProps = {
    showSizeChanger: true,
    showQuickJumper: true,
    pageSizeOptions: ['20', '50', '100', '200'],
    defaultPageSize: thirdListPagination.pageSize,
    ...thirdListPagination,
  };

  const fetchUserType = () => {
    const params = {
      key: 'user_type',
    };
    requestWithAuth('field-lookup', 'GET', params, null)
      .then((response) => {
        if (response && response.result && response.result.length) {
          const userType = response.result.map((user) => ({
            value: user,
            text: user,
          }));
          setUserTypesData(userType);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const fetchOrgData = useCallback(() => {
    setIsLoading(true);
    const newParams = {
      ...params,
      page_size: pageSize,
      page: current,
    };
    if (searchQuery) newParams.q = searchQuery;
    requestWithAuth('vault-user-org', 'GET', newParams, null).then(
      (response) => {
        if (response && response.results) {
          const data = response.results.map((org) => {
            return {
              key: org.org_name,
              ...org,
            };
          });
          setOrgData(data);
          setReportsOrgCount(response.count);
          setListPagination((prevState) => ({
            ...prevState,
            total: response.count,
          }));
          setIsLoading(false);
        }
      },
    );
  }, [pageSize, current, searchQuery]);

  const fetchOrgUserReports = useCallback(
    (query: string) => {
      setIsLoading(true);
      const newParams: Record<string, string | number> = {
        ...paramsSecondary,
        page_size: secondListPagination.pageSize,
        page: secondListPagination.current,
        org_name: query,
      };
      setOrgUserData([]);
      setUserReportsData([]);
      if (searchQuery) newParams.q = searchQuery;
      requestWithAuth('vault-report', 'GET', newParams, null).then(
        (response) => {
          if (response && response.results) {
            const data = response.results.map((user) => {
              return {
                key: user.id,
                ...user,
              };
            });
            setOrgUserData(data);
            setSecondListPagination((prevState) => ({
              ...prevState,
              total: response.count,
            }));
            setIsLoading(false);
          }
        },
      );
    },
    [secondListPagination.pageSize, secondListPagination.current, searchQuery],
  );

  const fetchUserCreditLogs = useCallback(
    (userId: string) => {
      setIsLoading(true);
      const newParams = {
        // ...params,
        page_size: thirdListPagination.pageSize,
        page: thirdListPagination.current,
        user_id: userId,
      };
      requestWithAuth('vault-credits-log', 'GET', newParams, null).then(
        (response) => {
          if (response && response.results) {
            setUserReportsData(response.results);
            setThirdListPagination((prevState) => ({
              ...prevState,
              total: response.count,
            }));
            setIsLoading(false);
          }
        },
      );
    },
    [thirdListPagination.pageSize, thirdListPagination.current],
  );

  useEffect(() => {
    fetchOrgUserReports(primaryTableExpandedRowKey);
  }, [fetchOrgUserReports, filtersStateSecondary, sorterStateSecondary]);

  useEffect(() => {
    fetchOrgData();
  }, [fetchOrgData, sorterStatePrimary]);

  useEffect(() => {
    fetchUserCreditLogs(secondaryTableExpandedRowKey);
  }, [fetchUserCreditLogs]);

  useEffect(() => {
    fetchUserType();
    return () => {
      params = {};
      paramsSecondary = { ordering: 'first_name' };
      setSorterStatePrimary([]);
      setSearchQuery('');
    };
  }, []);

  const handleSearchQuery = (searchValue: string) => {
    setSearchQuery(searchValue || '');
    setListPagination((prevState) => ({
      ...prevState,
      current: 1,
    }));
  };

  const getColumnSearchPropsSecondary = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex.replace(/_/g, ' ')}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleColumnSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleColumnSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() =>
            handleColumnSearchReset(clearFilters, confirm, dataIndex)
          }
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filteredValue: filtersStateSecondary[dataIndex] || [],
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{ color: filtered ? 'var(--color-primary)' : undefined }}
      />
    ),
  });

  const handleColumnSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    const searchText = selectedKeys[0];
    if (
      [
        'account_rep_email',
        'email',
        'first_name',
        'last_name',
        'user_ref_code',
      ].includes(dataIndex)
    ) {
      paramsSecondary[dataIndex] = searchText;
    }
  };

  const handleColumnSearchReset = (clearFilters, confirm, dataIndex) => {
    clearFilters();
    setFiltersStateSecondary((prevState) => {
      const newState = { ...prevState };
      delete newState[dataIndex];
      return newState;
    });
    if (
      [
        'account_rep_email',
        'email',
        'first_name',
        'last_name',
        'user_ref_code',
      ].includes(dataIndex)
    ) {
      delete paramsSecondary[dataIndex];
    }
    confirm();
  };

  const handleTableChange = (pagination, filtersArg, sorter) => {
    sorter = Array.isArray(sorter) ? sorter : [sorter];
    setSorterStatePrimary(sorter);
    // Remove all items from sorter that don't have an order
    sorter = sorter.filter((item) => item.order);
    if (sorter.length > 0) {
      params.ordering = sorter
        .map((info) =>
          info.order === 'descend' ? `-${info.columnKey}` : info.columnKey,
        )
        .join(',');
    } else {
      delete params.ordering;
    }

    setListPagination({
      ...pagination,
      pageSize: pagination.pageSize,
      current: pagination.current,
    });
  };

  const handleSecondaryTableChange = (pagination, filtersArg, sorter) => {
    setFiltersStateSecondary(filtersArg);
    Object.keys(filtersArg).reduce((obj: ParamsType, key) => {
      const filterObj: ParamsType = { ...obj };
      let value = '';
      if (filtersArg[key] !== null) value = getValue(filtersArg[key]);

      if (key === 'is_staff') {
        if (!value || value === 'all') {
          delete paramsSecondary.is_staff;
        } else {
          paramsSecondary.is_staff = value === 'true' ? 1 : 0;
        }
      } else if (key === 'user_type') {
        if (!value) {
          delete paramsSecondary.user_type;
        } else {
          filterObj.user_type = value;
          paramsSecondary.user_type = value;
        }
      } else if (key === 'user_ref_code_expiry_date') {
        // if length is greater than 1, it means both true and false are selected
        if (!value || filtersArg[key]?.length > 1) {
          delete paramsSecondary.user_ref_code_expiry_date__isnull;
        } else {
          paramsSecondary.user_ref_code_expiry_date__isnull = value;
        }
      } else if (key === 'total_matched_record_count') {
        // if length is greater than 1, it means both true and false are selected
        if (!value || filtersArg[key]?.length > 1) {
          delete paramsSecondary.total_matched_record_count__isnull;
        } else {
          paramsSecondary.total_matched_record_count__isnull = value;
        }
      } else if (key === 'is_corporate') {
        if (!value) {
          delete paramsSecondary.is_corporate;
        } else {
          paramsSecondary.is_corporate = value;
        }
      } else if (key === 'b2b_id') {
        if (value !== 'true' && value !== 'false') {
          delete paramsSecondary.b2b_id__isblank;
        } else {
          paramsSecondary.b2b_id__isblank = value;
        }
      }

      return null;
    }, {});
    sorter = Array.isArray(sorter) ? sorter : [sorter];
    setSorterStateSecondary(sorter);
    // Remove all items from sorter that don't have an order
    sorter = sorter.filter((item) => item.order);
    if (sorter.length > 0) {
      paramsSecondary.ordering = sorter
        .map((info) =>
          info.order === 'descend' ? `-${info.columnKey}` : info.columnKey,
        )
        .join(',');
    } else {
      delete paramsSecondary.ordering;
    }
    setSecondListPagination({
      ...pagination,
      pageSize: pagination.pageSize,
      current: pagination.current,
    });
  };

  const handleTertiaryTableChange = (pagination, filtersArg, sorter) => {
    setThirdListPagination({
      ...pagination,
      pageSize: pagination.pageSize,
      current: pagination.current,
    });
  };

  const handleFormReset = () => {
    params = {};
    setSorterStatePrimary([]);

    setListPagination({
      current: 1,
      pageSize: 50,
      total: 0,
    });

    // searchQuery = '';
    setSearchQuery('');
    //fetchUserCreditLogs();
  };

  const primaryColumns: object[] = [
    {
      title: 'Organization',
      key: 'org_name',
      dataIndex: 'org_name',
      width: 400,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      sortOrder: sorterStatePrimary.find(
        (info) => info.columnKey === 'org_name',
      )?.order,
    },
    {
      title: '',
      key: 'dummy',
      dataIndex: 'dummy',
    },
  ];

  const secondaryColumns: object[] = [
    {
      title: 'First Name',
      dataIndex: 'first_name',
      key: 'first_name',
      width: 160,
      ...getColumnSearchPropsSecondary('first_name'),
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      sortOrder: sorterStateSecondary.find(
        (info) => info.columnKey === 'first_name',
      )?.order,
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      key: 'last_name',
      width: 160,
      ...getColumnSearchPropsSecondary('last_name'),
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      sortOrder: sorterStateSecondary.find(
        (info) => info.columnKey === 'last_name',
      )?.order,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      minWidth: 130,
      ...getColumnSearchPropsSecondary('email'),
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      sortOrder: sorterStateSecondary.find((info) => info.columnKey === 'email')
        ?.order,
    },
    {
      title: 'B2B ID',
      dataIndex: 'b2b_id',
      key: 'b2b_id',
      width: 120,
      sorter: false,
      filters: [
        { text: 'N/A', value: true },
        { text: 'Not N/A', value: false },
      ],
      filterMultiple: false,
      filteredValue: filtersStateSecondary.b2b_id || [],
      render: (val) => {
        if (!val) return null;
        const displayValue = val.length > 15 ? val.slice(0, 12) + '...' : val;
        return (
          <Tooltip title={val}>
            <div
              style={{
                width: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {displayValue}
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: 'B2C ID',
      width: 100,
      dataIndex: 'b2c_id',
      key: 'b2c_id',
      sorter: false,
      render: (_val, rec) => {
        return (
          <div style={{ width: '100%', hyphens: 'none' }}>{rec.b2c_id}</div>
        );
      },
    },
    {
      title: 'Account Rep',
      dataIndex: 'account_rep_email',
      key: 'account_rep_email',
      width: 200,
      // ...getColumnSearchPropsSecondary('account_rep_email'),
      sorter: { multiple: 4 },
      sortDirections: ['ascend', 'descend'],
      sortOrder: sorterStateSecondary.find(
        (info) => info.columnKey === 'account_rep_email',
      )?.order,
      render: (val) => {
        if (!val) return null;
        const displayValue = val.length > 30 ? val.slice(0, 27) + '...' : val;
        return (
          <Tooltip title={val}>
            <div
              style={{
                width: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {displayValue}
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: 'User Type',
      dataIndex: 'user_type',
      key: 'user_type',
      width: 120,
      sorter: { multiple: 5 },
      sortDirections: ['ascend', 'descend'],
      sortOrder: sorterStateSecondary.find(
        (info) => info.columnKey === 'user_type',
      )?.order,
      filters: userTypesData,
      filteredValue: filtersStateSecondary.user_type || [],
      render: (val) => {
        return (
          <div
            style={{ width: '100%', wordBreak: 'break-word', display: 'block' }}
          >
            {val}
          </div>
        );
      },
    },
    {
      title: 'Created On',
      width: 100,
      dataIndex: 'create_time',
      key: 'create_time',
      sorter: { multiple: 6 },
      sortDirections: ['ascend', 'descend'],
      sortOrder: sorterStateSecondary.find(
        (info) => info.columnKey === 'create_time',
      )?.order,
      render: (val) => {
        return (
          <div
            style={{ width: '100%', wordBreak: 'break-word', display: 'block' }}
          >
            {val ? moment(val).format('MM/DD/YY') : ''}
          </div>
        );
      },
    },
    {
      title: 'Invoiced',
      dataIndex: 'is_corporate',
      key: 'is_corporate',
      width: 80,
      sorter: { multiple: 8 },
      sortDirections: ['ascend', 'descend'],
      sortOrder: sorterStateSecondary.find(
        (info) => info.columnKey === 'is_corporate',
      )?.order,
      filters: [
        { text: 'Yes', value: 'true' },
        { text: 'No', value: 'false' },
      ],
      filterMultiple: false,
      filteredValue: filtersStateSecondary.is_corporate || [],
      render: (val) => (
        <Tag color={val ? 'green' : 'red'}>{val ? 'Yes' : 'No'}</Tag>
      ),
    },
    {
      title: 'Last Login',
      dataIndex: 'last_login',
      key: 'last_login',
      width: 160,
      sorter: { multiple: 7 },
      sortDirections: ['ascend', 'descend'],
      sortOrder: sorterStateSecondary.find(
        (info) => info.columnKey === 'last_login',
      )?.order,
      render: (val) => {
        return (
          <div
            style={{ width: '100%', wordBreak: 'break-word', display: 'block' }}
          >
            {val ? moment(val).format('MM/DD/YY HH:mm:ss') : 'Never'}
          </div>
        );
      },
    },
    {
      title: 'Last Mobile Login',
      dataIndex: 'latest_mobile_usage',
      key: 'latest_mobile_usage',
      minWidth: 140,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      sortOrder: sorterStateSecondary.find(
        (info) => info.columnKey === 'latest_mobile_usage',
      )?.order,
      render: (val) => {
        return (
          <div
            style={{ width: '100%', wordBreak: 'break-word', display: 'block' }}
          >
            {val ? moment(val).format('MM/DD/YY') : ''}
          </div>
        );
      },
    },
    {
      title: 'Last Webapp Login',
      dataIndex: 'latest_web_usage',
      key: 'latest_web_usage',
      minWidth: 130,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      sortOrder: sorterStateSecondary.find(
        (info) => info.columnKey === 'latest_web_usage',
      )?.order,
      render: (val) => {
        return (
          <div
            style={{ width: '100%', wordBreak: 'break-word', display: 'block' }}
          >
            {val ? moment(val).format('MM/DD/YY') : ''}
          </div>
        );
      },
    },
    {
      title: 'Last Sidecar Login',
      dataIndex: 'last_sidecar_usage',
      key: 'last_sidecar_usage',
      minWidth: 130,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      sortOrder: sorterStateSecondary.find(
        (info) => info.columnKey === 'last_sidecar_usage',
      )?.order,
      render: (val) => {
        return (
          <div
            style={{ width: '100%', wordBreak: 'break-word', display: 'block' }}
          >
            {val ? moment(val).format('MM/DD/YY') : ''}
          </div>
        );
      },
    },
    {
      title: 'Ref Code',
      dataIndex: 'user_ref_code',
      key: 'user_ref_code',
      width: 200,
      ...getColumnSearchPropsSecondary('user_ref_code'),
      sorter: { multiple: 9 },
      sortDirections: ['ascend', 'descend'],
      sortOrder: sorterStateSecondary.find(
        (info) => info.columnKey === 'user_ref_code',
      )?.order,
      render: (val) => {
        return (
          <Link to={`/referrercode?referrer_code=${val}`}>
            <div className="column-style">{val}</div>
          </Link>
        );
      },
    },
    {
      title: 'Referral Code Created',
      dataIndex: 'user_ref_code_created',
      key: 'user_ref_code_created',
      minWidth: 130,
      render: (val) => {
        return (
          <div
            style={{ width: '100%', wordBreak: 'break-word', display: 'block' }}
          >
            {val ? moment(val).format('MM/DD/YY') : ''}
          </div>
        );
      },
    },
    {
      title: 'Referral Code Is Corporate',
      dataIndex: 'user_ref_code_is_corporate',
      key: 'user_ref_code_is_corporate',
      minWidth: 130,
    },
    {
      title: 'Ref Code Expiry',
      dataIndex: 'user_ref_code_expiry_date',
      key: 'user_ref_code_expiry_date',
      width: 120,
      sorter: { multiple: 10 },
      sortDirections: ['ascend', 'descend'],
      sortOrder: sorterStateSecondary.find(
        (info) => info.columnKey === 'user_ref_code_expiry_date',
      )?.order,
      filters: [
        { text: 'N/A', value: 'true' },
        { text: 'Not N/A', value: 'false' },
      ],
      filterMultiple: false,
      filteredValue: filtersStateSecondary.user_ref_code_expiry_date || [],
      render: (val) => {
        return (
          <div className="column-style">
            {val ? moment(val).format('MM/DD/YY') : 'N/A'}
          </div>
        );
      },
    },
    {
      title: 'Credits',
      dataIndex: 'credits',
      key: 'credits',
      width: 80,
      sorter: { multiple: 11 },
      sortDirections: ['ascend', 'descend'],
      sortOrder: sorterStateSecondary.find(
        (info) => info.columnKey === 'credits',
      )?.order,
      render: (val) => {
        return <div className="column-style">{val !== null ? val : 'N/A'}</div>;
      },
    },
    {
      title: 'Contacts Matched',
      dataIndex: 'total_matched_record_count',
      key: 'total_matched_record_count',
      width: 120,
      sorter: { multiple: 12 },
      sortDirections: ['ascend', 'descend'],
      sortOrder: sorterStateSecondary.find(
        (info) => info.columnKey === 'total_matched_record_count',
      )?.order,
      filters: [
        { text: 'N/A', value: 'true' },
        { text: 'Not N/A', value: 'false' },
      ],
      filterMultiple: false,
      filteredValue: filtersStateSecondary.total_matched_record_count,
      render: (val) => {
        return (
          <div className="column-style">
            {val !== null ? val.toLocaleString('en-US') : 'N/A'}
          </div>
        );
      },
    },
    {
      title: 'Contacts Imported',
      dataIndex: 'total_imported_record_count',
      key: 'total_imported_record_count',
      minWidth: 130,
    },
    // {
    //   title: 'Teams Count',
    //   dataIndex: 'teams_count',
    //   key: 'teams_count',
    //   width: 100,
    //   // sorter: { multiple: 13 },
    //   // sortDirections: ['descend', 'ascend'],
    //   // sortOrder: sorterStateSecondary.find((info) => info.columnKey === 'teams_count')
    //   //   ?.order,
    //   render: (val, rec) => {
    //     return (
    //       <Link to={`/team-editor?team_member_user_id=${rec.id}`}>
    //         <div className="column-style">{val !== null ? val : '0'}</div>
    //       </Link>
    //     );
    //   },
    // },
    {
      title: 'Last Import',
      dataIndex: 'latest_import_created',
      key: 'latest_import_created',
      width: 125,
      render: (val) => {
        return (
          <div
            style={{ width: '100%', wordBreak: 'break-word', display: 'block' }}
          >
            {val ? moment(val).format('MM/DD/YY') : ''}
          </div>
        );
      },
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      minWidth: 130,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      sortOrder: sorterStateSecondary.find((info) => info.columnKey === 'title')
        ?.order,
    },
    {
      title: 'Theme',
      dataIndex: 'theme',
      key: 'theme',
      width: 130,
    },
    {
      title: 'Industry',
      dataIndex: 'industry',
      key: 'industry',
      width: 150,
    },

    {
      title: 'Comments',
      dataIndex: 'user_ref_code_comment',
      key: 'user_ref_code_comment',
      minWidth: 130,
    },
    {
      title: 'User Milestones Completed',
      // dataIndex: 'user_ref_code_comment',
      key: 'milestones_completed',
      width: 180,
      render: (val, rec) => {
        let count = 0;
        if (rec.first_saved_search) count++;
        if (rec.import_contacts) count++;
        if (rec.daily_emails) count++;
        if (rec.warm_intros_movie) count++;
        if (rec.feeder_list_movie) count++;
        if (rec.referral_credits) count++;
        return (
          <div
            style={{ width: '100%', wordBreak: 'break-word', display: 'block' }}
          >
            {count} {' of 6'}
          </div>
        );
      },
    },
    {
      title: 'Pending Milestones',
      // dataIndex: 'user_ref_code_comment',
      key: 'milestones_pending',
      width: 250,
      render: (val, rec) => {
        return (
          <div
            style={{ width: '100%', wordBreak: 'break-word', display: 'block' }}
          >
            {!rec.first_saved_search && (
              <>
                Created First Saved Search <br />
              </>
            )}
            {!rec.import_contacts && (
              <>
                Imported Contacts <br />
              </>
            )}
            {!rec.daily_emails && (
              <>
                Created Email Triggers <br />
              </>
            )}
            {!rec.warm_intros_movie && (
              <>
                Watched Getting Started Video <br />
              </>
            )}
            {!rec.feeder_list_movie && (
              <>
                Watched Prospecting Video <br />
              </>
            )}
            {!rec.referral_credits && (
              <>
                Made Referrals to Earn Credits <br />
              </>
            )}
          </div>
        );
      },
    },
  ];

  const tertiaryColumns: object[] = [
    {
      title: 'Date',
      width: 120,
      dataIndex: 'created',
      key: 'created',
      //   sorter: true,
      //   sortDirections: ["ascend", "descend"],
      //   sortOrder: sortInfo.column === "created" && sortInfo.order,
      render: (val) => {
        return (
          <div
            style={{ width: '100%', wordBreak: 'break-word', display: 'block' }}
          >
            {val ? moment(val).format('MM/DD/YY') : ''}
          </div>
        );
      },
    },
    {
      title: 'Name of File',
      dataIndex: 'file_name',
      width: 200,
      render: (val) => {
        return (
          <div
            style={{
              width: '100%',
              wordBreak: 'break-word',
              display: 'block',
            }}
          >
            {val ? val.toString().toTitleCase() : ''}
          </div>
        );
      },
    },
    {
      title: 'No of Profiles',
      dataIndex: 'contacts_count',
      width: 120,
      render: (val) => {
        return <>{splitNumberWithCommas(val)}</>;
      },
    },
    {
      title: 'Credits Used',
      dataIndex: 'credits_charged',
      width: 120,
      render: (val) => {
        return <>{splitNumberWithCommas(val)}</>;
      },
    },
    {
      title: 'Log Type',
      dataIndex: 'log_type',
      width: 90,
    },
    {
      title: 'Subscription Id',
      dataIndex: 'subscription_id',
      width: 150,
    },
  ];

  const renderExportComponent = () => {
    return (
      <ExportCsv
        totalCount={reportsOrgCount}
        searchQuery={searchQuery}
        url="vault-report"
        downloadFileName="Report_Table"
      />
    );
  };

  const secondaryTableRender = (row) => {
    if (primaryTableExpandedRowKey === row.org_name) {
      return (
        <Table
          columns={secondaryColumns}
          dataSource={orgUserData}
          onChange={handleSecondaryTableChange}
          pagination={secondPaginationProps}
          scroll={{ x: 'max-content' }}
          style={{ width: 'calc(100vw - 360px)' }}
          expandable={{
            expandedRowRender: (row) => tertiaryTableRender(row),
            expandedRowKeys: [secondaryTableExpandedRowKey],
            onExpand: (expanded, record) => {
              if (expanded) {
                setSecondaryTableExpandedRowKey(record.id);
                fetchUserCreditLogs(record.id);
              } else {
                setSecondaryTableExpandedRowKey(null);
              }
            },
          }}
        />
      );
    }
  };

  const tertiaryTableRender = (row) => {
    if (secondaryTableExpandedRowKey === row.id) {
      return (
        <Table
          columns={tertiaryColumns}
          dataSource={userReportsData}
          pagination={ThirdPaginationProps}
          scroll={{ x: 'max-content' }}
          style={{ width: 'calc(100vw - 360px)' }}
          onChange={handleTertiaryTableChange}
        />
      );
    }
  };

  return (
    <>
      <div className="reports">
        <TopSection
          placeholder="Search Organization"
          handleSearchQuery={handleSearchQuery}
          handleFormReset={handleFormReset}
          renderExportComponent={renderExportComponent}
          showSearch
          showNewButton={false}
          showExportCsv={reportsOrgCount > 0}
          count={reportsOrgCount}
          searchValue={searchQuery}
        />
        <Spin size="default" spinning={isLoading}>
          <Table
            className="reports-table-nested"
            columns={primaryColumns}
            dataSource={orgData}
            expandable={{
              expandedRowRender: (row) => secondaryTableRender(row),
              expandedRowKeys: [primaryTableExpandedRowKey],
              defaultExpandedRowKeys: [],
              onExpand: (expanded, record) => {
                if (expanded) {
                  setFiltersStateSecondary({});
                  paramsSecondary = {};
                  setSorterStateSecondary([]);
                  setSecondListPagination({
                    current: 1,
                    pageSize: 50,
                    total: 0,
                  });
                  setPrimaryTableExpandedRowKey(record.org_name);
                  fetchOrgUserReports(record.org_name);
                } else {
                  setPrimaryTableExpandedRowKey(null);
                }
              },
            }}
            onChange={handleTableChange}
            pagination={paginationProps}
            // scroll={{ x: 'max-content' }}
            // loading={isLoading}
            // rowKey={(rec) => rec.id}
          />
        </Spin>
      </div>
    </>
  );
};

export default Reports;
