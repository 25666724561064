import { CopyrightOutlined } from '@ant-design/icons';

const links = [
  {
    key: 'support',
    title: 'Support',
    href: 'https://www.aidentified.com/support',
  },
  {
    key: 'privacy',
    title: 'Privacy Policy',
    href: 'https://www.aidentified.com/privacy-policy',
  },
  {
    key: 'terms',
    title: 'Terms & Conditions',
    href: 'https://www.aidentified.com/terms-conditions',
  },
];

const Footer = () => {
  return (
    <footer className="ai-admin-footer">
      {links && (
        <div className="links">
          {links.map((link) => (
            <a
              key={link.key}
              title={link.key}
              href={link.href}
              rel="noreferrer"
            >
              {link.title}
            </a>
          ))}
        </div>
      )}
      <div className="copyright">
        Copyright <CopyrightOutlined /> {new Date().getFullYear()} AIdentified,
        LLC
      </div>
    </footer>
  );
};

export default Footer;
