import { useStytchMemberSession } from '@stytch/react/b2b';
import { Navigate } from 'react-router-dom';
import { Login } from './Login';

export const Authenticate = (): JSX.Element => {
  const { session } = useStytchMemberSession();

  if (session) {
    return <Navigate to="/data-editor/vault" />;
  }

  return <Login />;
};
