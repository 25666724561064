import './layout.scss';

import { useStytchMemberSession } from '@stytch/react/b2b';
import { Layout } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Login } from '../components/Login';
import SideNav from '../components/SideNav';
import Exception from '../pages/Exception';
import AppFooter from './AppFooter';
import AppHeader from './AppHeader';

const nexisAdminAllowedPaths = [
  '/user-editor/users',
  '/user-editor/credits-log',
  '/referrercode',
  '/team-editor',
  '/import-export/contact-exports',
  '/import-export/contact-imports',
  '/reports',
  '/account/settings/base',
  '/',
];

interface AppLayoutProps {
  userName: string;
  userType: string;
  isNexisAdmin: boolean;
}
const AppLayout = ({ userName, userType, isNexisAdmin }: AppLayoutProps) => {
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();
  const { session } = useStytchMemberSession();

  const handleToggle = () => {
    setCollapsed(!collapsed);
  };

  const renderContent = () => {
    if (userType && userType !== 'admin' && !isNexisAdmin) {
      return (
        <Content className="usertype-warning">
          <div>Sorry, You don&apos;t have admin access!</div>
        </Content>
      );
    }

    if (isNexisAdmin && !nexisAdminAllowedPaths.includes(location.pathname)) {
      return <Exception />;
    }

    return (
      <Layout
        className="site-layout-background"
        style={{
          margin: '88px 16px 24px 16px',
          padding: 24,
          minHeight: 280,
          backgroundColor: 'var(--color-white)',
        }}
      >
        <Outlet />
      </Layout>
    );
  };

  if (!session) return <Login />;

  return (
    <Layout>
      <SideNav collapsed={collapsed} isNexisAdmin={isNexisAdmin} />
      <Layout className="site-layout">
        <div
          style={{
            position: 'fixed',
            zIndex: 5,
            width: collapsed ? 'calc(100% - 80px)' : 'calc(100% - 256px)',
          }}
        >
          <AppHeader
            collapsed={collapsed}
            toggle={handleToggle}
            userName={userName}
          />
        </div>
        {renderContent()}

        <AppFooter />
      </Layout>
    </Layout>
  );
};

export default AppLayout;
