import '../index.scss';

import { useCallback, useEffect, useState } from 'react';
import { Spin, message, Popconfirm, Button } from 'antd';
import moment from 'moment';

import { requestWithAuth } from '../../services/api';
import { splitNumberWithCommas } from '../../utils/general';
import CommonTable from '../../components/CommonTable';
import TopSection from '../../components/TopSection';
import { getValue } from '../../utils/general';
import {
  importExportStatusList,
  importExportSourceList,
} from '../../utils/constants';

interface RequestParams {
  ordering?: string;
  source?: string;
  status?: string;
  q?: string;
  page_size?: number;
  page?: number;
}

let params: RequestParams = {};

interface DeleteRequestBody {
  contact_import_ids?: number[];
}

function ContactImport(props) {
  const [listPagination, setListPagination] = useState({
    total: 0,
    pageSize: 50,
    current: 1,
  });
  const { pageSize, current } = listPagination;
  const [isLoading, setIsLoading] = useState(false);
  const [sortInfo, setSortInfo] = useState({
    column: '',
    order: '',
  });
  const { column, order } = sortInfo;
  const [importData, setImportData] = useState([]);
  const [importDataCount, setImportDataCount] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [filters, setFilters] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  const fetchImportData = useCallback(() => {
    setIsLoading(true);
    const newParams = {
      ...params,
      page_size: pageSize,
      page: current,
    };
    if (searchQuery) newParams.q = searchQuery;

    requestWithAuth('vault-contact-import', 'GET', newParams, null).then(
      (response) => {
        if (response && response.results) {
          setImportData(response.results);
          setImportDataCount(response.count);
          setListPagination((prevState) => ({
            ...prevState,
            total: response.count,
          }));
          setIsLoading(false);
        }
      },
    );
  }, [pageSize, current, searchQuery]);

  useEffect(() => {
    fetchImportData();
  }, [fetchImportData, filters, column, order]);
  useEffect(() => {
    return () => {
      params = {};
    };
  }, []);

  const handleTableChange = (pagination, filtersArg, sorter) => {
    setSelectedRowKeys([]);
    Object.keys(filtersArg).reduce((obj, key) => {
      //const filt = { ...obj };
      let value = '';
      if (filtersArg[key] !== null) value = getValue(filtersArg[key]);
      if (key === 'source' || key === 'status') {
        if (!value) {
          delete params[key];
          if (filters && filters[key]) {
            delete filters[key];
            setFilters({ ...filters });
          }
        } else {
          //filt[key] = value.split('\n').join(',');
          setFilters({ ...filters, [key]: value.split(',') });
          params[key] = value;
        }
      }
      return null;
    }, {});

    if (sorter && sorter.order && sorter.columnKey) {
      params.ordering =
        sorter.order === 'descend' ? '-' + sorter.columnKey : sorter.columnKey;
      setSortInfo({
        column: sorter.columnKey,
        order: sorter.order,
      });
    } else {
      setSortInfo({
        column: '',
        order: '',
      });
      delete params.ordering;
    }
    // if (
    //   pagination.pageSize === listPagination.pageSize &&
    //   pagination.current === listPagination.current
    // ) {
    //   fetchImportData();
    // }
    setListPagination({
      ...pagination,
      pageSize: pagination.pageSize,
      current: pagination.current,
    });
  };

  const handleSearchQuery = (searchValue) => {
    setSearchQuery(searchValue || '');
    setListPagination((prevState) => ({
      ...prevState,
      current: 1,
    }));
  };

  const handleFormReset = () => {
    params = {};
    setSortInfo({
      column: '',
      order: '',
    });
    setListPagination({
      current: 1,
      pageSize: 50,
      total: 0,
    });
    setSearchQuery('');
    setFilters(null);
    //fetchImportData();
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 80,
      className: 'word-break',
    },
    {
      title: 'Owner',
      dataIndex: 'email',
      key: 'email',
      width: 200,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortInfo.column === 'email' && sortInfo.order,
      className: 'word-break',
    },
    {
      title: 'Owner Name',
      dataIndex: 'first_name',
      key: 'first_name',
      width: 200,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortInfo.column === 'first_name' && sortInfo.order,
      className: 'word-break',
      render: (val, rec) => {
        return `${rec.first_name} ${rec.last_name || ''}`;
      },
    },

    {
      title: 'Upload Path',
      width: 250,
      dataIndex: 'upload_path',
      key: 'upload_path',
      className: 'word-break',
      hidden: props.isNexisAdmin,
    },
    {
      title: 'Source',
      width: 100,
      dataIndex: 'source',
      key: 'source',
      filters: importExportSourceList,
      filteredValue: filters && filters.source ? filters.source : [],
      filterMultiple: true,
    },
    {
      title: 'Import Name',
      dataIndex: 'import_name',
      key: 'import_name',
      width: 160,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortInfo.column === 'import_name' && sortInfo.order,
      className: 'word-break',
    },
    {
      title: 'Input Recorded Count',
      width: 120,
      dataIndex: 'input_record_count',
      key: 'input_record_count',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortInfo.column === 'input_record_count' && sortInfo.order,
      render: (val) => {
        return val ? splitNumberWithCommas(val) : '-';
      },
    },
    {
      title: 'Imported Recorded Count',
      width: 120,
      dataIndex: 'imported_record_count',
      key: 'imported_record_count',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortInfo.column === 'imported_record_count' && sortInfo.order,
      render: (val) => {
        return val ? splitNumberWithCommas(val) : '-';
      },
    },
    {
      title: 'Matched Recorded Count',
      width: 120,
      dataIndex: 'matched_record_count',
      key: 'matched_record_count',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortInfo.column === 'matched_record_count' && sortInfo.order,
      render: (val) => {
        return val ? splitNumberWithCommas(val) : '-';
      },
    },
    {
      title: 'Data Enrichment Only',
      dataIndex: 'data_enrichment_only',
      key: 'data_enrichment_only',
      width: 40,
      render: (val) => {
        return val === true ? 'Yes' : 'No';
      },
    },
    {
      title: 'Status',
      width: 100,
      dataIndex: 'status',
      key: 'status',
      className: 'capitalize',
      filters: importExportStatusList,
      filteredValue: filters && filters.status ? filters.status : [],
      filterMultiple: true,
    },
    {
      title: 'Progress',
      width: 200,
      dataIndex: 'spark_in_progress',
      key: 'spark_in_progress',
      className: 'word-break',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortInfo.column === 'spark_in_progress' && sortInfo.order,
      hidden: props.isNexisAdmin,
      render: (val) => {
        return val || val !== false ? val : '-';
      },
    },
    {
      title: 'Content Type',
      dataIndex: 'content_type',
      key: 'content_type',
      width: 160,
      hidden: props.isNexisAdmin,
    },
    {
      title: 'Original Filename',
      dataIndex: 'original_filename',
      key: 'original_filename',
      width: 180,
      hidden: props.isNexisAdmin,
    },
    {
      title: 'Filesize',
      dataIndex: 'file_size',
      key: 'file_size',
      width: 160,
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortInfo.column === 'file_size' && sortInfo.order,
    },
    {
      title: 'Created',
      width: 120,
      dataIndex: 'created',
      key: 'created',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortInfo.column === 'created' && sortInfo.order,
      render: (val) => {
        return (
          <div
            style={{ width: '100%', wordBreak: 'break-word', display: 'block' }}
          >
            {val ? moment(val).format('MM/DD/YY') : ''}
          </div>
        );
      },
    },
    {
      title: 'Updated',
      width: 120,
      dataIndex: 'updated',
      key: 'updated',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortInfo.column === 'updated' && sortInfo.order,
      render: (val) => {
        return (
          <div
            style={{ width: '100%', wordBreak: 'break-word', display: 'block' }}
          >
            {val ? moment(val).format('MM/DD/YY') : ''}
          </div>
        );
      },
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 120,
      render: (text, record) => (
        <>
          <Popconfirm
            title="Delete?"
            onConfirm={(e) => handleDelete(e, record.id)}
          >
            <Button className="row-actions" onClick={(e) => e.preventDefault()}>
              Delete
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys([...selectedRowKeys]);
    },
  };

  const handleDelete = (e, recordId) => {
    const body: DeleteRequestBody = {
      contact_import_ids: recordId ? [recordId] : selectedRowKeys,
    };

    setIsLoading(true);
    requestWithAuth(
      'vault-contact-import/delete-multiple',
      'POST',
      null,
      body,
    ).then((response) => {
      if (response && response.code === 200)
        message.success('Contact Import deleted');
      setSelectedRowKeys([]);
      setIsLoading(false);
      fetchImportData();
    });
  };

  return (
    <>
      <div className="people-editor">
        <TopSection
          count={importDataCount}
          placeholder="Search Owner, Import Name "
          showNewButton={false}
          showSearch
          handleSearchQuery={handleSearchQuery}
          handleFormReset={handleFormReset}
          selectedRowKeys={selectedRowKeys}
          handleDeleteMultiple={handleDelete}
          searchValue={searchQuery}
        />
        <Spin size="default" spinning={isLoading}>
          <CommonTable
            columns={columns.filter((column) => !column.hidden)}
            data={importData}
            count={importDataCount}
            onTableChange={handleTableChange}
            pagination={{ ...listPagination }}
            pageSize={listPagination.pageSize}
            loading={isLoading}
            rowKey={(rec) => rec.id}
            rowSelection={rowSelection}
            selectedRowKeys={selectedRowKeys}
          />
        </Spin>
      </div>
    </>
  );
}

export default ContactImport;
