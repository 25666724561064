import '../index.scss';

import { useCallback, useEffect, useState } from 'react';
import { Spin } from 'antd';
import moment from 'moment';

import { requestWithAuth } from '../../services/api';
import { splitNumberWithCommas } from '../../utils/general';
import CommonTable from '../../components/CommonTable';
import TopSection from '../../components/TopSection';
import ExportCsv from '../../components/ExportCsv';

interface RequestParams {
  page_size?: number;
  page?: number;
  q?: string;
}

let params = {};

function CreditsLog() {
  const [listPagination, setListPagination] = useState({
    total: 0,
    pageSize: 50,
    current: 1,
  });
  const { pageSize, current } = listPagination;
  const [isLoading, setIsLoading] = useState(false);
  //   const [sortInfo, setSortInfo] = useState({
  //     column: "",
  //     order: "",
  //   });
  const [searchQuery, setSearchQuery] = useState('');
  const [creditsData, setCreditsData] = useState([]);
  const [creditsDataCount, setCreditsDataCount] = useState(0);

  const fetchCreditsData = useCallback(() => {
    setIsLoading(true);
    const newParams: RequestParams = {
      ...params,
      page_size: pageSize,
      page: current,
    };
    if (searchQuery) newParams.q = searchQuery;

    requestWithAuth('vault-credits-log', 'GET', newParams, null).then(
      (response) => {
        if (response && response.results) {
          setCreditsData(response.results);
          setCreditsDataCount(response.count);
          setListPagination((prevState) => ({
            ...prevState,
            total: response.count,
          }));
          setIsLoading(false);
        }
      },
    );
  }, [pageSize, current, searchQuery]);

  useEffect(() => {
    fetchCreditsData();

    // return () => {
    //   params = {};
    // //   setSortInfo({
    // //     column: "",
    // //     order: "",
    // //   });
    //   searchQuery = "";
    // };
  }, [fetchCreditsData]);

  const handleTableChange = (pagination, filtersArg, sorter) => {
    // if (sorter && sorter.order && sorter.columnKey) {
    //   params.ordering =
    //     sorter.order === "descend" ? "-" + sorter.columnKey : sorter.columnKey;
    //   setSortInfo({
    //     column: sorter.columnKey,
    //     order: sorter.order,
    //   });
    // } else {
    //   setSortInfo({
    //     column: "",
    //     order: "",
    //   });
    //   delete params.ordering;
    // }
    // if (
    //   pagination.pageSize === listPagination.pageSize &&
    //   pagination.current === listPagination.current
    // ) {
    //   fetchCreditsData();
    // }
    setListPagination({
      ...pagination,
      pageSize: pagination.pageSize,
      current: pagination.current,
    });
  };

  const handleSearchQuery = (searchValue) => {
    setSearchQuery(searchValue || '');
    setListPagination((prevState) => ({
      ...prevState,
      current: 1,
    }));
  };

  const handleFormReset = () => {
    params = {};
    // setSortInfo({
    //   column: "",
    //   order: "",
    // });
    setListPagination({
      current: 1,
      pageSize: 50,
      total: 0,
    });
    setSearchQuery('');
    //fetchUsersData();
  };

  const columns = [
    {
      title: 'Date',
      width: 120,
      dataIndex: 'created',
      key: 'created',
      //   sorter: true,
      //   sortDirections: ["ascend", "descend"],
      //   sortOrder: sortInfo.column === "created" && sortInfo.order,
      render: (val) => {
        return (
          <div
            style={{ width: '100%', wordBreak: 'break-word', display: 'block' }}
          >
            {val ? moment(val).format('MM/DD/YY') : ''}
          </div>
        );
      },
    },
    {
      title: 'Owner Email',
      dataIndex: 'email',
      key: 'email',
      width: 120,
    },
    // {
    //     title: 'Owner Name',
    //     dataIndex: 'owner_name',
    //     key: 'owner_name',
    //     width: 200,
    //     sorter: true,
    //     sortDirections: ['ascend', 'descend'],
    //     sortOrder: sortInfo.column === 'full_name' && sortInfo.order,
    //     className: 'word-break',
    //   },
    {
      title: 'Name of File',
      dataIndex: 'file_name',
      width: 200,
      render: (val) => {
        return (
          <div
            style={{
              width: '100%',
              wordBreak: 'break-word',
              display: 'block',
            }}
          >
            {val ? val.toString().toTitleCase() : ''}
          </div>
        );
      },
    },
    {
      title: 'No of Profiles',
      dataIndex: 'contacts_count',
      width: 120,
      render: (val) => {
        return <>{splitNumberWithCommas(val)}</>;
      },
    },
    {
      title: 'Credits Used',
      dataIndex: 'credits_charged',
      width: 120,
      render: (val) => {
        return <>{splitNumberWithCommas(val)}</>;
      },
    },
    {
      title: 'Log Type',
      dataIndex: 'log_type',
      width: 90,
    },
    {
      title: 'Subscription Id',
      dataIndex: 'subscription_id',
      width: 150,
    },
    {
      title: 'Creator Email',
      dataIndex: 'creator_email',
      width: 150,
    },
    {
      title: 'Referrer Code',
      dataIndex: 'referrer_code',
      width: 150,
    },
  ];

  const renderExportComponent = () => {
    return (
      <ExportCsv
        totalCount={creditsDataCount}
        searchQuery={searchQuery}
        url="vault-credits-log"
        downloadFileName="Credit_Table"
      />
    );
  };

  return (
    <>
      <div className="people-editor">
        <TopSection
          count={creditsDataCount}
          placeholder="Search owner email or referrer"
          showNewButton={false}
          showSearch
          handleSearchQuery={handleSearchQuery}
          handleFormReset={handleFormReset}
          showExportCsv={creditsDataCount > 0}
          renderExportComponent={renderExportComponent}
          searchValue={searchQuery}
        />
        <Spin size="default" spinning={isLoading}>
          <CommonTable
            columns={columns}
            data={creditsData}
            count={creditsDataCount}
            onTableChange={handleTableChange}
            pagination={{ ...listPagination }}
            pageSize={listPagination.pageSize}
            loading={isLoading}
            rowKey={(rec) => rec.id}
          />
        </Spin>
      </div>
    </>
  );
}

export default CreditsLog;
