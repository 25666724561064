import {
  AuthFlowType,
  B2BOAuthProviders,
  B2BProducts,
  StytchB2BUIConfig,
} from '@stytch/vanilla-js';

const stytchCookieDomain =
  import.meta.env.VITE_STYTCH_COOKIE_DOMAIN || 'localhost';

const baseUrl = window.location.origin;

export const discoveryConfig: StytchB2BUIConfig = {
  products: [
    B2BProducts.oauth,
    B2BProducts.emailMagicLinks,
    B2BProducts.passwords,
    // B2BProducts.emailOtp,
    // B2BProducts.sso,
  ],
  sessionOptions: { sessionDurationMinutes: 60 },
  oauthOptions: {
    providers: [{ type: B2BOAuthProviders.Google }],
    loginRedirectURL: `${baseUrl}/authenticate`,
    signupRedirectURL: `${baseUrl}/authenticate`,
    discoveryRedirectURL: `${baseUrl}/authenticate`,
  },
  emailMagicLinksOptions: {
    loginRedirectURL: `${baseUrl}/authenticate`,
    signupRedirectURL: `${baseUrl}/authenticate`,
    discoveryRedirectURL: `${baseUrl}/authenticate`,
  },
  passwordOptions: {
    loginRedirectURL: `${baseUrl}/authenticate`,
    resetPasswordRedirectURL: `${baseUrl}/authenticate`,
  },
  authFlowType: AuthFlowType.Discovery,
  disableCreateOrganization: true,

  directLoginForSingleMembership: {
    /**
     * Whether or not direct login for single membership is enabled.
     */
    status: true,
    /**
     * If enabled, logs user in directly even if they have pending invite to a different organization
     */
    ignoreInvites: true,
    /**
     * If enabled, logs user in directly even if they have organizations they could join via JIT provisioning
     */
    ignoreJitProvisioning: true,
  },
};

export const adminPortalStyles = {
  fontFamily: 'IBM Plex Sans',
};

export const discoveryStyles = {
  fontFamily: 'IBM Plex Sans',
  container: {
    width: '500px',
  },
};

export const stytchClientOptions = {
  cookieOptions: {
    opaqueTokenCookieName: 'admin_stytch_session',
    jwtCookieName: 'admin_stytch_session_jwt',
    path: '/',
    availableToSubdomains: true,
    domain: stytchCookieDomain,
  },
};
