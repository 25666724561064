import '@gouch/to-title-case';

import iconChime from '../assets/icons/ai_chime.svg';
import iconContactually from '../assets/icons/ai_contactually.svg';
import iconHubSpot from '../assets/icons/ai_hubspot.svg';
import iconPipedrive from '../assets/icons/ai_pipedrive.svg';
import iconRedtail from '../assets/icons/ai_redtail.svg';
import iconSalesforce from '../assets/icons/ai_salesforce.svg';

export const splitNumberWithCommas = (x) => {
  if (!x) return 0;
  const parts = x.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
};

export const getValue = (obj) =>
  Object.keys(obj)
    .map((key) => obj[key])
    .join(',');

export const formatEventName = (val) => {
  if (!val) return '';
  let value = val.toTitleCase();
  // let value = val;
  value = value.replace('ipo', 'IPO');
  value = value.replace('Ipo', 'IPO');
  if (val === 'company was acquired') {
    value = 'Company Acquired';
  } else if (val === 'ipo lockup expiring soon') {
    value = 'IPO Lock-up Expiring Soon';
  } else if (val === 'ipo lockup expired') {
    value = 'IPO Lock-up Expired';
  } else if (val === 'ipo spac') {
    value = 'IPO SPAC';
  } else if (val === 'news') {
    value = 'News Mention';
  }

  return value;
};

export const dateFilterOptions = [
  {
    text: 'Recent',
    value: 'lastday',
  },
  {
    text: 'One Week',
    value: 'week',
  },
  {
    text: 'One Month',
    value: 'month',
  },
  {
    text: 'YTD',
    value: 'year',
  },
];

export const compare = (a, b) => {
  if (a.value < b.value) {
    return -1;
  }
  if (a.value > b.value) {
    return 1;
  }
  return 0;
};

export const getCrmLogo = (crm) => {
  switch (crm) {
    case 'sf':
      return iconSalesforce;
    case 'cm':
      return iconChime;
    case 'ct':
      return iconContactually;
    case 'rt':
      return iconRedtail;
    case 'pd':
      return iconPipedrive;
    case 'hs':
      return iconHubSpot;
    default:
      // return iconCrm;
      return null;
  }
};

export const getCrmLabels = (crm) => {
  switch (crm) {
    case 'sf':
      return 'Salesforce';
    case 'cm':
      return 'Chime';
    case 'ct':
      return 'Contactually';
    case 'rt':
      return 'Redtail';
    case 'pd':
      return 'Pipedrive';
    case 'hs':
      return 'HubSpot';
    default:
      return 'No CRM Integrated';
  }
};
