import axios from 'axios';

export const setAxiosTokenInterceptor = (accessToken) => {
  axios.interceptors.request.use(
    (config) => {
      const requestConfig = config;
      if (accessToken) {
        requestConfig.headers.Authorization = `Bearer ${accessToken}`;
      }
      return requestConfig;
    },
    (error) => {
      console.log('setAxiosTokenInterceptor: ', error);
    },
  );
};
