import { useStytchMemberSession } from '@stytch/react/b2b';
import { Spin } from 'antd';
import { Navigate, Outlet } from 'react-router-dom';

export const ProtectedRoutes = (): JSX.Element => {
  const { session, fromCache } = useStytchMemberSession();

  if (!session && !fromCache) {
    return <Navigate to="/" />;
  }

  if (!session) {
    return <Spin size="large">Loading...</Spin>;
  }

  return <Outlet />;
};
