import { PureComponent } from 'react';
import { Select } from 'antd';
import axios from 'axios';
import { requestWithAuth } from '../../services/api';

interface CompanySuggestProps {
  value?: {
    label: string;
    value: string | number;
  };
  disabled?: boolean;
  onChange?: (value: { label: string; value: string | number }) => void;
}

interface CompanyData {
  ai_org_id: string | number;
  name: string;
}

interface CompanySuggestState {
  data: CompanyData[];
  value?: { label: string; value: string | number };
  loading: boolean;
}

const { Option } = Select;
let source;
class CompanySuggest extends PureComponent<
  CompanySuggestProps,
  CompanySuggestState
> {
  constructor(props: CompanySuggestProps) {
    super(props);
    this.state = {
      data: [],
      value: props.value,
      loading: false,
    };
  }

  handleSearch = (searchValue: string) => {
    if (searchValue) {
      this.setState({ loading: true });
      const params = {
        lookup_type: 'n2',
        search_key: 'ai_org',
        search_value: searchValue,
        filter_type: 'all',
      };

      // if (controller) controller.abort();
      // controller = new AbortController(); // create a controller
      // const { signal } = controller; // grab a reference to its associated AbortSignal object using the AbortController.signal property

      if (source) source.cancel('Operation canceled by the user.');
      const { CancelToken } = axios;
      source = CancelToken.source();

      setTimeout(() => {
        requestWithAuth('auto-suggest-new?', 'GET', params, null, source.token)
          .then((response) => {
            if (response && response.result) {
              this.setState({ data: response.result, loading: false });
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }, 500);
    } else {
      this.setState({ data: [], loading: false });
    }
  };

  handleChange = (value) => {
    const { onChange } = this.props;
    if (onChange) onChange(value);
    this.setState({ value });
  };

  render() {
    const { data, value, loading } = this.state;
    return (
      <Select
        style={{ width: '100%' }}
        showSearch
        labelInValue
        loading={loading}
        value={value}
        placeholder="Search Company"
        defaultActiveFirstOption={false}
        showArrow={false}
        filterOption={false}
        onSearch={this.handleSearch}
        onChange={this.handleChange}
        notFoundContent={null}
        // isMulti
        // mode="multiple"
        allowClear
        disabled={this.props.disabled}
      >
        {data &&
          data.length > 0 &&
          data.map((item) => {
            return <Option key={item.ai_org_id}>{item.name}</Option>;
          })}
      </Select>
    );
  }
}
export default CompanySuggest;
