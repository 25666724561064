import './index.css';
// init Sentry
import './instrument';

import { Auth0Provider } from '@auth0/auth0-react';
import { StytchB2BProvider } from '@stytch/react/b2b';
import { StytchB2BUIClient } from '@stytch/vanilla-js/b2b';
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App.tsx';
import { StytchApp } from './StytchApp.tsx';
import { stytchClientOptions } from './utils/stytchConfig.ts';

const {
  VITE_AUTH0_DOMAIN,
  VITE_AUTH0_CLIENT_ID,
  VITE_AUTH0_REDIRECT_URI,
  VITE_USE_STYTCH_AUTHENTICATION,
} = import.meta.env;

const useStytchAuthentication = JSON.parse(
  VITE_USE_STYTCH_AUTHENTICATION || 'false',
);

const root = ReactDOM.createRoot(document.getElementById('root')!);

if (useStytchAuthentication) {
  // Initialize Stytch
  const stytch = new StytchB2BUIClient(
    import.meta.env.VITE_STYTCH_PUBLIC_TOKEN,
    stytchClientOptions,
  );
  root.render(
    <React.StrictMode>
      <StytchB2BProvider stytch={stytch}>
        <Router>
          <StytchApp />
        </Router>
      </StytchB2BProvider>
    </React.StrictMode>,
  );
} else {
  root.render(
    <Auth0Provider
      domain={VITE_AUTH0_DOMAIN}
      clientId={VITE_AUTH0_CLIENT_ID}
      redirectUri={`${VITE_AUTH0_REDIRECT_URI}/user/login/callback`}
      forgotPasswordLink={`${VITE_AUTH0_REDIRECT_URI}/user/forgot-password`}
      useRefreshTokens={true}
      cacheLocation="localstorage"
    >
      <StrictMode>
        <App />
      </StrictMode>
    </Auth0Provider>,
  );
}
