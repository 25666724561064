import { message } from 'antd';
import axios from 'axios';

const useStytchAuthentication = JSON.parse(
  import.meta.env.VITE_USE_STYTCH_AUTHENTICATION || 'false',
);

message.config({
  top: 80,
});

const { VITE_COREDATA_API_URL } = import.meta.env;

export async function requestWithAuth(
  url,
  method,
  params,
  body,
  cancelToken = null,
) {
  const absoluteUrl = `${VITE_COREDATA_API_URL}${url}`;
  return axios
    .request({
      url: absoluteUrl,
      method,
      params: { ...params },
      data: body,
      cancelToken,
      withCredentials: useStytchAuthentication ? true : false,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      const { response = {} } = error;
      if (response.status === 400 && response.data) {
        return { error: response.data };
      } else if (method !== 'GET') {
        message.error(
          'Something went wrong. Please try again in a few minutes.',
        );
      }
    });
}
