/**
 * Temporary component that mirrors App.tsx functionality while authentication is being migrated from Auth0 to Stytch.
 * This component will eventually replace App.tsx once the migration is complete.
 *
 * StytchApp handles:
 * - Authentication state management
 * - User data fetching and storage
 * - Route protection and navigation
 * - Basic layout structure
 *
 * The component structure and routing logic is identical to App.tsx to ensure a smooth transition
 * between authentication providers. The only differences are:
 * - Uses Stytch hooks and providers instead of Auth0
 * - Different token handling mechanism
 * - Modified user data structure
 *
 * @see src/main.tsx - For provider setup and environment configuration
 * @see src/App.tsx - For the original Auth0 implementation
 */
import './antdStyles.scss';

// import './StytchApp.css';

import '@fontsource/ibm-plex-sans';
import { useStytchMemberSession } from '@stytch/react/b2b';
import { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Authenticate } from './components/Authenticate';
import { Login } from './components/Login';
import { ProtectedRoutes } from './components/ProtectedRoutes';
import AppLayout from './layouts/AppLayout';
import UserLayout from './layouts/UserLayout';
import Account from './pages/Account';
import Comments from './pages/Comments';
import SuggestedVideo from './pages/Dashboard/SuggestedVideo';
import WeeklyTip from './pages/Dashboard/WeeklyTip';
import CompanyEditor from './pages/DataEditor/CompanyEditor';
import CompanyEventsEditor from './pages/DataEditor/CompanyEventsEditor';
import CompanyMerge from './pages/DataEditor/CompanyMerge';
import InsiderEventsEditor from './pages/DataEditor/InsiderEventsEditor';
import PeopleEditor from './pages/DataEditor/PeopleEditor';
import ProfileEdits from './pages/DataEditor/ProfileEdits';
import Exception from './pages/Exception';
import ContactExport from './pages/ImportExport/ContactExport';
import ContactImport from './pages/ImportExport/ContactImport';
import CrmExportTracks from './pages/ImportExport/CrmExportTracks';
import ReferrerCode from './pages/ReferrerCode';
import Reports from './pages/Reports';
import TeamEditor from './pages/TeamEditor';
import ForgotPassword from './pages/User/ForgotPassword';
import ForgotPasswordSent from './pages/User/ForgotPasswordSent';
import LoginCallBack from './pages/User/LoginCallBack';
import CreditsLog from './pages/UserEditor/CreditsLog';
import UserMappingEditor from './pages/UserEditor/UserMappingEditor';
import UsersEditor from './pages/UserEditor/UsersEditor';
import UserSetupResponse from './pages/UserEditor/UserSetupResponse';
import { requestWithAuth } from './services/api';

export const StytchApp = () => {
  const { session } = useStytchMemberSession();
  const [name, setName] = useState('');
  const [userType, setUserType] = useState('');
  const [isNexisAdmin, setIsNexisAdmin] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      if (session) {
        const response = await requestWithAuth('user-type', 'GET', null, null);
        if (response?.results?.[0]) {
          setName(response.results[0].full_name);
          setUserType(response.results[0].user_type);
          if (response.results[0].nexis_admin === true) {
            setIsNexisAdmin(true);
          }
        }
      }
    };

    fetchUserData();
  }, [session]);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <AppLayout
            userName={name}
            userType={userType}
            isNexisAdmin={isNexisAdmin}
          />
        }
      >
        <Route path="/authenticate" element={<Authenticate />} />
        <Route element={<ProtectedRoutes />}>
          <Route path="data-editor">
            <Route path="vault" element={<PeopleEditor />} />
            <Route path="org-events" element={<CompanyEventsEditor />} />
            <Route path="insider-events" element={<InsiderEventsEditor />} />
            <Route path="company" element={<CompanyEditor />} />
            <Route path="company-merge" element={<CompanyMerge />} />
            <Route path="profile-edits" element={<ProfileEdits />} />
            <Route index element={<Navigate to="/data-editor/vault" />} />
          </Route>
          <Route path="user-editor">
            <Route
              path="users"
              element={<UsersEditor isNexisAdmin={isNexisAdmin} />}
            />
            <Route path="users-mapping" element={<UserMappingEditor />} />
            <Route path="credits-log" element={<CreditsLog />} />
            <Route path="user-setup-response" element={<UserSetupResponse />} />
            <Route index element={<Navigate to="/user-editor/users" />} />
          </Route>

          <Route
            path="referrercode"
            element={<ReferrerCode isNexisAdmin={isNexisAdmin} />}
          />
          <Route path="dashboard">
            <Route path="weekly-tip" element={<WeeklyTip />} />
            <Route path="suggested-video" element={<SuggestedVideo />} />
            <Route index element={<Navigate to="/dashboard/weekly-tip" />} />
          </Route>
          <Route path="comments" element={<Comments />} />
          <Route
            path="team-editor"
            element={<TeamEditor isNexisAdmin={isNexisAdmin} />}
          />
          <Route path="import-export">
            <Route
              path="contact-exports"
              element={<ContactExport isNexisAdmin={isNexisAdmin} />}
            />
            <Route
              path="contact-imports"
              element={<ContactImport isNexisAdmin={isNexisAdmin} />}
            />
            <Route path="crm-export-tracks" element={<CrmExportTracks />} />
            <Route
              index
              element={<Navigate to="/import-export/contact-exports" />}
            />
          </Route>
          <Route path="reports" element={<Reports />} />
          <Route path="account">
            <Route path="settings/base" element={<Account name={name} />} />
            <Route
              path="settings"
              element={<Navigate to="/account/settings/base" />}
            />
            <Route index element={<Navigate to="/account/settings/base" />} />
          </Route>

          <Route path="*" element={<Exception />} />
        </Route>
        <Route path="/user/" element={<UserLayout />}>
          <Route path="login" element={<Login />} />
          <Route path="login/callback" element={<LoginCallBack />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="forgot-password-sent" element={<ForgotPasswordSent />} />
          <Route index element={<Navigate to="/user/login" />} />
        </Route>
      </Route>
      <Route index element={<Navigate to="/data-editor/vault" />} />
      <Route path="/exception" element={<Exception />} />
    </Routes>
  );
};
