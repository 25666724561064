import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  SettingOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import { Dropdown } from 'antd';
import type { MenuProps } from 'antd';

import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

const { VITE_AUTH0_REDIRECT_URI } = import.meta.env;

const Header = ({ collapsed, toggle, userName }) => {
  const navigate = useNavigate();
  const { logout } = useAuth0();
  const onMenuClick = ({ key }) => {
    if (key === 'userinfo') {
      navigate('/account/settings/base');
      return;
    }
    if (key === 'logout') {
      logout({
        returnTo: `${VITE_AUTH0_REDIRECT_URI}/user/login`,
      });
    }
  };

  const items: MenuProps['items'] = [
    {
      key: 'userinfo',
      icon: <SettingOutlined />,
      label: 'Account Settings',
    },
    {
      type: 'divider',
    },
    {
      key: 'logout',
      icon: <LogoutOutlined />,
      label: 'Sign out',
    },
  ];

  return (
    <div className="ai-admin-header">
      <div className="ai-fold-icon" onClick={toggle}>
        {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
      </div>
      <Dropdown
        menu={{ items: items, onClick: onMenuClick }}
        placement="bottomLeft"
        trigger={['click', 'hover']}
      >
        <div className="ai-user">
          <UserOutlined /> <span className="ai-username">{userName}</span>
        </div>
      </Dropdown>
    </div>
  );
};

export default Header;
