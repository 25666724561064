import '../index.scss';

import { useCallback, useEffect, useState } from 'react';
import {
  Form,
  Popconfirm,
  Input,
  DatePicker,
  Divider,
  message,
  Spin,
  Modal,
  Upload,
} from 'antd';
import moment from 'moment';

import { requestWithAuth } from '../../services/api';
import CommonTable from '../../components/CommonTable';
import TopSection from '../../components/TopSection';
import { EditableCell, EditableRow } from '../../components/EditableComponents';

interface RequestParams {
  ordering?: string;
  type?: string;
  page_size?: number;
  page?: number;
  q?: string;
}

let params: RequestParams = {};
//let searchQuery = "";

interface FormValues {
  id?: string;
  title?: string;
  description?: string;
  start_date?: string;
  end_date?: string;
  file_name?: string;
  image_url?: string;
}

const FormItems = ({
  formVals,
  form,
  isUpdate,
  fileList,
  setFileListValue,
}) => {
  const [showThumbnail, setShowThumbnail] = useState(false);
  useEffect(() => {
    if (isUpdate) {
      setShowThumbnail(true);
      // setFileListValue([]);
    }
  }, [isUpdate]);

  const onChange = ({ fileList: newFileList }) => {
    setShowThumbnail(false);
    setFileListValue([...newFileList]);
  };

  const onRemove = () => {
    if (isUpdate) setShowThumbnail(true);
    setFileListValue([]);
    return false;
  };

  useEffect(() => {
    form.resetFields();
  }, [formVals, form]);

  return [
    <Form.Item
      key="title"
      name="title"
      label="Title"
      required
      rules={[
        {
          required: true,
          message: 'Enter title',
        },
      ]}
      initialValue={formVals.title}
    >
      <Input placeholder="Title" />
    </Form.Item>,

    <Form.Item
      key="description"
      name="description"
      label="Description"
      required
      rules={[
        {
          required: true,
          message: 'Enter description',
        },
      ]}
      initialValue={formVals.description}
    >
      <Input.TextArea
        autoSize={{ minRows: 3, maxRows: 8 }}
        placeholder="Description"
        autoComplete="_away"
      />
    </Form.Item>,

    <Form.Item
      key="image_url"
      name="image_url"
      label="Image"
      // rules={[
      //     {
      //         required: true,
      //         message: 'Choose image file',
      //     },
      // ]}
      // initialValue={formVals.image_url}
    >
      <div style={{ display: 'flex' }}>
        {showThumbnail && formVals.image_url && (
          <img
            src={formVals.image_url}
            alt="upload"
            className="weekly-tip-image"
          />
        )}
        <Upload
          listType="picture-card"
          fileList={fileList}
          onChange={onChange}
          onRemove={onRemove}
          beforeUpload={() => false}
        >
          {showThumbnail && formVals.image_url
            ? 'Change'
            : (!fileList || fileList.length < 1) && '+ Upload'}
        </Upload>
      </div>
    </Form.Item>,

    <Form.Item
      key="start_date"
      name="start_date"
      label="Start date"
      initialValue={
        formVals.start_date ? moment(formVals.start_date) : undefined
      }
      required
      rules={[
        {
          required: true,
          message: 'Enter start date',
        },
      ]}
    >
      <DatePicker placeholder="start_date" style={{ width: '100%' }} />
    </Form.Item>,

    <Form.Item
      key="end_date"
      name="end_date"
      label="End date"
      initialValue={formVals.end_date ? moment(formVals.end_date) : undefined}
      required
      rules={[
        {
          required: true,
          message: 'Enter end date',
        },
      ]}
    >
      <DatePicker placeholder="End date" style={{ width: '100%' }} />
    </Form.Item>,
  ];
};

const FormModal = ({
  form,
  isUpdate,
  handleUpdate,
  handleCreate,
  isModalLoading,
  onClose,
  recordFormVals,
  fileList,
  setFileListValue,
  error,
}) => {
  const [formVals, setFormVals] = useState<FormValues>({});

  useEffect(() => {
    if (isUpdate) {
      setFormVals({ ...recordFormVals });
    }
  }, [recordFormVals, isUpdate]);

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        const formValues = { ...values };

        if (formValues.end_date) {
          formValues.end_date = moment(formValues.end_date).format(
            'YYYY-MM-DD',
          );
        }
        if (formValues.start_date) {
          formValues.start_date = moment(formValues.start_date).format(
            'YYYY-MM-DD',
          );
        }
        if (isUpdate) {
          handleUpdate(formVals.id, formValues);
        } else {
          handleCreate(formValues);
        }
      })
      .catch((errorInfo) => {
        if (errorInfo) {
          console.log(errorInfo);
          message.error('Please fill the mandatory fileds');
          return;
        }
      });
  };

  return (
    <Modal
      title={isUpdate ? 'Update Weekly Tip' : 'New Weekly Tip'}
      visible
      width={800}
      okText={isUpdate ? 'Update' : 'Create'}
      onOk={handleSubmit}
      confirmLoading={isModalLoading}
      onCancel={onClose}
    >
      <Spin size="default" spinning={isModalLoading}>
        <Form labelCol={{ span: 7 }} wrapperCol={{ span: 13 }} form={form}>
          <FormItems
            formVals={formVals}
            form={form}
            isUpdate={isUpdate}
            fileList={fileList}
            setFileListValue={setFileListValue}
          />
          {error && <div className="error-text">{error}</div>}
        </Form>
      </Spin>
    </Modal>
  );
};

const WeeklyTip = () => {
  const [listPagination, setListPagination] = useState({
    total: 0,
    pageSize: 50,
    current: 1,
  });
  const { current, pageSize } = listPagination;
  const [isLoading, setIsLoading] = useState(false);
  const [sortInfo, setSortInfo] = useState({
    column: '',
    order: '',
  });
  const { column, order } = sortInfo;
  const [searchQuery, setSearchQuery] = useState('');
  const [allClearFilters, setAllClearFilters] = useState({});
  const [weeklyTipData, setWeeklyTipData] = useState([]);
  const [weeklyDataCount, setWeeklyDataCount] = useState(0);
  const [isUpdate, setIsUpdate] = useState(false);
  const [showFormModal, setShowFormModal] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [recordFormVals, setRecordFormVals] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [error, setError] = useState(null);

  const [form] = Form.useForm();

  const fetchWeeklyTipData = useCallback(() => {
    setIsLoading(true);
    const newParams: RequestParams = {
      ...params,
      type: 'tip',
      page_size: pageSize,
      page: current,
    };
    if (searchQuery) newParams.q = searchQuery;
    requestWithAuth('vault-dashboard-suggestions', 'GET', newParams, null).then(
      (response) => {
        if (response && response.results) {
          setWeeklyTipData(response.results);
          setWeeklyDataCount(response.count);
          setListPagination((prevState) => ({
            ...prevState,
            total: response.count,
          }));
          setIsLoading(false);
        }
      },
    );
  }, [pageSize, current, searchQuery]);

  const handleFormReset = () => {
    if (allClearFilters && Object.keys(allClearFilters).length)
      Object.keys(allClearFilters).map((key) => allClearFilters[key]());
    params = {};
    setSortInfo({
      column: '',
      order: '',
    });
    setListPagination({
      current: 1,
      pageSize: 50,
      total: 0,
    });
    setAllClearFilters({});
    setSearchQuery('');
    params = {};
    //fetchWeeklyTipData();
  };

  useEffect(() => {
    fetchWeeklyTipData();

    // return () => {
    //   if (allClearFilters && Object.keys(allClearFilters).length)
    //     Object.keys(allClearFilters).map((key) => allClearFilters[key]());
    //   params = {};
    //   setSortInfo({
    //     column: "",
    //     order: "",
    //   });
    //   setListPagination({
    //     current: 1,
    //     pageSize: 50,
    //   });
    //   setAllClearFilters({});
    //   searchQuery = "";
    //   params = { type: "tip" };
    // };
  }, [fetchWeeklyTipData, allClearFilters, column, order]);

  //   useEffect(() => {
  //     if (total === 0) return;
  //     fetchWeeklyTipData();
  //   }, [fetchWeeklyTipData, total]);
  useEffect(() => {
    return () => {
      params = {};
    };
  }, []);

  const handleCreate = (fields) => {
    if (!fileList || fileList.length <= 0) {
      message.error('Please choose an image');
      return;
    }
    setIsModalLoading(true);
    setError(null);
    const formData = new FormData();
    try {
      if (fileList && fileList.length > 0) {
        formData.append(
          'image_file',
          fileList[0].originFileObj,
          fileList[0].name,
        );
        formData.append('file_name', fileList[0].name);
        formData.append('suggestion_type', 'tip');
        formData.append('title', fields.title);
        formData.append('description', fields.description);
        formData.append('start_date', fields.start_date);
        formData.append('end_date', fields.end_date);
      }
    } catch (e) {
      console.log(e, 'catch');
    }

    requestWithAuth('vault-dashboard-suggestions', 'POST', null, formData)
      .then((response) => {
        if (response && response.error) {
          setError(Object.values(response.error).join(', '));
          setIsModalLoading(false);
          return;
        }
        if (response) {
          fetchWeeklyTipData();
          setShowFormModal(false);
        }
        setIsModalLoading(false);
      })
      .catch(() => {
        setIsModalLoading(false);
        setShowFormModal(false);
      });
  };

  const handleUpdate = (recordId, fields) => {
    /** No need for testing image file on update
     *  As the recordFormVals already have file information and @fileList dowsn't have data unless we changed it
     *  Also, Deleting image on update is not possible
     */

    setIsModalLoading(true);
    setError(null);
    const formData = new FormData();
    if (fileList && fileList.length > 0) {
      formData.append(
        'image_file',
        fileList[0].originFileObj,
        fileList[0].name,
      );
      formData.append('file_name', fileList[0].name);
    } else {
      formData.append('file_name', recordFormVals.file_name);
    }

    formData.append('suggestion_type', 'tip');
    formData.append('title', fields.title);
    formData.append('description', fields.description);
    formData.append('start_date', fields.start_date);
    formData.append('end_date', fields.end_date);
    requestWithAuth(
      `vault-dashboard-suggestions/${recordId}`,
      'PUT',
      null,
      formData,
    )
      .then((response) => {
        if (response && response.error) {
          setError(Object.values(response.error).join(', '));
          setIsModalLoading(false);
          return;
        } else if (response) {
          fetchWeeklyTipData();
          setShowFormModal(false);
          setIsUpdate(false);
        }
        setIsModalLoading(false);
      })
      .catch(() => {
        setIsModalLoading(false);
        setShowFormModal(false);
        setIsUpdate(false);
      });
  };

  const handleDelete = (recordId) => {
    setIsLoading(true);
    requestWithAuth(
      'vault-dashboard-suggestions/' + recordId,
      'DELETE',
      null,
      null,
    ).then(() => {
      message.success('Entry deleted');
      setIsLoading(false);
      fetchWeeklyTipData();
    });
  };

  const handleDeleteMultiple = () => {
    const body = {
      id_list: selectedIds,
    };
    setIsLoading(true);
    requestWithAuth(
      'vault-dashboard-suggestions/delete-multiple',
      'POST',
      null,
      body,
    ).then((response) => {
      if (response && response.code === 200) message.success('Entry deleted');
      setSelectedIds([]);
      setSelectedRowKeys([]);
      setIsLoading(false);
      fetchWeeklyTipData();
    });
  };

  const setFileListValue = (fileList) => {
    setFileList(fileList);
  };

  const toggleFormModal = (flag) => {
    setShowFormModal(flag);
  };

  const showUpdateForm = (record) => {
    setIsUpdate(true);
    setShowFormModal(true);
    setRecordFormVals({ ...record });
    /** image url has expiry, so to get the latest image url GET api is called */
    // requestWithAuth(`vault-dashboard-suggestions/${record.id}`, 'GET', null, null).then((response) => {
    //     if (response) {
    //         setRecordFormVals({ ...response })
    //     }
    // });
  };

  const onSelectChange = (selectedRowKeys, selectedRows) => {
    let selectedIds = [];
    selectedIds = selectedRows.map((obj) => {
      return obj.id;
    });
    setSelectedRowKeys(selectedRowKeys);
    setSelectedIds(selectedIds);
  };

  const handleTableChange = (pagination, filtersArg, sorter) => {
    setSelectedRowKeys([]);
    if (sorter && sorter.order && sorter.columnKey) {
      params.ordering =
        sorter.order === 'descend' ? '-' + sorter.columnKey : sorter.columnKey;
      setSortInfo({
        column: sorter.columnKey,
        order: sorter.order,
      });
    } else {
      setSortInfo({
        column: '',
        order: '',
      });
    }
    // setParams(params);
    // if (
    //   pagination.pageSize === listPagination.pageSize &&
    //   pagination.current === listPagination.current
    // ) {
    //   fetchWeeklyTipData();
    // }
    setListPagination({
      ...pagination,
      pageSize: pagination.pageSize,
      current: pagination.current,
    });
  };

  const handleSearchQuery = (searchValue) => {
    setSearchQuery(searchValue || '');
    setListPagination((prevState) => ({
      ...prevState,
      current: 1,
    }));
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: 150,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortInfo.column === 'title' && sortInfo.order,
      render: (val) => {
        return (
          <div
            style={{ width: '100%', wordBreak: 'break-word', display: 'block' }}
          >
            {val}
          </div>
        );
      },
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: 200,
      render: (val) => {
        return (
          <div
            style={{ width: '100%', wordBreak: 'break-word', display: 'block' }}
          >
            {val}
          </div>
        );
      },
    },
    {
      title: 'File Name',
      dataIndex: 'file_name',
      key: 'file_name',
      width: 160,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortInfo.column === 'file_name' && sortInfo.order,
      render: (val) => {
        return (
          <div
            style={{ width: '100%', wordBreak: 'break-word', display: 'block' }}
          >
            {val}
          </div>
        );
      },
    },
    {
      title: 'Start Date',
      width: 120,
      dataIndex: 'start_date',
      key: 'start_date',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortInfo.column === 'start_date' && sortInfo.order,
      render: (val) => {
        return (
          <div
            style={{ width: '100%', wordBreak: 'break-word', display: 'block' }}
          >
            {val ? moment(val).format('MM/DD/YY') : ''}
          </div>
        );
      },
    },
    {
      title: 'End Date',
      width: 120,
      dataIndex: 'end_date',
      key: 'end_date',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortInfo.column === 'end_date' && sortInfo.order,
      render: (val) => {
        return (
          <div
            style={{ width: '100%', wordBreak: 'break-word', display: 'block' }}
          >
            {val ? moment(val).format('MM/DD/YY') : ''}
          </div>
        );
      },
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 120,
      onCell: () => ({
        onClick: (e) => {
          e.stopPropagation();
        },
      }),
      render: (text, record) => (
        <>
          <span
            style={{ color: 'var(--color-primary)' }}
            onClick={() => showUpdateForm(record)}
          >
            Edit
          </span>
          <Divider type="vertical" />
          <Popconfirm title="Delete?" onConfirm={() => handleDelete(record.id)}>
            <span
              style={{ color: 'var(--color-primary)' }}
              onClick={(e) => e.preventDefault()}
            >
              Delete
            </span>
          </Popconfirm>
        </>
      ),
    },
  ];

  return (
    <>
      <div className="people-editor">
        <TopSection
          count={weeklyDataCount}
          placeholder="Search Title, File Name "
          handleSearchQuery={handleSearchQuery}
          handleFormReset={handleFormReset}
          toggleCreateForm={toggleFormModal}
          selectedRowKeys={selectedRowKeys}
          handleDeleteMultiple={handleDeleteMultiple}
          showSearch
          searchValue={searchQuery}
        />
        <Spin size="default" spinning={isLoading}>
          <CommonTable
            columns={columns}
            data={weeklyTipData}
            count={weeklyDataCount}
            components={components}
            rowClassName={() => 'editable-row'}
            onTableChange={handleTableChange}
            pagination={{ ...listPagination }}
            pageSize={listPagination.pageSize}
            loading={isLoading}
            rowKey={(rec) => `${rec.events_id}${rec.id}`}
            rowSelection={rowSelection}
            selectedRowKeys={selectedRowKeys}
            rowEventHandlers={showUpdateForm}
          />
        </Spin>
      </div>
      {showFormModal && (
        <FormModal
          form={form}
          isUpdate={isUpdate}
          handleUpdate={handleUpdate}
          handleCreate={handleCreate}
          isModalLoading={isModalLoading}
          recordFormVals={recordFormVals}
          fileList={fileList}
          setFileListValue={setFileListValue}
          error={error}
          onClose={() => {
            form.resetFields();
            setIsUpdate(false);
            setShowFormModal(false);
            setRecordFormVals(null);
            setError(null);
          }}
        />
      )}
    </>
  );
};

export default WeeklyTip;
