import {
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SettingOutlined,
  UserOutlined,
} from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Dropdown } from 'antd';

import { useStytchB2BClient } from '@stytch/react/b2b';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

interface AppHeaderProps {
  collapsed: boolean;
  toggle: () => void;
  userName: string;
}
const AppHeader = ({ collapsed, toggle, userName }: AppHeaderProps) => {
  const stytch = useStytchB2BClient();
  const navigate = useNavigate();
  const handleLogout = useCallback(async () => {
    await stytch.session.revoke();
    navigate('/');
  }, [stytch, navigate]);

  const onMenuClick = ({ key }) => {
    if (key === 'userinfo') {
      navigate('/account/settings/base');
      return;
    }
    if (key === 'logout') {
      handleLogout();
    }
  };

  const items: MenuProps['items'] = [
    {
      key: 'userinfo',
      icon: <SettingOutlined />,
      label: 'Account Settings',
    },
    {
      type: 'divider',
    },
    {
      key: 'logout',
      icon: <LogoutOutlined />,
      label: 'Sign out',
    },
  ];

  return (
    <div className="ai-admin-header">
      <div className="ai-fold-icon" onClick={toggle}>
        {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
      </div>
      <Dropdown
        menu={{ items: items, onClick: onMenuClick }}
        placement="bottomLeft"
        trigger={['click', 'hover']}
      >
        <div className="ai-user">
          <UserOutlined /> <span className="ai-username">{userName}</span>
        </div>
      </Dropdown>
    </div>
  );
};

export default AppHeader;
