import '../index.scss';

import { DeleteOutlined, EditOutlined, FilterFilled } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Space,
  Spin,
  Tooltip,
} from 'antd';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import CommonTable from '../../components/CommonTable';
import DataSearch from '../../components/DataSearch';
import TopSection from '../../components/TopSection';
import { requestWithAuth } from '../../services/api';

interface SelectedUser {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  is_admin?: boolean;
}

interface RequestParams {
  page_size?: number;
  page?: number;
  q?: string;
  ordering?: string;
  user_count__gte?: number;
  user_count__lte?: number;
}

interface TeamUser {
  id?: number;
  user_id: number;
  is_admin: boolean;
  first_name: string;
  last_name: string;
  email: string;
  user_type: string;
}

interface RequestBody {
  user_data: TeamUser[];
  organization_id: number;
}

interface RequestBody {
  team_ids?: number[];
  id?: number;
}

interface FormVals {
  id?: number;
  name?: string;
  slug?: string;
  is_active?: boolean;
  is_nexis?: boolean;
}

let params: RequestParams = {};

const FormItems = ({
  formVals,
  form,
  isUpdate,
  isNexisAdmin,
  onAddUserClick,
}) => {
  const [userSelected, setUserSelected] = useState<SelectedUser | null>(null);

  useEffect(() => {
    form.resetFields();
  }, [formVals, form]);

  const onUserSelect = (data) => {
    setUserSelected({
      id: data.key,
      email: data.email,
      first_name: data.first_name,
      last_name: data.last_name,
    });
  };

  const addUser = () => {
    userSelected.is_admin = false;
    onAddUserClick(userSelected);
    setUserSelected(null);
  };

  return (
    <>
      <Form.Item
        name="name"
        label="Name"
        required
        rules={[
          {
            required: true,
            message: 'Enter name',
          },
        ]}
        initialValue={formVals.name}
      >
        <Input placeholder="The name of the organization" />
      </Form.Item>
      ,
      <Form.Item name="slug" label="Slug" initialValue={formVals.slug}>
        <Input disabled />
      </Form.Item>
      <Form.Item
        name="is_active"
        label="Is active"
        initialValue={isUpdate ? formVals.is_active : true}
        valuePropName="checked"
      >
        <Checkbox style={{ lineHeight: '32px' }} />
      </Form.Item>
      {isUpdate && !isNexisAdmin && (
        <Form.Item
          name="is_nexis"
          label="Is Nexis Team"
          initialValue={isUpdate ? formVals.is_nexis : false}
          valuePropName="checked"
        >
          <Checkbox
            style={{ lineHeight: '32px' }}
            disabled
            defaultChecked={isUpdate ? formVals.is_nexis : false}
          />
        </Form.Item>
      )}
      <Form.Item name="new_user" label="New User">
        <div style={{ display: 'flex', columnGap: '10px' }}>
          <DataSearch
            id="vault-user"
            placeholder="Search User"
            value={
              userSelected && userSelected.id
                ? {
                    key: userSelected.id,
                    label: userSelected.email,
                    value: userSelected.id,
                  }
                : null
            }
            onSelect={onUserSelect}
          />
          <Button
            onClick={addUser}
            className="common-button"
            disabled={!userSelected || !userSelected.id}
          >
            ADD
          </Button>
        </div>
      </Form.Item>
    </>
  );
};

const FormModal = ({
  form,
  isUpdate,
  isNexisAdmin,
  handleTeamUpdate,
  handleTeamCreate,
  handleUserCreate,
  isModalLoading,
  onClose,
  recordFormVals,
  error,
}) => {
  const [formVals, setFormVals] = useState<FormVals>({});
  const [userData, setUserData] = useState([]);
  const [teamNameOld, setTeamNameOld] = useState('');

  useEffect(() => {
    if (isUpdate) {
      setFormVals({ ...recordFormVals });
      fetchCurrentTeamUsers(recordFormVals.id);
      setTeamNameOld(recordFormVals.name);
    }
  }, [recordFormVals, isUpdate]);

  const fetchCurrentTeamUsers = (id) => {
    const userInfo: TeamUser[] = [];
    const newParams = {
      page_size: 100,
      page: 1,
      organization_id: id,
      ordering: 'created',
    };

    requestWithAuth('vault-team-user', 'GET', newParams, null).then(
      (response) => {
        if (response && response.results) {
          response.results.map((item) => {
            userInfo.push({
              id: item.id,
              user_id: item.user,
              first_name: item.first_name,
              last_name: item.last_name,
              email: item.email,
              user_type: item.user_type,
              is_admin: item.is_admin,
            });
            return null;
          });
        }
        setUserData([...userInfo]);
      },
    );
  };

  const addUserToList = (userNew) => {
    if (userData.some((user) => user.user_id === userNew.id)) {
      message.error('User Already Added', 5);
      return;
    }
    setUserData([
      ...userData,
      {
        user_id: userNew.id,
        first_name: userNew.first_name,
        last_name: userNew.last_name,
        email: userNew.email,
        user_type: userNew.user_type,
        is_admin: userNew.is_admin,
      },
    ]);
  };

  // const editUser = (e, record) => {
  //   const newData = [...userData];
  //   const index = newData.findIndex((item) => item.email === record.email);
  //   const item = newData[index];
  //   item.is_admin = e.target.checked;
  //   newData.splice(index, 1, {
  //     ...item,
  //     ...record,
  //   });
  //   setUserData([...newData]);
  // };

  const onDeleteClick = (email) => {
    const itemIndex = userData.findIndex((ele) => ele.email === email);
    if (itemIndex >= 0) userData.splice(itemIndex, 1);
    setUserData([...userData]);
  };

  const handleSubmit = () => {
    if (!userData || userData.length === 0) {
      message.error('Please add at least one user');
      return;
    }

    const userFieldData = userData.map((item) => {
      return { id: item.id, user_id: item.user_id, is_admin: item.is_admin };
    });
    form
      .validateFields()
      .then((values) => {
        const formValues = { ...values };
        if (isUpdate) {
          if (formValues.name.toUpperCase() === teamNameOld.toUpperCase()) {
            /** if team name is not updated, directly update the users, team update is not needed.*/
            if (userFieldData && userFieldData.length > 0)
              handleUserCreate(userFieldData, formVals.id);
          } else handleTeamUpdate(formVals.id, formValues, userFieldData);
        } else {
          handleTeamCreate(formValues, userFieldData);
        }
      })
      .catch((errorInfo) => {
        if (errorInfo) {
          console.log(errorInfo);
          message.error('Please fill the mandatory fileds');
          return;
        }
      });
  };

  const newUserColumns = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 150,
      render: (val, record) => (
        <Link to={`/user-editor/users?user_id=${record.user_id}`}>{val}</Link>
      ),
    },
    {
      title: 'First Name',
      dataIndex: 'first_name',
      key: 'first_name',
      width: 150,
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      key: 'last_name',
      width: 150,
    },
    {
      title: 'User Type',
      dataIndex: 'user_type',
      key: 'user_type',
      width: 150,
    },
    // XX: 20241101 dkoch -- this is_admin field isn't used in the app
    // {
    //   title: "Is Admin",
    //   dataIndex: "is_admin",
    //   key: "is_admin",
    //   className: "center",
    //   width: 80,
    //   render: (val, record) => (
    //     <Checkbox defaultChecked={val} onChange={(e) => editUser(e, record)} />
    //   ),
    // },
    {
      title: 'Action',
      key: 'action',
      width: 120,
      render: (text, record) => (
        <Button
          className="row-actions"
          onClick={() => onDeleteClick(record.email)}
        >
          Delete
        </Button>
      ),
    },
  ];
  return (
    <Modal
      title={isUpdate ? 'Update Team' : 'New Team'}
      open
      width={1000}
      okText={isUpdate ? 'Update' : 'Create'}
      onOk={handleSubmit}
      confirmLoading={isModalLoading}
      onCancel={onClose}
    >
      <Spin size="default" spinning={isModalLoading}>
        <Form labelCol={{ span: 7 }} wrapperCol={{ span: 13 }} form={form}>
          <FormItems
            formVals={formVals}
            form={form}
            isUpdate={isUpdate}
            isNexisAdmin={isNexisAdmin}
            onAddUserClick={addUserToList}
          />
          {userData.length > 0 && (
            <CommonTable
              columns={newUserColumns}
              data={userData}
              pagination={null}
              rowKey={(rec) => rec.email}
              size="small"
            />
          )}
          {error && <div className="error-text">{error}</div>}
        </Form>
      </Spin>
    </Modal>
  );
};

const TeamEditor = ({ isNexisAdmin }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const [teamsData, setTeamsData] = useState([]);
  const [teamsDataCount, setTeamsDataCount] = useState(0);
  const [listPagination, setListPagination] = useState({
    total: 0,
    pageSize: 50,
    current: 1,
  });
  const { pageSize, current } = listPagination;
  const [sortInfo, setSortInfo] = useState({
    column: '',
    order: '',
  });
  const { column, order } = sortInfo;
  const [isUpdate, setIsUpdate] = useState(false);
  const [showFormModal, setShowFormModal] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [recordFormVals, setRecordFormVals] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [userCountRange, setUserCountRange] = useState({
    min: null,
    max: null,
  });

  const [form] = Form.useForm();

  const fetchTeamsData = useCallback(() => {
    setIsLoading(true);
    const newParams = {
      ...Object.fromEntries(searchParams),
      ...params,
      page_size: pageSize,
      page: current,
    };
    if (searchQuery) newParams.q = searchQuery;
    requestWithAuth('vault-team', 'GET', newParams, null).then((response) => {
      if (response && response.results) {
        setTeamsData(response.results);
        setTeamsDataCount(response.count);
        setListPagination((prevState) => ({
          ...prevState,
          total: response.count,
        }));
        setIsLoading(false);
      }
    });
  }, [pageSize, current, searchQuery, searchParams]);

  useEffect(() => {
    fetchTeamsData();

    // return () => {
    //     params = {};
    // setSortInfo({
    //     column: '',
    //     order: ''
    // });
    // searchQuery = '';
    // }
  }, [fetchTeamsData, column, order]);
  useEffect(() => {
    return () => {
      params = {};
    };
  }, []);

  const handleSearchQuery = (searchValue) => {
    setSearchQuery(searchValue || '');
    setListPagination((prevState) => ({
      ...prevState,
      current: 1,
    }));
  };

  const handleTableChange = (pagination, filtersArg, sorter) => {
    setSelectedRowKeys([]);
    if (sorter && sorter.order && sorter.columnKey) {
      params.ordering =
        sorter.order === 'descend' ? '-' + sorter.columnKey : sorter.columnKey;
      setSortInfo({
        column: sorter.columnKey,
        order: sorter.order,
      });
    } else {
      setSortInfo({
        column: '',
        order: '',
      });
      delete params.ordering;
    }
    // if (
    //     pagination.pageSize === listPagination.pageSize &&
    //     pagination.current === listPagination.current
    // ) {
    //     fetchTeamsData();
    // }
    setListPagination({
      ...pagination,
      pageSize: pagination.pageSize,
      current: pagination.current,
    });
  };

  const handleFormReset = () => {
    params = {};
    setSortInfo({
      column: '',
      order: '',
    });
    setListPagination({
      current: 1,
      pageSize: 50,
      total: 0,
    });
    setSearchQuery('');
    // Clear URL query params
    setSearchParams({});
    setUserCountRange({ min: null, max: null });
    fetchTeamsData();
  };

  const handleTeamCreate = (fields, userFields) => {
    const teamFields = { name: fields.name, is_active: fields.is_active };
    setIsModalLoading(true);
    setError(null);
    requestWithAuth('vault-team', 'POST', null, teamFields)
      .then((response) => {
        if (response && response.error) {
          setError(Object.values(response.error).join(', '));
          setIsModalLoading(false);
          return;
        }
        if (userFields && userFields.length > 0)
          handleUserCreate(userFields, response.id);
        if (response) {
          fetchTeamsData();
          setShowFormModal(false);
        }
        setIsModalLoading(false);
      })
      .catch(() => {
        setIsModalLoading(false);
        setShowFormModal(false);
      });
  };

  const handleUserCreate = (fields, teamId) => {
    setIsModalLoading(true);
    setError(null);
    const body: RequestBody = {
      user_data: fields,
      organization_id: teamId,
    };
    requestWithAuth('vault-team-user/update-multiple', 'POST', null, body)
      .then((response) => {
        if (response && response.error) {
          setError(Object.values(response.error).join(', '));
          setIsModalLoading(false);
        }

        // fetchTeamUsersData();
        setIsModalLoading(false);
        setShowFormModal(false);
      })
      .catch(() => {
        setIsModalLoading(false);
        setShowFormModal(false);
      });
  };

  const handleTeamUpdate = (recordId, fields, userFields) => {
    setIsModalLoading(true);
    setError(null);
    if (fields.is_nexis) delete fields.is_nexis;
    requestWithAuth(`vault-team/${recordId}`, 'PUT', null, fields)
      .then((response) => {
        if (response && response.error) {
          setError(Object.values(response.error).join(', '));
          setIsModalLoading(false);
          return;
        }
        if (userFields && userFields.length > 0)
          handleUserCreate(userFields, recordId);
        if (response) {
          setShowFormModal(false);
          fetchTeamsData();
          setIsUpdate(false);
        }
        setIsModalLoading(false);
      })
      .catch(() => {
        setIsModalLoading(false);
        setShowFormModal(false);
        setIsUpdate(false);
      });
  };

  const handleDelete = (_, recordId) => {
    const body = {
      team_ids: recordId ? [recordId] : selectedRowKeys,
    };

    setIsLoading(true);
    requestWithAuth('vault-team/delete-multiple', 'POST', null, body).then(
      (response) => {
        if (response && response.code === 200) message.success('Entry deleted');
        setSelectedRowKeys([]);
        setIsLoading(false);
        fetchTeamsData();
      },
    );
  };

  const showUpdateForm = (record) => {
    setIsUpdate(true);
    setShowFormModal(true);
    setRecordFormVals({ ...record });
  };

  const toggleFormModal = (flag) => {
    setShowFormModal(flag);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys([...selectedRowKeys]);
    },
  };

  const handleUserCountFilter = (min, max, confirm) => {
    const newParams = { ...params };
    if (min !== null) {
      newParams.user_count__gte = min;
    } else {
      delete newParams.user_count__gte;
    }
    if (max !== null) {
      newParams.user_count__lte = max;
    } else {
      delete newParams.user_count__lte;
    }
    params = newParams;
    setUserCountRange({ min, max });
    confirm();
    fetchTeamsData();
  };

  const resetUserCountFilter = (clearFilters, confirm) => {
    clearFilters();
    setUserCountRange({ min: null, max: null });
    delete params.user_count__gte;
    delete params.user_count__lte;
    confirm();
    fetchTeamsData();
  };

  const columns = [
    {
      title: 'Team',
      dataIndex: 'name',
      key: 'name',
      width: 150,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortInfo.column === 'name' && sortInfo.order,
      className: 'word-break',
    },
    {
      title: 'Slug',
      dataIndex: 'slug',
      key: 'slug',
      width: 200,
      className: 'word-break',
    },
    {
      title: 'User Count',
      dataIndex: 'user_count',
      key: 'user_count',
      width: 100,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortInfo.column === 'user_count' && sortInfo.order,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input.Group compact style={{ marginBottom: 8 }}>
            <Input
              style={{ width: 80, textAlign: 'center' }}
              placeholder="Min"
              value={userCountRange.min}
              onChange={(e) => {
                const val = e.target.value ? parseInt(e.target.value) : null;
                setUserCountRange((prev) => ({ ...prev, min: val }));
              }}
              type="number"
            />
            <Input
              style={{
                width: 30,
                pointerEvents: 'none',
                backgroundColor: '#fff',
              }}
              placeholder="~"
              disabled
            />
            <Input
              style={{ width: 80, textAlign: 'center' }}
              placeholder="Max"
              value={userCountRange.max}
              onChange={(e) => {
                const val = e.target.value ? parseInt(e.target.value) : null;
                setUserCountRange((prev) => ({ ...prev, max: val }));
              }}
              type="number"
            />
          </Input.Group>
          <Button
            type="primary"
            onClick={() =>
              handleUserCountFilter(
                userCountRange.min,
                userCountRange.max,
                confirm,
              )
            }
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Filter
          </Button>
          <Button
            onClick={() => resetUserCountFilter(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      ),
      filterIcon: () => (
        <FilterFilled
          style={{
            color:
              userCountRange.min !== null || userCountRange.max !== null
                ? 'var(--color-primary)'
                : undefined,
          }}
        />
      ),
      render: (val, record) => (
        <Link to={`/user-editor/users?team_id=${record.id}`}>{val}</Link>
      ),
    },
    {
      title: 'Created',
      width: 120,
      dataIndex: 'created',
      key: 'created',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortInfo.column === 'created' && sortInfo.order,
      render: (val) => {
        return (
          <div
            style={{ width: '100%', wordBreak: 'break-word', display: 'block' }}
          >
            {val ? moment(val).format('MM/DD/YY') : ''}
          </div>
        );
      },
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 120,
      onCell: () => ({
        onClick: (e) => {
          e.stopPropagation();
        },
      }),
      render: (text, record) => (
        <Space size="small">
          <Tooltip title="Edit">
            <Button
              icon={<EditOutlined />}
              onClick={() => showUpdateForm(record)}
              size="small"
            />
          </Tooltip>
          <Tooltip title="Delete">
            <Popconfirm
              title="Delete?"
              onConfirm={(e) => handleDelete(e, record.id)}
            >
              <Button
                icon={<DeleteOutlined />}
                size="small"
                onClick={(e) => e.preventDefault()}
              />
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <>
      <div>
        <TopSection
          placeholder="Search Team"
          count={teamsDataCount}
          showSearch
          handleFormReset={handleFormReset}
          handleSearchQuery={handleSearchQuery}
          toggleCreateForm={toggleFormModal}
          selectedRowKeys={selectedRowKeys}
          handleDeleteMultiple={handleDelete}
          searchValue={searchQuery}
        />
        <Spin size="default" spinning={isLoading}>
          <CommonTable
            columns={columns}
            data={teamsData}
            onTableChange={handleTableChange}
            pagination={{ ...listPagination }}
            pageSize={listPagination.pageSize}
            loading={isLoading}
            rowKey={(rec) => rec.id}
            rowSelection={rowSelection}
            selectedRowKeys={selectedRowKeys}
            rowEventHandlers={showUpdateForm}
          />
        </Spin>
      </div>
      {showFormModal && (
        <FormModal
          form={form}
          isUpdate={isUpdate}
          isNexisAdmin={isNexisAdmin}
          handleTeamUpdate={handleTeamUpdate}
          handleTeamCreate={handleTeamCreate}
          handleUserCreate={handleUserCreate}
          isModalLoading={isModalLoading}
          recordFormVals={recordFormVals}
          error={error}
          onClose={() => {
            form.resetFields();
            setIsUpdate(false);
            setShowFormModal(false);
            setRecordFormVals(null);
            setError(null);
          }}
        />
      )}
    </>
  );
};

export default TeamEditor;
