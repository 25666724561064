import React, { useState, useRef, useContext, useEffect } from 'react';
import { Form, Input } from 'antd';

export const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  required,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);

  useEffect(() => {
    if (editing && inputRef) {
      inputRef.current?.focus({
        cursor: 'end',
      });
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      if (values && Object.keys(values)) {
        const key = Object.keys(values)[0];
        if (record[key] !== values[key]) handleSave({ ...record, ...values });
      }
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  const renderCell = () => {
    return editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={
          required
            ? [
                {
                  required: true,
                  message: `${title} is required.`,
                },
              ]
            : []
        }
      >
        <Input
          style={{ minWidth: '150px' }}
          ref={inputRef}
          onPressEnter={save}
          onBlur={save}
          autoComplete="_away"
        />
      </Form.Item>
    ) : (
      <div
        style={{ padding: 0, margin: 0 }}
        onClick={(e) => {
          toggleEdit();
          e.stopPropagation();
        }}
      >
        {children}
      </div>
    );
  };

  return (
    <td {...restProps}>
      {editable ? (
        <EditableContext.Consumer>{renderCell}</EditableContext.Consumer>
      ) : (
        children
      )}
    </td>
  );
};

const EditableContext = React.createContext(null);

export const EditableRow = ({ ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};
