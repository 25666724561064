import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

const {
  VITE_SENTRY_DSN,
  VITE_ENV,
  VITE_SENTRY_TRACES_SAMPLE_RATE,
  VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE,
} = import.meta.env;

Sentry.init({
  dsn: VITE_SENTRY_DSN,
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate anywhere from 0.0 to 1.0 to capture 0% to 100% of transactions.
  tracesSampleRate: VITE_SENTRY_TRACES_SAMPLE_RATE
    ? parseFloat(VITE_SENTRY_TRACES_SAMPLE_RATE)
    : 0.0,

  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: [
    /^\//,
    /^http:\/\/localhost:8001\//,
    /^https:\/\/api\.aidentified\.com\//,
    /^https:\/\/api-staging\.aidentified\.com\//,
  ],

  // Capture Replays for 0-100% of all sessions based on the environment variable,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE
    ? parseFloat(VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE)
    : 0.0,
  replaysOnErrorSampleRate: 1.0,

  environment: VITE_ENV,
});
