import '../index.scss';

import { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import { Popconfirm, message, Spin } from 'antd';
import { requestWithAuth } from '../../services/api';
import CommonTable from '../../components/CommonTable';
import { EditableCell, EditableRow } from '../../components/EditableComponents';
import TopSection from '../../components/TopSection';

interface RequestBody {
  status?: string;
  ids?: number[];
}

interface Params {
  page_size?: number;
  page?: number;
  ordering?: string;
}

let params: Params = {};

const Comments = () => {
  const [listPagination, setListPagination] = useState({
    total: 0,
    pageSize: 50,
    current: 1,
  });
  const { pageSize, current } = listPagination;
  const [isLoading, setIsLoading] = useState(false);
  const [commentsData, setCommentsData] = useState([]);
  const [commentsCount, setCommentsCount] = useState(0);
  const [sortInfo, setSortInfo] = useState({
    column: '',
    order: '',
  });
  const { column, order } = sortInfo;
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const fetchCommentsData = useCallback(() => {
    setIsLoading(true);
    const newParams = {
      ...params,
      page_size: pageSize,
      page: current,
    };

    requestWithAuth('profile-comments', 'GET', newParams, null).then(
      (response) => {
        if (response && response.results) {
          setCommentsData(response.results);
          setCommentsCount(response.count);
          setListPagination((prevState) => ({
            ...prevState,
            total: response.count,
          }));
          setIsLoading(false);
        }
      },
    );
  }, [pageSize, current]);
  //   useEffect(() => {
  //     setListPagination((prevListPagination) => ({
  //       ...prevListPagination,
  //       total: commentsCount,
  //     }));
  //   }, [commentsCount]);
  //fetchCommentsData();
  useEffect(() => {
    fetchCommentsData();
  }, [fetchCommentsData, column, order]);
  useEffect(() => {
    return () => {
      params = {};
    };
  }, []);

  //   useEffect(() => {
  //     if (total === 0) return;
  //     fetchCommentsData();
  //   }, [fetchCommentsData, total]);

  const handleSave = (row) => {
    if (row.admin_status === '-') return;
    setIsLoading(true);
    const body: RequestBody = {
      status: row.admin_status,
    };

    requestWithAuth('profile-comments/' + row.id, 'PUT', null, body).then(
      (response) => {
        if (response && response.error) {
          message.error(
            response.error.detail || 'Please try after some time',
            5,
          );
        } else if (response) {
          fetchCommentsData();
        }

        setIsLoading(false);
      },
    );
  };
  const handleDelete = (e, recordId) => {
    const body: RequestBody = {
      ids: recordId ? [recordId] : selectedRowKeys,
    };
    setIsLoading(true);
    requestWithAuth(
      'profile-comments/delete-multiple',
      'POST',
      null,
      body,
    ).then((response) => {
      if (response && response.code === 200) message.success('Entry deleted');
      setSelectedRowKeys([]);
      setIsLoading(false);
      fetchCommentsData();
    });
  };

  const handleTableChange = (pagination, filtersArg, sorter) => {
    setSelectedRowKeys([]);
    if (sorter && sorter.order && sorter.columnKey) {
      params.ordering =
        sorter.order === 'descend' ? '-' + sorter.columnKey : sorter.columnKey;
      setSortInfo({
        column: sorter.columnKey,
        order: sorter.order,
      });
    } else {
      setSortInfo({
        column: '',
        order: '',
      });
    }
    // if (
    //   pagination.pageSize === listPagination.pageSize &&
    //   pagination.current === listPagination.current
    // ) {
    //   fetchCommentsData();
    // }
    setListPagination({
      ...pagination,
      pageSize: pagination.pageSize,
      current: pagination.current,
    });
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys([...selectedRowKeys]);
    },
  };

  const getDate = (date) => {
    return moment(date).format('MM/DD/YYYY');
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = [
    {
      title: 'Comment',
      dataIndex: 'user_comment',
      key: 'user_comment',
      width: 180,
    },
    {
      title: 'Profile First name',
      dataIndex: 'first_name',
      key: 'first_name',
      width: 180,
    },
    {
      title: 'Profile Last name',
      dataIndex: 'last_name',
      key: 'last_name',
      width: 180,
    },
    {
      title: 'Profile Title',
      dataIndex: 'title',
      key: 'title',
      width: 180,
    },
    {
      title: 'Profile Company',
      dataIndex: 'org_name',
      key: 'org_name',
      width: 180,
    },
    {
      title: 'Profile B2bId',
      dataIndex: 'b2b_id',
      key: 'b2b_id',
      width: 180,
    },
    {
      title: 'Profile B2cId',
      dataIndex: 'b2c_id',
      key: 'b2c_id',
      width: 180,
    },
    {
      title: 'Profile LinkedIn',
      dataIndex: 'linkedin_urls',
      key: 'linkedin_urls',
      width: 180,
      render: (linkedIns) => (
        <div style={{ width: '100%' }}>
          {Array.isArray(linkedIns) &&
            linkedIns.map((linkedIn) => {
              const link = `//${linkedIn}`;
              return (
                <span key={linkedIn}>
                  <a
                    style={{ wordBreak: 'break-word', display: 'block' }}
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {linkedIn}
                  </a>{' '}
                </span>
              );
            })}
        </div>
      ),
    },
    {
      title: 'Submitted By',
      dataIndex: 'owner_name',
      key: 'owner_name',
      width: 180,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortInfo.column === 'owner_name' && sortInfo.order,
    },
    {
      title: 'Submitted On',
      dataIndex: 'created',
      key: 'created',
      width: 150,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortInfo.column === 'created' && sortInfo.order,
      render: (date) => <div>{getDate(date)}</div>,
    },
    {
      title: 'Status',
      width: 200,
      dataIndex: 'admin_status',
      key: 'admin_status',
      editable: true,
      className: 'editable-row status-edit',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortInfo.column === 'admin_status' && sortInfo.order,
      render: (val) => {
        return (
          <div
            onClick={() => {
              document.querySelector('div.ant-table-body').scrollLeft = 10;
            }}
          >
            {val || '-'}
          </div>
        );
      },
      onCell: (record) => ({
        record,
        editable: true,
        dataIndex: 'admin_status',
        title: 'Status',
        handleSave: handleSave,
      }),
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 80,
      render: (text, record) => (
        <>
          <Popconfirm
            title="Delete?"
            onConfirm={(e) => handleDelete(e, record.id)}
          >
            <div
              style={{ color: 'var(--color-primary)' }}
              onClick={(e) => e.preventDefault()}
            >
              Delete
            </div>
          </Popconfirm>
        </>
      ),
    },
    {
      title: '',
      key: '',
      fixed: 'right',
      width: 120,
      render: (text, record) => (
        <>
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://app.aidentified.com/prospect-finder/people?b2b_id=${record.b2b_id}&b2c_id=${record.b2c_id}`}
          >
            View Profile
          </a>
        </>
      ),
    },
  ];

  return (
    <div className="people-editor">
      <Spin size="default" spinning={isLoading}>
        <TopSection
          showNewButton={false}
          count={commentsCount}
          selectedRowKeys={selectedRowKeys}
          handleDeleteMultiple={handleDelete}
        />
        <CommonTable
          columns={columns}
          data={commentsData}
          components={components}
          onTableChange={handleTableChange}
          pagination={{ ...listPagination }}
          pageSize={listPagination.pageSize}
          loading={isLoading}
          rowKey={(rec) => rec.id}
          rowSelection={rowSelection}
          selectedRowKeys={selectedRowKeys}
          scroll={{ x: 'max-content', y: 600 }}
        />
      </Spin>
    </div>
  );
};

export default Comments;
