import '../index.scss';

import { useCallback, useEffect, useState } from 'react';
import { Spin, Popconfirm, message, Button } from 'antd';
import moment from 'moment';

import { requestWithAuth } from '../../services/api';
import { getValue } from '../../utils/general';
import { importExportStatusList, crmNames } from '../../utils/constants';
import CommonTable from '../../components/CommonTable';
import TopSection from '../../components/TopSection';

interface RequestParams {
  page_size?: number;
  page?: number;
  q?: string;
  ordering?: string;
  crm?: string;
  status?: string;
}

interface RequestBody {
  crm_export_ids: number[];
}

let params: RequestParams = {};
//let searchQuery = '';

function CrmExportTracks() {
  const [listPagination, setListPagination] = useState({
    total: 0,
    pageSize: 50,
    current: 1,
  });
  const { pageSize, current } = listPagination;
  const [isLoading, setIsLoading] = useState(false);
  const [sortInfo, setSortInfo] = useState({
    column: '',
    order: '',
  });
  const { column, order } = sortInfo;
  const [searchQuery, setSearchQuery] = useState('');
  const [filters, setFilters] = useState(null);
  const [exportData, setExportData] = useState([]);
  const [exportDataCount, setExportDataCount] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const fetchExportData = useCallback(() => {
    setIsLoading(true);
    const newParams = {
      ...params,
      page_size: pageSize,
      page: current,
    };
    if (searchQuery) newParams.q = searchQuery;

    requestWithAuth('vault-crm-export-tracker', 'GET', newParams, null).then(
      (response) => {
        if (response && response.results) {
          setExportData(response.results);
          setExportDataCount(response.count);
          setListPagination((prevState) => ({
            ...prevState,
            total: response.count,
          }));
          setIsLoading(false);
        }
      },
    );
  }, [pageSize, current, searchQuery]);

  useEffect(() => {
    fetchExportData();
  }, [fetchExportData, filters, column, order]);
  useEffect(() => {
    return () => {
      params = {};
    };
  }, []);

  const handleTableChange = (pagination, filtersArg, sorter) => {
    setSelectedRowKeys([]);
    Object.keys(filtersArg).reduce((obj, key) => {
      let value = '';
      if (filtersArg[key] !== null) value = getValue(filtersArg[key]);
      if (key === 'crm' || key === 'status') {
        if (!value) {
          if (filters && filters[key]) {
            delete filters[key];
            setFilters({ ...filters });
          }
          delete params[key];
        } else {
          setFilters({ ...filters, [key]: value.split(',') });
          params[key] = value;
        }
      }
      return null;
    }, {});

    if (sorter && sorter.order && sorter.columnKey) {
      params.ordering =
        sorter.order === 'descend' ? '-' + sorter.columnKey : sorter.columnKey;
      setSortInfo({
        column: sorter.columnKey,
        order: sorter.order,
      });
    } else {
      setSortInfo({
        column: '',
        order: '',
      });
      delete params.ordering;
    }
    // if (
    //   pagination.pageSize === listPagination.pageSize &&
    //   pagination.current === listPagination.current
    // ) {
    //   fetchExportData();
    // }
    setListPagination({
      ...pagination,
      pageSize: pagination.pageSize,
      current: pagination.current,
    });
  };

  const handleDelete = (e, recordId) => {
    const body: RequestBody = {
      crm_export_ids: recordId ? [recordId] : selectedRowKeys,
    };

    setIsLoading(true);
    requestWithAuth(
      'vault-crm-export-tracker/delete-multiple',
      'POST',
      null,
      body,
    ).then((response) => {
      if (response && response.code === 200) message.success('Entry deleted');
      setSelectedRowKeys([]);
      setIsLoading(false);
      fetchExportData();
    });
  };

  const handleSearchQuery = (searchValue) => {
    setSearchQuery(searchValue || '');
    setListPagination((prevState) => ({
      ...prevState,
      current: 1,
    }));
  };

  const handleFormReset = () => {
    params = {};
    setSortInfo({
      column: '',
      order: '',
    });
    setListPagination({
      current: 1,
      pageSize: 50,
      total: 0,
    });
    setSearchQuery('');
    setFilters(null);
    //fetchExportData();
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys([...selectedRowKeys]);
    },
  };

  const columns = [
    {
      title: 'Owner Name',
      dataIndex: 'first_name',
      key: 'first_name',
      width: 200,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortInfo.column === 'first_name' && sortInfo.order,
      className: 'word-break',
      render: (val, rec) => {
        return `${rec.first_name} ${rec.last_name || ''}`;
      },
    },
    {
      title: 'CRM',
      dataIndex: 'crm',
      key: 'crm',
      width: 100,
      className: 'word-break capitalize',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortInfo.column === 'crm' && sortInfo.order,
      filters: crmNames,
      filteredValue: filters && filters.crm ? filters.crm : [],
      filterMultiple: true,
    },
    {
      title: 'Full Name',
      dataIndex: 'full_name',
      key: 'full_name',
      width: 200,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortInfo.column === 'full_name' && sortInfo.order,
      className: 'word-break',
    },
    {
      title: 'b2b ID',
      dataIndex: 'b2b_id',
      key: 'b2b_id',
      width: 180,
      editable: true,
      className: 'word-break',
    },
    {
      title: 'b2c ID',
      dataIndex: 'b2c_id',
      key: 'b2c_id',
      width: 180,
      className: 'word-break',
    },
    {
      title: 'Company',
      dataIndex: 'org_name',
      key: 'org_name',
      width: 180,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortInfo.column === 'org_name' && sortInfo.order,
      className: 'word-break',
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: 200,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortInfo.column === 'title' && sortInfo.order,
      className: 'word-break',
    },

    {
      title: 'Created',
      width: 120,
      dataIndex: 'created',
      key: 'created',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortInfo.column === 'created' && sortInfo.order,
      render: (val) => {
        return (
          <div
            style={{ width: '100%', wordBreak: 'break-word', display: 'block' }}
          >
            {val ? moment(val).format('MM/DD/YY') : ''}
          </div>
        );
      },
    },
    {
      title: 'Status',
      width: 100,
      dataIndex: 'status',
      key: 'status',
      className: 'capitalize',
      filters: importExportStatusList,
      filteredValue: filters && filters.status ? filters.status : [],
      filterMultiple: true,
    },
    {
      title: 'Missing Fields',
      dataIndex: 'missing_fields',
      key: 'missing_fields',
      width: 200,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortInfo.column === 'missing_fields' && sortInfo.order,
      className: 'word-break',
      render: (val) => {
        return val ? JSON.stringify(val) : <div className="center">-</div>;
      },
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 120,
      render: (text, record) => (
        <>
          <Popconfirm
            title="Delete?"
            onConfirm={(e) => handleDelete(e, record.id)}
          >
            <Button className="row-actions" onClick={(e) => e.preventDefault()}>
              Delete
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  return (
    <>
      <div className="people-editor">
        <TopSection
          count={exportDataCount}
          placeholder="Search Owner, Full Name "
          showNewButton={false}
          showSearch
          selectedRowKeys={selectedRowKeys}
          handleSearchQuery={handleSearchQuery}
          handleFormReset={handleFormReset}
          handleDeleteMultiple={handleDelete}
          searchValue={searchQuery}
        />
        <Spin size="default" spinning={isLoading}>
          <CommonTable
            columns={columns}
            data={exportData}
            count={exportDataCount}
            onTableChange={handleTableChange}
            pagination={{ ...listPagination }}
            pageSize={listPagination.pageSize}
            loading={isLoading}
            rowKey={(rec) => rec.id}
            rowSelection={rowSelection}
            selectedRowKeys={selectedRowKeys}
          />
        </Spin>
      </div>
    </>
  );
}

export default CrmExportTracks;
