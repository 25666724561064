export const filterStates = [
  {
    text: 'AK',
    value: 'AK',
  },
  {
    text: 'AL',
    value: 'AL',
  },
  {
    text: 'AR',
    value: 'AR',
  },
  {
    text: 'AZ',
    value: 'AZ',
  },
  {
    text: 'CA',
    value: 'CA',
  },
  {
    text: 'CO',
    value: 'CO',
  },
  {
    text: 'CT',
    value: 'CT',
  },
  {
    text: 'DC',
    value: 'DC',
  },
  {
    text: 'DE',
    value: 'DE',
  },
  {
    text: 'FL',
    value: 'FL',
  },
  {
    text: 'GA',
    value: 'GA',
  },
  {
    text: 'HI',
    value: 'HI',
  },
  {
    text: 'IA',
    value: 'IA',
  },
  {
    text: 'ID',
    value: 'ID',
  },
  {
    text: 'IL',
    value: 'IL',
  },
  {
    text: 'IN',
    value: 'IN',
  },
  {
    text: 'KS',
    value: 'KS',
  },
  {
    text: 'KY',
    value: 'KY',
  },
  {
    text: 'LA',
    value: 'LA',
  },
  {
    text: 'MA',
    value: 'MA',
  },
  {
    text: 'MD',
    value: 'MD',
  },
  {
    text: 'ME',
    value: 'ME',
  },
  {
    text: 'MI',
    value: 'MI',
  },
  {
    text: 'MN',
    value: 'MN',
  },
  {
    text: 'MO',
    value: 'MO',
  },
  {
    text: 'MS',
    value: 'MS',
  },
  {
    text: 'MT',
    value: 'MT',
  },
  {
    text: 'NC',
    value: 'NC',
  },
  {
    text: 'ND',
    value: 'ND',
  },
  {
    text: 'NE',
    value: 'NE',
  },
  {
    text: 'NH',
    value: 'NH',
  },
  {
    text: 'NJ',
    value: 'NJ',
  },
  {
    text: 'NM',
    value: 'NM',
  },
  {
    text: 'NV',
    value: 'NV',
  },
  {
    text: 'NY',
    value: 'NY',
  },
  {
    text: 'OH',
    value: 'OH',
  },
  {
    text: 'OK',
    value: 'OK',
  },
  {
    text: 'OR',
    value: 'OR',
  },
  {
    text: 'PA',
    value: 'PA',
  },
  {
    text: 'RI',
    value: 'RI',
  },
  {
    text: 'SC',
    value: 'SC',
  },
  {
    text: 'SD',
    value: 'SD',
  },
  {
    text: 'TN',
    value: 'TN',
  },
  {
    text: 'TX',
    value: 'TX',
  },
  {
    text: 'UT',
    value: 'UT',
  },
  {
    text: 'VA',
    value: 'VA',
  },
  {
    text: 'VT',
    value: 'VT',
  },
  {
    text: 'WA',
    value: 'WA',
  },
  {
    text: 'WI',
    value: 'WI',
  },
  {
    text: 'WV',
    value: 'WV',
  },
  {
    text: 'WY',
    value: 'WY',
  },
];

export const SalaryRanges = [
  {
    value: '$0 - $100k',
    text: '$0 - $100k',
  },
  {
    value: '$101k - $250k',
    text: '$101k - $250k',
  },
  {
    value: '$251k - $500k',
    text: '$251k - $500k',
  },
  {
    value: '$501k - $1M',
    text: '$501k - $1M',
  },
  {
    value: '$1M+',
    text: '$1M+',
  },
];

export const ChildAgeRanges = [
  {
    text: '0-3',
    value: '0-3',
  },
  {
    text: '4-6',
    value: '4-6',
  },
  {
    text: '7-9',
    value: '7-9',
  },
  {
    text: '10-12',
    value: '10-12',
  },
  {
    text: '13-18',
    value: '13-18',
  },
];

export const InterestsArray = [
  { text: 'Barbecuing', value: 'barbecuing' },
  { text: 'Baseball', value: 'baseball' },
  { text: 'Basketball', value: 'basketball' },
  { text: 'Bike - Mountain/Road', value: 'bike_mountain_road' },
  { text: 'Bird Watching', value: 'bird_watching' },
  { text: 'Board Games', value: 'board_games' },
  { text: 'Boating/Sailing', value: 'boating_or_sailing' },
  { text: 'Camping/Hiking', value: 'camping_or_hiking' },
  { text: 'Canoeing/Kayaking', value: 'canoeing_kayaking' },
  { text: 'Card Games', value: 'card_games' },
  { text: 'Unique Cars', value: 'cars' },
  {
    text: 'Charity: Art/Culture/Humanities',
    value: 'charity_art_culture_humanities',
  },
  { text: 'Charity: Education', value: 'charity_education' },
  {
    text: 'Charity: Social Services/Welfare',
    value: 'charity_social_services_welfare',
  },
  { text: 'Comedy Clubs', value: 'comedy_clubs' },
  { text: 'Concert: Classical', value: 'concert_classical' },
  { text: 'Concert: Country', value: 'concert_country' },
  { text: 'Concert: R&B/Hip-Hop/Rap', value: 'concert_rb_hip_hop_rap' },
  { text: 'Concert: Rock/Pop', value: 'concert_rock_pop' },
  { text: 'Concerts', value: 'concerts' },
  { text: 'Charity: Animals', value: 'contribute_animal' },
  { text: 'Charity: Children', value: 'contribute_childrens' },
  { text: 'Charity: Environment', value: 'contribute_environm' },
  { text: 'Charity: Health', value: 'contribute_health' },
  {
    text: 'Charity: Politics - Conservative',
    value: 'contribute_political_conservative',
  },
  {
    text: 'Charity: Politics - Liberal',
    value: 'contribute_political_liberal',
  },
  { text: 'Charity: Religion', value: 'contribute_religious' },
  { text: 'Cooking for Fun', value: 'cooking_for_fun' },
  { text: 'Cruise Ship Vacations', value: 'cruise_ship_vacations' },
  { text: 'Dance Performances', value: 'dance_performances' },
  { text: 'Dining Out - Not Fast Food', value: 'dining_out_not_fast_food' },
  { text: 'Education Courses', value: 'education_courses' },
  { text: 'Fishing', value: 'fishing' },
  { text: 'Fitness', value: 'fitness' },
  { text: 'Fly Fishing', value: 'fly_fishing' },
  { text: 'Football', value: 'football' },
  { text: 'Fresh Water Fish', value: 'fresh_water_fish' },
  { text: 'Gardening', value: 'gardening' },
  { text: 'Go to a Beach/Lake', value: 'go_to_a_beach_lake' },
  { text: 'Golf', value: 'golf' },
  { text: 'Gourmet Cooking', value: 'hobby_cooking_gourmet' },
  { text: 'Photography/Videography', value: 'hobby_photography_videography' },
  { text: 'Wine Appreciation', value: 'hobby_wine_appreciation' },
  { text: 'Hockey', value: 'hockey' },
  { text: 'Home Improvement', value: 'home_improvement' },
  { text: 'Horseback Riding', value: 'horseback_riding' },
  { text: 'Hunting', value: 'hunting' },
  { text: 'Ice Skating', value: 'ice_skating' },
  {
    text: 'Investments: Mutual Funds/CDs',
    value: 'investments_own_mutual_funds_cds',
  },
  {
    text: 'Investments: Stocks/Bonds',
    value: 'investments_own_stocks_or_bonds',
  },
  { text: 'Listening to Music', value: 'listening_to_music' },
  { text: 'Live Theater', value: 'live_theater' },
  { text: 'Motorcycles', value: 'motor_cycle' },
  { text: 'Music: 1940s-1950s Pop', value: 'music_1940s_1950s_pop' },
  {
    text: 'Music: 60s-70s Pop Classic Rock',
    value: 'music_60s_70s_pop_classic_rock',
  },
  { text: 'Music: 80s Old School', value: 'music_80s_old_school' },
  { text: 'Music: 80s Pop/Rock', value: 'music_80s_pop_rock' },
  {
    text: 'Music: Adult Contemporary Pop',
    value: 'music_adult_contemporary_pop',
  },
  { text: 'Music: Alternative', value: 'music_alternative' },
  { text: 'Music: Bluegrass', value: 'music_bluegrass' },
  {
    text: 'Music: Broadway/Movie/TV Soundtracks',
    value: 'music_broadway_movie_tv_soundtracks',
  },
  { text: 'Music: Classical', value: 'music_classical' },
  { text: 'Music: Early 90s Hip Hop', value: 'music_early_90s_hip_hop' },
  { text: 'Music: Easy Listening', value: 'music_easy_listening' },
  { text: 'Music Festivals', value: 'music_festivals' },
  { text: 'Music: Hard Rock', value: 'music_hard_rock' },
  { text: 'Music: Heavy/Speed Metal', value: 'music_heavy_speed_metal' },
  { text: 'Music: Hip Hop', value: 'music_hip_hop' },
  { text: 'Music: Jazz', value: 'music_jazz' },
  { text: 'Music: Mainstream Country', value: 'music_mainstream_country' },
  {
    text: 'Music: Modern Adult Contemporary',
    value: 'music_modern_adult_contemporary',
  },
  { text: 'Music: New Age', value: 'music_new_age' },
  { text: 'Music: Pop Alternative', value: 'music_pop_alternative' },
  { text: 'Music: Pop Hip Hop', value: 'music_pop_hip_hop' },
  { text: 'Music: Pop R&B Tempo', value: 'music_pop_rb_tempo' },
  { text: 'Music: Pure Pop', value: 'music_pure_pop' },
  { text: 'Music: Reggae', value: 'music_reggae' },
  { text: 'Music: Salsa/Merengue', value: 'music_salsa_merengue' },
  { text: 'Music: Techno', value: 'music_techno' },
  { text: 'Music: Traditional Country', value: 'music_traditional_country' },
  { text: 'Music: Traditional Soul/R&B', value: 'music_traditional_soul_rb' },
  { text: 'Nascar', value: 'nascar' },
  { text: 'Needlework/Quilting', value: 'needlework_quilting' },
  {
    text: 'Painting, Drawing, Sculpting',
    value: 'painting_drawing_sculpting',
  },
  { text: 'Pets', value: 'pets' },
  { text: 'Play Musical Instrument', value: 'play_musical_instrument' },
  { text: 'Racquetball', value: 'racquetball' },
  { text: 'Reading Books', value: 'reading_books' },
  { text: 'Running', value: 'running' },
  { text: 'Snow Skiing', value: 'snow_skiing' },
  { text: 'Snowboarding', value: 'snowboarding' },
  { text: 'Snowmobiling', value: 'snowmobiling' },
  { text: 'Soccer', value: 'soccer' },
  { text: 'Softball', value: 'softball' },
  { text: 'Sports', value: 'sports' },
  { text: 'Tennis', value: 'tennis' },
  { text: 'Travel', value: 'travel' },
  { text: 'Volleyball', value: 'volleyball' },
  { text: 'Yoga', value: 'yoga' },
];

export const genderOptions = [
  {
    value: null,
    text: 'Select',
  },
  {
    value: 'F',
    text: 'Female',
  },
  {
    value: 'M',
    text: 'Male',
  },
];

export const maritalStatusOptions = [
  {
    value: null,
    text: 'Select',
  },
  {
    value: 'S',
    text: 'Single',
  },
  {
    value: 'M',
    text: 'Married',
  },
];

export const presenceOfChildrenOptions = [
  {
    value: null,
    text: 'Select',
  },
  {
    value: true,
    text: 'Yes',
  },
  {
    value: false,
    text: 'No',
  },
];

export const titleRankOptions = [
  { value: 24, label: 'C-Suite' },
  { value: 10, label: 'CEO' },
  { value: 11, label: 'Co-CEO' },
  { value: 20, label: 'COO' },
  { value: 21, label: 'CFO' },
  { value: 22, label: 'CIO' },
  { value: 23, label: 'CTO' },
  { value: 25, label: 'CMO' },
  { value: 30, label: 'Principal/Owner' },
  { value: 40, label: 'Founder' },
  { value: 45, label: '(Co)Chairman' },
  { value: 46, label: 'Vice Chairman' },
  { value: 47, label: 'Board Member' },
  { value: 48, label: 'Advisory Board Member' },
  { value: 50, label: 'Managing Partner' },
  { value: 51, label: 'Partner' },
  { value: 52, label: 'Managing Director' },
  { value: 53, label: 'Managing Member' },
  { value: 80, label: 'President' },
  { value: 90, label: 'Executive Vice President' },
  { value: 100, label: 'Senior Vice President' },
  { value: 110, label: 'Vice President' },
  { value: 120, label: 'AVP' },
  { value: 121, label: 'Department Chief' },
  { value: 122, label: 'Department Head' },
  { value: 125, label: 'General Manager' },
  { value: 129, label: 'Executive Director' },
  { value: 130, label: 'Director (non-board)' },
  { value: 140, label: 'Manager' },
  { value: 150, label: 'Judge' },
  { value: 160, label: 'Attorney' },
  { value: 161, label: 'Medical Doctor' },
  { value: 165, label: 'Medical Assistant' },
  { value: 166, label: 'Nurse' },
  { value: 809, label: 'Senior Consultant' },
  { value: 810, label: 'Consultant' },
  { value: 820, label: 'Account Executive' },
  { value: 830, label: 'Financial Advisor' },
  { value: 840, label: 'Sales Representative' },
  { value: 850, label: 'Business Analyst' },
  { value: 900, label: 'Realtor' },
  { value: 930, label: 'Professor' },
  { value: 940, label: 'Accountant' },
  { value: 950, label: 'Software Engineer' },
  { value: 960, label: 'Engineer' },
  { value: 970, label: 'Recruiter' },
  { value: 990, label: 'Teacher' },
  { value: 995, label: 'Retired' },
  { value: 1000, label: 'Other' },
  { value: 1001, label: 'No title' },
];

export const wealthSegmentOptions = [
  {
    text: '<$100k',
    value: 'Low Wealth Market',
    min: 0,
    max: 100000,
  },
  {
    text: '$100k+ to $500k',
    value: 'Mass Market',
    min: 100001,
    max: 500000,
  },
  {
    text: '$500k+ to $1m',
    value: 'Mass Affluent Market Lower',
    min: 500001,
    max: 1000000,
  },
  {
    text: '$1m+ to $2m',
    value: 'Mass Affluent Market',
    min: 1000001,
    max: 2000000,
  },
  {
    text: '$2m+ to $5m',
    value: 'Middle Market',
    min: 2000001,
    max: 5000000,
  },
  {
    text: '$5m+ to $10m',
    value: 'Affluent Market',
    min: 5000001,
    max: 10000000,
  },
  {
    text: '$10m+ to $20m',
    value: 'Wealth Market',
    min: 10000001,
    max: 20000000,
  },
  {
    text: '$20m+ to $50m',
    value: 'Lower Middle Wealth Market',
    min: 20000001,
    max: 50000000,
  },
  {
    text: '$50m+ to $100m',
    value: 'Upper Middle Wealth Market',
    min: 50000001,
    max: 100000000,
  },
  {
    text: '$100m+ to $500m',
    value: 'High Wealth Market',
    min: 100000001,
    max: 500000000,
  },
  {
    text: '$500m+ to $1b',
    value: 'Ultra-High Wealth Market',
    min: 500000001,
    max: 1000000000,
  },
  {
    text: '>$1b',
    value: 'Billionaire Wealth Market',
    min: 1000000001,
    max: null,
  },
];

export const fundingRounds = [
  { text: 'Angel Round', value: 'Angel Round' },
  { text: 'Grant', value: 'Grant' },
  { text: 'Pre Seed Round', value: 'Pre Seed Round' },
  { text: 'Seed Round', value: 'Seed Round' },
  { text: 'Convertible Note', value: 'Convertible Note' },
  { text: 'Equity Crowdfunding', value: 'Equity Crowdfunding' },
  { text: 'Series A', value: 'Series A' },
  { text: 'Series B', value: 'Series B' },
  { text: 'Series C', value: 'Series C' },
  { text: 'Series D', value: 'Series D' },
  { text: 'Series E', value: 'Series E' },
  { text: 'Series F', value: 'Series F' },
  { text: 'Series G', value: 'Series G' },
  { text: 'Series H', value: 'Series H' },
  { text: 'Series I', value: 'Series I' },
  { text: 'Series J', value: 'Series J' },
  { text: 'Venture Round', value: 'Venture Round' },
  { text: 'Corporate Round', value: 'Corporate Round' },
  { text: 'Funding Round', value: 'Funding Round' },
  { text: 'Private Equity Round', value: 'Private Equity Round' },
  { text: 'Debt Financing', value: 'Debt Financing' },
  { text: 'Post-IPO Equity', value: 'Post-IPO Equity' },
  { text: 'Post-IPO Secondary', value: 'Post-IPO Secondary' },
  { text: 'Post-IPO Debt', value: 'Post-IPO Debt' },
  { text: 'Secondary Market', value: 'Secondary Market' },
  { text: 'Initial Coin Offering', value: 'Initial Coin Offering' },
];

export const staffOptionsFilters = [
  {
    text: 'All',
    value: 'all',
  },
  {
    text: 'Yes',
    value: true,
  },
  {
    text: 'No',
    value: false,
  },
];

export const userTypeFilters = [
  {
    text: 'All',
    value: 'all',
  },
  {
    text: 'Premium',
    value: 'premium',
  },
  {
    text: 'Admin',
    value: 'admin',
  },
  {
    text: 'Lite',
    value: 'lite',
  },
];

export const industryList = [
  { text: 'Banking/Mortgage', value: 'Banking/Mortgage' },
  { text: 'Education', value: 'Education' },
  { text: 'Enterprise/B2B', value: 'Enterprise/B2B' },
  // { text: "Financial Services", value: "Financial Services" },
  { text: 'Gaming', value: 'Gaming' },
  { text: 'Healthcare', value: 'Healthcare' },
  { text: 'Insurance', value: 'Insurance' },
  { text: 'IT/Software', value: 'IT/Software' },
  {
    text: 'Hospitality/Food/Travel/Luxury',
    value: 'Hospitality/Food/Travel/Luxury',
  },
  { label: 'Life Planning', value: 'Life Planning' },
  { text: 'Media/Creative Industries', value: 'Media/Creative Industries' },
  { text: 'Non-Profit', value: 'Non-Profit' },
  {
    text: 'Professional Services(Consulting, Law, etc.)',
    value: 'Professional Services(Consulting, Law, etc.)',
  },
  { text: 'Real Estate', value: 'Real Estate' },
  { label: 'Sports', value: 'Sports' },
  { label: 'Wealth Planning', value: 'Wealth Planning' },
  { text: 'Other', value: 'Other' },
];

export const subscriptionStatus = [
  { text: 'Active', value: 'active' },
  { text: 'Cancelled', value: 'cancelled' },
  { text: 'Trial', value: 'trial' },
];

export const homeOwnerStatusList = [
  { text: 'Home Owner', value: 'Home Owner' },
  { text: 'Renter', value: 'Renter' },
];

export const importExportSourceList = [
  {
    text: 'CSV',
    value: 'CSV',
  },
  {
    text: 'Data Enrichment',
    value: 'Data Enrichment',
  },
];

export const importExportStatusList = [
  {
    text: 'Not Started',
    value: 'not started',
  },
  {
    text: 'In Progress',
    value: 'in progress',
  },
  {
    text: 'Done',
    value: 'done',
  },
  {
    text: 'Error',
    value: 'error',
  },
  {
    text: 'Validating Emails',
    value: 'validating emails',
  },
  {
    text: 'Refreshing Profiles',
    value: 'refreshing profiles',
  },
  {
    text: 'Queued',
    value: 'queued',
  },
];

export const crmNames = [
  {
    text: 'Chime',
    value: 'chime',
  },
  {
    text: 'Contactually',
    value: 'contactually',
  },
  {
    text: 'Hubspot',
    value: 'hubspot',
  },
  {
    text: 'Pipedrive',
    value: 'pipedrive',
  },
  {
    text: 'Redtail',
    value: 'redtail',
  },
  {
    text: 'Salesforce',
    value: 'salesforce',
  },
];
