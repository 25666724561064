import { StytchB2B } from '@stytch/react/b2b';
import { discoveryConfig, discoveryStyles } from '../../utils/stytchConfig';
import './index.css';
export const Login = () => {
  return (
    <div className="login-background-container">
      <div className="login-container">
        <div className="centered-login">
          <StytchB2B config={discoveryConfig} styles={discoveryStyles} />
        </div>
      </div>
    </div>
  );
};
