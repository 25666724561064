import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Navigate } from 'react-router-dom';
//import { inject, observer } from 'mobx-react';
import { setAxiosTokenInterceptor } from '../../components/Auth0AxiosInterceptor';
import Progress from '../../components/Progress';

const LoginCallBack = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { user, isAuthenticated, getIdTokenClaims } = useAuth0();

  useEffect(() => {
    if (isAuthenticated && user) {
      getIdTokenClaims().then((token) => {
        if (token) setAxiosTokenInterceptor(token.__raw);
        setIsLoggedIn(true);
      });
    }
  }, [isAuthenticated, getIdTokenClaims, user]);

  if (isLoggedIn) {
    return <Navigate to="/" />;
  }
  return <Progress />;
};
export default LoginCallBack;
