import '../index.scss';

import {
  DeleteOutlined,
  EditOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Popconfirm,
  Select,
  Space,
  Spin,
  Tag,
  Tooltip,
} from 'antd';
import moment from 'moment';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import CreatableSelect from 'react-select/creatable';
import CommonTable from '../../components/CommonTable';
import { EditableCell, EditableRow } from '../../components/EditableComponents';
import ExportCsv from '../../components/ExportCsv';
import TopSection from '../../components/TopSection';
import { requestWithAuth } from '../../services/api';
import { industryList } from '../../utils/constants';

interface FiltersState {
  user_type?: string[];
  is_corporate?: string[];
  maxio_public_signup_url?: string[];
}

interface RequestParams {
  page_size?: number;
  page?: number;
  q?: string;
  ordering?: string;
  owner__isinvalid?: string;
  owner__icontains?: string;
  company__isnull?: string;
  company__icontains?: string;
  referrer_code__icontains?: string;
  maxio_public_signup_url__isnull?: string;
  is_corporate?: string;
  user_type?: string;
}

let params: RequestParams = {};
//let searchQuery = "";

const getValue = (obj) =>
  Object.keys(obj)
    .map((key) => obj[key])
    .join(',');

const FormItems = ({
  formVals,
  accountReps,
  isUpdate,
  isNexisAdmin,
  plansLookUp,
  userTypesLookup,
  themesLookUp,
}) => {
  const { TextArea } = Input;
  const [teamsLookup, setTeamsLookup] = useState([]);
  const [teamsLoading, setTeamsLoading] = useState(false);

  const onTeamSearch = (searchQuery) => {
    if (!searchQuery) {
      setTeamsLookup([]);
      return;
    }
    setTeamsLoading(true);
    const params = {
      page_size: 50,
      page: 1,
      q: searchQuery,
    };

    setTeamsLookup([]);
    requestWithAuth('vault-team', 'GET', params, null).then((response) => {
      if (response && response.results) {
        const options = response.results.map((ele) => ({
          value: ele.id,
          label: ele.name,
        }));
        setTeamsLookup([...options]);
      } else {
        setTeamsLookup([]);
      }
    });
    setTeamsLoading(false);
  };

  return (
    <>
      <Form.Item
        name="owner"
        label="Owner"
        required
        rules={[
          {
            required: true,
            message: 'Enter Owner',
          },
        ]}
      >
        <Select
          style={{ width: '100%' }}
          showSearch
          filterOption={(input, option) =>
            option?.children?.toString().includes(input)
          }
          filterSort={(optionA, optionB) =>
            (optionA?.children?.toString() || '')
              .toLowerCase()
              .localeCompare(
                (optionB?.children?.toString() || '').toLowerCase(),
              )
          }
        >
          {accountReps.map(({ value, text }) => (
            <Select.Option key={value} value={value}>
              {text}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="referrer_code"
        label="Referrer Code"
        required
        rules={[
          {
            required: true,
            message: 'Enter Referrer Code',
          },
        ]}
      >
        <Input placeholder="Referrer Code" />
      </Form.Item>
      <Form.Item
        name="is_northwestern_mutual"
        label="NM User"
        valuePropName="checked"
      >
        <Checkbox />
      </Form.Item>

      <Form.Item name="company" label="Company">
        <Input placeholder="Company" />
      </Form.Item>

      <Form.Item name="industry" label="Industry">
        <Select style={{ width: '100%' }} showSearch>
          {industryList.map(({ value, text }) => (
            <Select.Option key={value} value={text}>
              {text}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="user_type"
        label="User Type"
        required
        rules={[
          {
            required: true,
            message: 'Enter User Type',
          },
        ]}
        initialValue={formVals?.user_type || 'premium'}
      >
        <Select style={{ width: '100%' }}>
          {userTypesLookup.map(({ value, text }) => (
            <Select.Option key={value} value={text}>
              {text}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="created" label="Created">
        <DatePicker placeholder="Created" disabled />
      </Form.Item>

      <Form.Item
        name="expiry_date"
        label="Expiration Date"
        required
        rules={[
          {
            required: true,
            message: 'Enter Expiry',
          },
        ]}
      >
        <DatePicker
          placeholder="Expiration Date"
          disabledDate={(current) => {
            return isUpdate ? false : moment().add(-1, 'days') >= current;
          }}
        />
      </Form.Item>

      {/**Do not show nexis client field for nexis users
       * but it should default to "yes" for all codes they create.
       * Make visible for Aidentified admin users so that
       *  we can update old referral codes for Nexis.
       */}
      {!isNexisAdmin && (
        <Form.Item name="is_nexis" label="Nexis Client" valuePropName="checked">
          <Checkbox />
        </Form.Item>
      )}
      {/* <Form.Item
                name="max_referrals"
                label="Maximum Number of Users"
                initialValue={formVals.max_referrals}>
                <Input placeholder="Maximum Number of Users" type="number" />
            </Form.Item>, */}

      <Form.Item name="max_referrals" label="Maximum Number of Users">
        <InputNumber
          style={{ width: '100%' }}
          placeholder="Maximum Number of Users"
          autoComplete="_away"
        />
      </Form.Item>

      {/* <Form.Item
                name="referrer_code_credits"
                label="Credits"
                initialValue={isUpdate ? formVals.referrer_code_credits : 20}>
                <Input placeholder="Credits" type="number" />
            </Form.Item>, */}

      <Form.Item name="referrer_code_credits" label="Credits">
        <InputNumber
          style={{ width: '100%' }}
          placeholder="Credits"
          autoComplete="_away"
        />
      </Form.Item>

      {!isNexisAdmin && (
        <Form.Item name="theme" label="White Label">
          <Select style={{ width: '100%' }}>
            {themesLookUp.map(({ theme__id, theme__name }) => (
              <Select.Option key={theme__id} value={theme__id}>
                {theme__name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}

      <Form.Item
        name="is_corporate"
        label="Invoice Only"
        valuePropName="checked"
      >
        <Checkbox style={{ lineHeight: '32px' }} />

        {/* <Input type="checkbox" value={!!formVals.is_corporate} /> */}
      </Form.Item>

      <Form.Item name="maxio_public_signup_url" label="Maxio Plan">
        <Select style={{ width: '100%' }}>
          {plansLookUp.map(({ value, label }) => (
            <Select.Option key={label} value={value}>
              {label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="team_obj" label="Auto add to team">
        <CreatableSelect
          isLoading={teamsLoading}
          className={'team-select'}
          placeholder="Search Team"
          options={teamsLookup}
          isSearchable
          isClearable
          isValidNewOption={() => false}
          hideSelectedOptions
          closeMenuOnSelect
          closeMenuOnScroll
          onInputChange={(e) => onTeamSearch(e)}
          onBlur={() => {
            setTeamsLookup([]);
          }}
          noOptionsMessage={() => null}
        />
      </Form.Item>
      <Form.Item name="comment" label="Comments">
        <TextArea placeholder="Comments" />
      </Form.Item>
    </>
  );
};

const FormModal = ({
  isUpdate,
  handleUpdate,
  handleCreate,
  isModalLoading,
  onClose,
  recordFormVals,
  accountReps,
  error,
  themesLookUp,
  isNexisAdmin,
  plansLookUp,
  userTypesLookup,
}) => {
  const [form] = Form.useForm();
  useEffect(() => {
    return () => {
      form.resetFields();
    };
  }, [form]);

  useEffect(() => {
    if (isUpdate) {
      recordFormVals.team_obj = recordFormVals.team
        ? {
            value: recordFormVals.team,
            // key: formVals.team,
            label: recordFormVals.team_name,
          }
        : null;

      recordFormVals.created = recordFormVals.created
        ? moment(recordFormVals.created)
        : null;
      recordFormVals.expiry_date = recordFormVals.expiry_date
        ? moment(recordFormVals.expiry_date)
        : null;

      form.setFieldsValue({ ...recordFormVals });
    }
  }, [recordFormVals, isUpdate, form]);

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        const formValues = { ...values };
        formValues.referrer_code_credits =
          formValues.referrer_code_credits || 0;
        if (formValues.team_obj && formValues.team_obj.value) {
          formValues.team = formValues.team_obj.value;
        } else {
          formValues.team = null;
        }
        delete formValues.team_obj;
        /** by default is_nexis is true for nexis admin, even if the checkbox is absent */
        if (isNexisAdmin) {
          formValues.is_nexis = true;
        }
        if (!formValues.max_referrals) formValues.max_referrals = 1;
        if (isUpdate) {
          handleUpdate(recordFormVals.id, formValues);
        } else {
          handleCreate(formValues);
        }
      })
      .catch((errorInfo) => {
        if (errorInfo) {
          console.log(errorInfo);
          message.error('Please fill the mandatory fields');
          return;
        }
      });
  };

  return (
    <Modal
      title={isUpdate ? 'Edit Referrer Code' : 'New Referrer Code'}
      open={true}
      width={800}
      okText={isUpdate ? 'Update' : 'Create'}
      onOk={handleSubmit}
      confirmLoading={isModalLoading}
      onCancel={onClose}
    >
      <Spin size="default" spinning={isModalLoading}>
        <Form labelCol={{ span: 7 }} wrapperCol={{ span: 13 }} form={form}>
          <FormItems
            formVals={recordFormVals}
            accountReps={accountReps}
            plansLookUp={plansLookUp}
            userTypesLookup={userTypesLookup}
            isUpdate={isUpdate}
            isNexisAdmin={isNexisAdmin}
            themesLookUp={themesLookUp}
          />
          {error && <div className="error-text">{error}</div>}
        </Form>
      </Spin>
    </Modal>
  );
};

const ReferrerCode = (props) => {
  const searchInputRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [listPagination, setListPagination] = useState({
    total: 0,
    pageSize: 50,
    current: 1,
  });
  const { pageSize, current } = listPagination;
  const [isLoading, setIsLoading] = useState(false);
  const [filtersState, setFiltersState] = useState<FiltersState>({});
  const [sortedInfo, setSortedInfo] = useState({
    column: '',
    order: '',
  });
  const { column, order } = sortedInfo;
  const [referrerCodeData, setReferrerCodeData] = useState([]);
  const [referrerCodeCount, setReferrerCodeCount] = useState(0);
  const [isUpdate, setIsUpdate] = useState(false);
  const [showFormModal, setShowFormModal] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [recordFormVals, setRecordFormVals] = useState(null);
  const [accountReps, setAccountReps] = useState([]);
  const [inactiveAccountReps, setInactiveAccountReps] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [error, setError] = useState(null);
  const [plansLookUp, setPlansLookUp] = useState([]);
  const [userTypesLookup, setUserTypesLookup] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [themesLookUp, setThemesLookUp] = useState([]);

  const fetchReferrerCodeData = useCallback(() => {
    setIsLoading(true);
    const newParams = {
      ...params,
      ...Object.fromEntries(searchParams.entries()),
      page_size: pageSize,
      page: current,
    };
    if (searchQuery) newParams.q = searchQuery;
    requestWithAuth('referrer-code', 'GET', newParams, null).then(
      (response) => {
        if (response && response.results) {
          setReferrerCodeData(response.results);
          setReferrerCodeCount(response.count);
          setListPagination({
            current: 1,
            pageSize: 50,
            total: response.count,
          });
          setIsLoading(false);
        }
      },
    );
  }, [pageSize, current, searchQuery, searchParams]);

  useEffect(() => {
    fetchTheme();
    fetchAccountReps();
    fetchInactiveAccountReps();
    fetchPlansData();
    fetchUserTypeLookup();
    return () => {
      params = {};
    };
  }, []);

  useEffect(() => {
    fetchReferrerCodeData();
  }, [fetchReferrerCodeData, column, order, filtersState, searchParams]);

  const fetchAccountReps = () => {
    requestWithAuth('field-lookup', 'GET', { key: 'account_rep' }, null)
      .then((response) => {
        if (response && response.result && response.result.length) {
          const accountReps = [];
          response.result.map((rep) => {
            accountReps.push({ value: rep.id, text: rep.email });
            return '';
          });
          accountReps.sort();
          setAccountReps(accountReps);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const fetchInactiveAccountReps = () => {
    requestWithAuth(
      'field-lookup',
      'GET',
      { key: 'account_rep_inactive' },
      null,
    )
      .then((response) => {
        if (response && response.result && response.result.length) {
          const inactiveAccountReps = [];
          response.result.map((rep) => {
            inactiveAccountReps.push({ value: rep.id, text: rep.email });
            return '';
          });
          inactiveAccountReps.sort();
          setInactiveAccountReps(inactiveAccountReps);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const fetchPlansData = () => {
    requestWithAuth(
      'field-lookup',
      'GET',
      { key: 'maxio_public_signup_url' },
      null,
    )
      .then((response) => {
        if (response && response.result && response.result.length) {
          setPlansLookUp([...response.result]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const fetchTheme = () => {
    const params = {
      key: 'theme',
    };
    requestWithAuth('field-lookup', 'GET', params, null)
      .then((response) => {
        if (response && response.result && response.result.length) {
          setThemesLookUp(response.result);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const fetchUserTypeLookup = () => {
    const params = {
      key: 'user_type',
    };
    requestWithAuth('field-lookup', 'GET', params, null)
      .then((response) => {
        if (response && response.result && response.result.length) {
          const userType = [];
          response.result.map((user) => {
            if (user !== 'inactive') userType.push({ value: user, text: user });
            return null;
          });
          setUserTypesLookup(userType);
          // userArray = filteredInfoUserType.unshift({ text: 'All', value: 'all' })
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const formatAccountRep = (accountRepKey) => {
    if (accountReps.length > 0) {
      const val = accountReps.find((rep) => rep.value === accountRepKey);
      if (val?.text) {
        return val.text;
      }
    }
    return '';
  };

  const formatInactiveAccountRep = (accountRepKey) => {
    if (inactiveAccountReps.length > 0) {
      const val = inactiveAccountReps.find(
        (rep) => rep.value === accountRepKey,
      );
      if (val?.text) {
        return (
          <s>
            <i>{val.text}</i>
          </s>
        );
      }
    }
    return (
      <s>
        <i>{accountRepKey}</i>
      </s>
    );
  };

  const toggleFormModal = (flag) => {
    setShowFormModal(flag);
  };

  const showUpdateForm = (record) => {
    setIsUpdate(true);
    setShowFormModal(true);
    setRecordFormVals({ ...record });
  };

  const handleDelete = (recordId: number) => {
    setIsLoading(true);
    requestWithAuth('referrer-code/' + recordId, 'DELETE', null, null).then(
      () => {
        message.success('Referrercode deleted');
        setIsLoading(false);
        fetchReferrerCodeData();
      },
    );
  };

  const handleDeleteMultiple = () => {
    const body = {
      code_ids: selectedIds,
    };
    setIsLoading(true);
    requestWithAuth('referrer-code/delete-multiple', 'POST', null, body).then(
      (response) => {
        if (response && response.code === 200) {
          message.success('Referrer Code deleted');
          fetchReferrerCodeData();
        } else
          message.error('Not able to delete now. Please try after some time');
        setSelectedIds([]);
        setSelectedRowKeys([]);
        setIsLoading(false);
      },
    );
  };

  const handleCreate = (fields) => {
    setIsModalLoading(true);
    setError(null);
    requestWithAuth('referrer-code', 'POST', null, fields)
      .then((response) => {
        if (response && response.error) {
          setError(Object.values(response.error).join(', '));
          setIsModalLoading(false);
          return;
        }
        if (response) {
          fetchReferrerCodeData();
          setShowFormModal(false);
        }
        setIsModalLoading(false);
      })
      .catch((e) => {
        setIsModalLoading(false);
        setShowFormModal(false);
      });
  };

  const handleUpdate = (recordId: number, fields) => {
    setError(null);
    setIsModalLoading(true);
    requestWithAuth(`referrer-code/${recordId}`, 'PUT', null, fields)
      .then((response) => {
        if (response && response.error) {
          setError(Object.values(response.error).join(', '));
          setIsModalLoading(false);
          return;
        } else if (response) {
          fetchReferrerCodeData();
          setShowFormModal(false);
          setIsUpdate(false);
        }
        setIsModalLoading(false);
      })
      .catch((e) => {
        setIsModalLoading(false);
        setShowFormModal(false);
        setIsUpdate(false);
      });
  };

  const handleSearchQuery = (searchValue) => {
    setSearchQuery(searchValue || '');
    setListPagination((prevState) => ({
      ...prevState,
      current: 1,
    }));
  };

  const handleFormReset = () => {
    params = {};
    setSortedInfo({
      column: '',
      order: '',
    });
    setListPagination({
      current: 1,
      pageSize: 50,
      total: 0,
    });
    setSearchQuery('');
    setFiltersState({});
    setSearchParams({});
    fetchReferrerCodeData();
  };

  const onSelectChange = (selectedRowKeys, selectedRows) => {
    let selectedIds = [];
    selectedIds = selectedRows.map((obj) => {
      return obj.id;
    });
    setSelectedRowKeys(selectedRowKeys);
    setSelectedIds(selectedIds);
  };

  const handleTableChange = (pagination, filtersArg, sorter) => {
    setSelectedRowKeys([]);
    if (sorter && sorter.order && sorter.columnKey) {
      params.ordering =
        sorter.order === 'descend' ? '-' + sorter.columnKey : sorter.columnKey;
      setSortedInfo({
        column: sorter.columnKey,
        order: sorter.order,
      });
    } else {
      setSortedInfo({
        column: '',
        order: '',
      });
      delete params.ordering;
    }

    setFiltersState(filtersArg);
    Object.keys(filtersArg).reduce((obj, key) => {
      let value = '';
      if (filtersArg[key] !== null) value = getValue(filtersArg[key]);

      if (key === 'maxio_public_signup_url') {
        // if length is greater than 1, it means both true and false are selected
        if (!value || filtersArg[key]?.length > 1) {
          delete params.maxio_public_signup_url__isnull;
        } else {
          params.maxio_public_signup_url__isnull = value;
        }
      } else if (key === 'is_corporate' || key === 'user_type') {
        if (!value) {
          delete params[key];
        } else {
          params[key] = value;
        }
      }

      return null;
    }, {});

    setListPagination({
      ...pagination,
      pageSize: pagination.pageSize,
      current: pagination.current,
    });
  };

  const handleColumnSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    if (dataIndex === 'owner') {
      if (selectedKeys[0] === 'Inactive account reps only') {
        params.owner__isinvalid = 'true';
        delete params.owner__icontains;
      } else {
        params.owner__icontains = selectedKeys[0];
        delete params.owner__isinvalid;
      }
    }
    if (dataIndex === 'company') {
      if (selectedKeys[0] === 'N/A') {
        params.company__isnull = 'true';
        delete params.company__icontains;
      } else {
        params.company__icontains = selectedKeys[0];
        delete params.company__isnull;
      }
    }
    if (dataIndex === 'referrer_code') {
      params.referrer_code__icontains = selectedKeys[0];
    }
  };

  const handleColumnSearchReset = (clearFilters, confirm, dataIndex) => {
    clearFilters();
    setFiltersState((prevState) => {
      const newState = { ...prevState };
      delete newState[dataIndex];
      return newState;
    });
    if (dataIndex === 'owner') {
      delete params.owner__icontains;
      delete params.owner__isinvalid;
    }
    if (dataIndex === 'company') {
      delete params.company__icontains;
      delete params.company__isnull;
    }
    if (dataIndex === 'referrer_code') {
      delete params.referrer_code__icontains;
    }
    confirm();
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInputRef}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleColumnSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: 'block' }}
          disabled={
            selectedKeys[0] === 'N/A' ||
            selectedKeys[0] === 'Inactive account reps only'
          }
        />
        {dataIndex === 'company' && (
          <>
            <Checkbox
              onChange={(e) => {
                if (e.target.checked) {
                  setSelectedKeys(['N/A']);
                } else {
                  setSelectedKeys([]);
                }
              }}
              checked={selectedKeys[0] === 'N/A'}
              style={{ marginBottom: 8 }}
            >
              N/A
            </Checkbox>
            <br />
          </>
        )}
        {dataIndex === 'owner' && (
          <>
            <Checkbox
              onChange={(e) => {
                if (e.target.checked) {
                  setSelectedKeys(['Inactive account reps only']);
                } else {
                  setSelectedKeys([]);
                }
              }}
              checked={selectedKeys[0] === 'Inactive account reps only'}
              style={{ marginBottom: 8 }}
            >
              Inactive account reps only
            </Checkbox>
            <br />
          </>
        )}
        <Button
          type="primary"
          onClick={() => handleColumnSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() =>
            handleColumnSearchReset(clearFilters, confirm, dataIndex)
          }
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{ color: filtered ? 'var(--color-primary)' : undefined }}
      />
    ),
    filteredValue: filtersState[dataIndex] || [],
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInputRef.current?.select());
      }
    },
  });

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columns = [
    {
      title: 'Referrer Code',
      dataIndex: 'referrer_code',
      key: 'referrer_code',
      width: 120,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortedInfo.column === 'referrer_code' && sortedInfo.order,
      ...getColumnSearchProps('referrer_code'),
      render: (val) => {
        return <div className="column-style">{val}</div>;
      },
    },
    {
      title: 'Owner',
      dataIndex: 'owner',
      key: 'owner',
      width: 120,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortedInfo.column === 'owner' && sortedInfo.order,
      ...getColumnSearchProps('owner'),
      render: (val) => {
        return (
          <div className="column-style">
            {formatAccountRep(val) || formatInactiveAccountRep(val)}
          </div>
        );
      },
    },
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
      width: 120,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortedInfo.column === 'company' && sortedInfo.order,
      ...getColumnSearchProps('company'),
      render: (val) => {
        return <div className="column-style">{val || ''}</div>;
      },
    },
    {
      title: 'Created',
      width: 90,
      dataIndex: 'created',
      key: 'created',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortedInfo.column === 'created' && sortedInfo.order,
      render: (val) => {
        return (
          <div className="column-style">{moment(val).format('MM/DD/YY')}</div>
        );
      },
    },
    {
      title: 'Expiry',
      width: 90,
      dataIndex: 'expiry_date',
      key: 'expiry_date',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortedInfo.column === 'expiry_date' && sortedInfo.order,
      render: (val) => {
        return (
          <div className="column-style">
            {val ? moment(val).format('MM/DD/YY') : ''}
          </div>
        );
      },
    },
    {
      title: 'Invoiced',
      dataIndex: 'is_corporate',
      key: 'is_corporate',
      width: 80,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortedInfo.column === 'is_corporate' && sortedInfo.order,
      filters: [
        { text: 'Yes', value: 'true' },
        { text: 'No', value: 'false' },
      ],
      filterMultiple: false,
      filteredValue: filtersState.is_corporate || [],
      render: (val) => {
        return <Tag color={val ? 'green' : 'red'}>{val ? 'Yes' : 'No'}</Tag>;
      },
    },
    {
      title: 'Maxio Plan',
      dataIndex: 'maxio_public_signup_url',
      key: 'maxio_public_signup_url',
      width: 180,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      sortOrder:
        sortedInfo.column === 'maxio_public_signup_url' && sortedInfo.order,
      filters: [
        { text: 'N/A', value: 'true' },
        { text: 'Not N/A', value: 'false' },
      ],
      filterMultiple: false,
      filteredValue: filtersState.maxio_public_signup_url || [],
      render: (val) => {
        if (!val) return <div className="column-style">N/A</div>;
        const plan = plansLookUp.find((plan) => plan.value === val);
        return <div className="column-style">{plan ? plan.label : val}</div>;
      },
    },
    {
      title: 'User Type',
      dataIndex: 'user_type',
      key: 'user_type',
      width: 120,
      sorter: { multiple: 5 },
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortedInfo.column === 'user_type' && sortedInfo.order,
      filters: userTypesLookup,
      filteredValue: filtersState.user_type || [],
      render: (val) => {
        return (
          <div
            style={{ width: '100%', wordBreak: 'break-word', display: 'block' }}
          >
            {val}
          </div>
        );
      },
    },
    {
      title: 'Max Users',
      dataIndex: 'max_referrals',
      key: 'max_referrals',
      width: 80,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortedInfo.column === 'max_referrals' && sortedInfo.order,
      render: (val) => {
        return <div className="column-style">{val || 0}</div>;
      },
    },
    {
      title: 'Active Users',
      dataIndex: 'active_user_count',
      key: 'active_user_count',
      width: 80,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortedInfo.column === 'active_user_count' && sortedInfo.order,
      render: (val, record) => {
        return (
          <div className="column-style">
            <Link
              to={`/user-editor/users?user_ref_code__iexact=${record.referrer_code}&user_type=admin,api,lite,internal,premium`}
            >
              {val || 0}
            </Link>
          </div>
        );
      },
    },
    {
      title: 'Inactive Users',
      dataIndex: 'inactive_user_count',
      key: 'inactive_user_count',
      width: 80,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      sortOrder:
        sortedInfo.column === 'inactive_user_count' && sortedInfo.order,
      render: (val, record) => {
        return (
          <div className="column-style">
            <Link
              to={`/user-editor/users?user_ref_code__iexact=${record.referrer_code}&user_type=inactive`}
            >
              {val || 0}
            </Link>
          </div>
        );
      },
    },
    {
      title: 'Total Users',
      dataIndex: 'total_user_count',
      key: 'total_user_count',
      width: 80,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortedInfo.column === 'total_user_count' && sortedInfo.order,
      render: (val, record) => {
        return (
          <div className="column-style">
            <Link
              to={`/user-editor/users?user_ref_code__iexact=${record.referrer_code}`}
            >
              {val || 0}
            </Link>
          </div>
        );
      },
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 80,
      onCell: () => ({
        onClick: (e) => {
          e.stopPropagation();
        },
      }),
      render: (text, record) => (
        <Space size="small">
          <Tooltip title="Edit">
            <Button
              icon={<EditOutlined />}
              onClick={() => showUpdateForm(record)}
              size="small"
            />
          </Tooltip>
          <Tooltip title="Delete">
            <Popconfirm
              title="Delete?"
              onConfirm={() => handleDelete(record.id)}
            >
              <Button icon={<DeleteOutlined />} size="small" />
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    },
  ];

  const renderExportComponent = () => {
    return (
      <ExportCsv
        totalCount={referrerCodeCount}
        filterParams={params}
        url="referrer-code"
        downloadFileName="referrer_codes"
      />
    );
  };

  return (
    <>
      <div>
        <TopSection
          placeholder="Search referrer, owner, company"
          selectedRowKeys={selectedRowKeys}
          handleSearchQuery={handleSearchQuery}
          handleFormReset={handleFormReset}
          toggleCreateForm={toggleFormModal}
          handleDeleteMultiple={handleDeleteMultiple}
          showSearch
          count={referrerCodeCount}
          searchValue={searchQuery}
          showExportCsv={referrerCodeCount > 0}
          renderExportComponent={renderExportComponent}
        />
        <Spin size="default" spinning={isLoading}>
          <CommonTable
            columns={columns}
            data={referrerCodeData}
            components={components}
            onTableChange={handleTableChange}
            pagination={{ ...listPagination }}
            pageSize={listPagination.pageSize}
            loading={isLoading}
            rowKey={(rec) => `${rec.events_id}${rec.id}`}
            rowSelection={rowSelection}
            selectedRowKeys={selectedRowKeys}
            rowEventHandlers={showUpdateForm}
            size="small"
          />
        </Spin>
      </div>
      {showFormModal && (
        <FormModal
          isUpdate={isUpdate}
          handleUpdate={handleUpdate}
          handleCreate={handleCreate}
          isModalLoading={isModalLoading}
          recordFormVals={recordFormVals}
          accountReps={accountReps}
          plansLookUp={plansLookUp}
          userTypesLookup={userTypesLookup}
          themesLookUp={themesLookUp}
          error={error}
          onClose={() => {
            setIsUpdate(false);
            setShowFormModal(false);
            setError(null);
          }}
          isNexisAdmin={props.isNexisAdmin}
        />
      )}
    </>
  );
};

export default ReferrerCode;
