import './index.scss';

import { useRef, useEffect, useMemo, useState } from 'react';
import { Popconfirm, Input, Button } from 'antd';
import debounce from 'lodash/debounce';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';

import { splitNumberWithCommas } from '../../utils/general';

const { Search } = Input;

const useDebounce = (callback: () => void) => {
  const ref = useRef<() => void>();

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  const debouncedCallback = useMemo(() => {
    const func = () => {
      ref.current?.();
    };

    return debounce(func, 500);
  }, []);

  return debouncedCallback;
};

const TopSection = (props) => {
  const {
    count = null,
    placeholder = '',
    showNewButton = true,
    showExportCsv = false,
    showSearch,
    selectedRowKeys,
    handleSearchQuery,
    handleFormReset,
    renderExportComponent,
    toggleCreateForm,
    handleDeleteMultiple,
    deleteButtonText = 'Delete',
    searchValue = '',
  } = props;
  const [query, setQuery] = useState(searchValue);

  const debouncedSearch = useDebounce(() => {
    handleSearchQuery(query);
  });

  const onClearFilters = () => {
    handleFormReset();
    setQuery('');
  };

  return (
    <div className="table-header">
      <div style={{ display: 'inline-flex', gap: '20px' }}>
        {showNewButton && (
          <div>
            <Button
              icon={<PlusOutlined />}
              type="primary"
              onClick={() => {
                toggleCreateForm(true);
              }}
              className="new-button"
            >
              New
            </Button>
          </div>
        )}
        {showExportCsv && <div>{renderExportComponent()}</div>}
        {selectedRowKeys && selectedRowKeys.length > 0 && (
          <Popconfirm
            title={`${deleteButtonText}?`}
            placement="rightBottom"
            onConfirm={handleDeleteMultiple}
          >
            <Button type="primary" className="new-button">
              {deleteButtonText}
            </Button>
          </Popconfirm>
        )}
      </div>
      {showSearch && (
        <>
          <div style={{ marginLeft: 'auto', width: '100%', maxWidth: '350px' }}>
            <Search
              placeholder={placeholder}
              autoComplete="__away"
              onChange={(e) => {
                debouncedSearch();
                setQuery(e.target.value);
              }}
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key === 'Enter') handleSearchQuery(e.currentTarget.value);
              }}
              enterButton
              value={query}
            />
          </div>
          <div style={{ marginRight: 'auto' }}>
            <Button
              icon={<CloseOutlined />}
              onClick={onClearFilters}
              className="clear"
            >
              Clear Filters
            </Button>
          </div>
        </>
      )}
      {count !== null && (
        <div style={{ color: 'var(--color-primary)', textAlign: 'end' }}>
          {splitNumberWithCommas(count)} {count > 1 ? 'Results' : 'Result'}
        </div>
      )}
    </div>
  );
};

export default TopSection;
